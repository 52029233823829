import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import { toast } from 'react-toastify';

import { H5 } from '../index.js';
import Grid from '../grid/Grid.js';
import attachmentTable from '../../configs/gridConfigs/frf/attachmentTable.js';

class UploadAttachment extends Component {
  constructor(props) {
    super(props);
    this.onDropData = this.onDropData.bind(this);
  }
 
  onDropData = files => {
    const { uploadAction, attachment } = this.props;
    const file = files[0];
    const type = ['pdf', 'xls', 'xlsx','PDF', 'XLS', 'XLSX', 'jpeg', 'jpg', 'ppt', 'PPT','doc', 'docx', 'DOC', 'DOCX','png','JPG','JPEG', 'PNG', 'CSV', 'csv'];
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    const isEdge = !!window.StyleMedia;

    const reader = new FileReader();
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = event => {
      let message = '';
      if(event.target.result){
        const base = event.target.result.split(',');
        const element = {
          fileName: file.name,
          format: file.name.split('.').pop(),
          submittedDate: moment(new Date()).format('MM/DD/YYYY'),
          base64: base[1]
        };
        // Validate and upload
        if (attachment.length >= 3) {
          message = 'Maximum 3 Files are allowed';
        } else  if (!type.includes(element.format)) {
          message = 'The File Extension Should be pdf, PDF, xls, xlsx, XLS, XLSX, jpeg, jpg, ppt, doc, docx, DOC, DOCX png, JPG, JPEG, PNG, CSV, csv';
        } 
        /* else if (element.fileName.indexOf(' ') !== -1) {
          message= 'File Name should not contain any space';
        } */
         else if(file.size > 10485760) {
          message = 'File Should be Less Than 10MB';
        } else if (file.size === 0) {
          message = 'File Should be Greater Than 0B';
        } else {
          // Action creater to be triggered on attachment Upload
          uploadAction(element);
        }
      } else if(isIE||isEdge){ 
        // In IE for a file with 0B event.target.result will be undefined, 
        // hence the 0 B file validation is moved to else condition for IE
        message = 'File Should be Greater Than 0B';
      }
       // Display above given error message if validation message exist
       if(message) {
        toast.error(message, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    };
    if(file){
      reader.readAsDataURL(file);
    };
  }

  render() {
    const { attachment, disableUpload } = this.props;
    return (
      <div data-testid="upload-attachment-component">
      <div className="ncss-col-sm-12 inline-flex-display p5-sm u-bold qrf-padding-zero ie-inline-display">
        <H5 className="ncss-col-sm-2  u-bold trf-data-text">Add supporting documents</H5>
        {/* <div className="ncss-col-sm-1"> */}
         { !disableUpload &&
             <span data-testid="dropzone-component">
             <Dropzone onDrop={this.onDropData} onClick={evt => evt.preventDefault()}>
               {({ getRootProps, getInputProps, open }) => (
                 <span {...getRootProps()}>
                   <input {...getInputProps()} />
                   <span className="upload-sec">
                     <i className="fas fa-upload" style={{ color: 'black', fontSize: 'medium' }} />
                   </span>
                 </span>
               )}
             </Dropzone>
           </span>
          }
        </div>
      {/* </div> */}
      {attachment && attachment.length > 0 && (
            <div data-testid = "container-component">
               <div className="ncss-row">
                <div className="ncss-col-sm-12" data-testid="grid-component">
                    <Grid 
                      columnDefs={attachmentTable} 
                      rowData={attachment} 
                      showFilter={false} 
                      uploadHeight
                      
                    />
                </div>
                </div>
            </div>) }
      </div>
    );
  }
}

UploadAttachment.propTypes = {
  uploadAction: PropTypes.func,
  attachment: PropTypes.array,
  disableUpload: PropTypes.bool
};

export default UploadAttachment;