import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm, getFormValues } from 'redux-form';
import get from 'lodash/get';

import {
  isSupplierUser,
  isRslUser,
  isFactoryUser,
  isLabUser,
  getSupplierList,
  selectUserDetails
} from '../../reducers/authReducer.js';
import { Button, TextInput, ConfirmationBox, ContentPopup } from '../../components/index.js';
import Grid from '../../components/grid/Grid.js';
import supplierGridConfig from '../../configs/gridConfigs/userPage/supplierGridConfig.js';
import osidGridConfig from '../../configs/gridConfigs/userPage/osidGridConfig.js';

import { createServiceAction } from '../../util/actionHelper.js';

export class SupplierSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitModal: false,
      isOpenSupplierList: false,
      status: null
    };
    this.hidden = {
      display: 'none'
    };
    this.confirmationBoxOpen = this.confirmationBoxOpen.bind(this);
    this.closeSubmitModal = this.closeSubmitModal.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.closeSupplierListModal = this.closeSupplierListModal.bind(this);
    this.openSupplierListModal = this.openSupplierListModal.bind(this);
    this.submitUserProfile = this.submitUserProfile.bind(this);
  }

  componentWillUpdate(nextProps) {
    const {initialValues, requestSupplierList} = this.props;
    if(initialValues.supplierName !== nextProps.initialValues.supplierName && nextProps.initialValues.supplierInfoStatus === 'Edit')
    {
      
        requestSupplierList(nextProps.initialValues.supplierName);
    }

}
  onRowClick = params => {
    
    const { change, requestLocationList, requestExceptionDetails,resetLocation, supplierChange,isOSIdSearch,osIdChange,userDetails,fromCreateTrf } = this.props;
   
    this.closeSupplierListModal();
    change('supplierOrLocationId', params.data.supplierID ? params.data.supplierID : '');
    if(fromCreateTrf){
    change('supplierName',params.data.supplierName) 
    fromCreateTrf(params.data.supplierName ? params.data.supplierName : '',params.data.supplierID ? params.data.supplierID : '')
    } 
    if(supplierChange){ 
    supplierChange(params.data.supplierName ? params.data.supplierName : '',params.data.supplierID ? params.data.supplierID : '')
    }
    if(requestLocationList) {
      requestLocationList(params.data.supplierID,userDetails.facilityId);
      resetLocation();
    }
    if(requestExceptionDetails){
      requestExceptionDetails({ userType: 'Suppliers', supplierId:params.data.supplierID});
    }
    if(isOSIdSearch){
      osIdChange(params.data.OSHId ? {OSId:params.data.OSHId,locId:params.data.locId} : '')
    }
  };

  confirmationBoxOpen = (modalopen, status) => {
    this.setState({ submitModal: true, status });
  };

  closeSubmitModal = () => {
    this.setState({ submitModal: false });
  };

  closeSupplierListModal = () => {
    
    this.setState({ isOpenSupplierList: false });
   
  };

  openSupplierListModal = () => {
    this.setState({ isOpenSupplierList: true });
  };

  submitUserProfile = () => {
    const { supplierForm, submitUserProfile } = this.props;
    submitUserProfile(supplierForm);
  };

  cancelUserProfile = () => {
    const { history } = this.props;
    history.push('/');
  };
  handleOSHList = ()=>{
    const {supplierList,filteredSupp} = this.props;
    let filterOSHList=supplierList;
    filteredSupp && filteredSupp.forEach(oshId =>{
      filterOSHList =filterOSHList && filterOSHList.filter(function(el) { return el.OSHId !== oshId.OSId; })
    });
   return filterOSHList;
  }
  setUserGridColumns = (params) => {
    let columnsToDisplay = ['OSHId','supplierName'];
    if( params.columnApi){
    const allColumns = params.columnApi.getColumnState();
    const filteredColumns = allColumns.map(column => {
      if (columnsToDisplay.indexOf(column.colId) === -1) {
        column.hide = true;
      } else {
        column.hide = false;
      }
      return column;
    });
    params.columnApi.setColumnState(filteredColumns);
    // Fix for IE
    params.api.sizeColumnsToFit();
    params.api.refreshHeader();
  }
  }

  render() {
    const { supplierForm, requestSupplierList, supplierList,fromPCX, Search,supplierName,includeInternal,includePCX,isEditUser,isOSIdSearch } = this.props;
    const { submitModal, status, isOpenSupplierList } = this.state;
    const supplierJSON=isEditUser ? {q: `${supplierForm.supplierName}*`,includeInternal:`${includeInternal}`,includePCX:`${includePCX}`}
                        :{q: `${supplierForm.supplierName}*`}
    const osidJSON= {q:`${supplierForm.OSHID}*`,includePCX:false,forUser:'Factories',includeInternal:true}
    let oshIdList = supplierForm.OSHID !== "" && supplierList && this.handleOSHList();
    return (
      <div className="supplier-search" data-testid="supplier-search">
        <div>
          <div className="p2-bottom-sm">{!isOSIdSearch ?
            <div className="ncss-input-container inline-flex-display u-bold" data-testid="field">
              <Field data-testid="field" component={TextInput} name="supplierName" type="text" containerClass="u-full-width" />
              {(supplierForm && supplierForm.supplierName !== null && supplierForm.supplierName.length>=3)?
              <Button 
              className="ncss-btn-grey u-bold new-material search-button"
              onClick={() => {
                this.openSupplierListModal();
                fromPCX ? requestSupplierList(supplierForm && supplierJSON) :Search(supplierName) ;
              }}
            >
              <i className="fas fa-search" />
              Search
            </Button>:
          <Button className="ncss-btn-grey u-bold new-material disabled " disabled>
             <i className="fas fa-search" />
              Search
          </Button> }
              
            </div>:
            <div className="ncss-input-container inline-flex-display u-bold" >
            <Field component={TextInput} name="OSHID" type="text" containerClass="u-full-width" />
            {(supplierForm && supplierForm.OSHID.length>=3)?
            <Button
            className="ncss-btn-grey u-bold new-material search-button"
            onClick={() => {
              this.openSupplierListModal();
              requestSupplierList(supplierForm && osidJSON)
            }}
          >
            <i className="fas fa-search" />
            Search & Add
          </Button>:
        <Button className="ncss-btn-grey u-bold new-material disabled " disabled>
           <i className="fas fa-search" />
           Search & Add
        </Button> }
            
            <Field component={TextInput} name="supplierOrLocationId" type="hidden" />
          </div>
          }
          </div>
          
          {!isOSIdSearch ?<ContentPopup
            title="Select Supplier:"
            isOpen={isOpenSupplierList}
            closeModal={this.closeSupplierListModal}
            content={
              <Grid rowData={supplierList} columnDefs={supplierGridConfig} onRowClick={this.onRowClick} 
                customNoRowTemplete={
                  supplierList === undefined
                   ? "<span class='ag-overlay-loading-center'>No results found</span>"
                   : "<span class='ag-overlay-loading-center'>Please wait while your rows are loading</span>"
               } 
              />
            }
          />:
          <ContentPopup
            title="Select OS ID:"
            isOpen={isOpenSupplierList}
            closeModal={this.closeSupplierListModal}
            content={
              <Grid rowData={oshIdList} columnDefs={osidGridConfig} onRowClick={this.onRowClick} 
                customNoRowTemplete={
                  supplierList === undefined
                   ? "<span class='ag-overlay-loading-center'>No results found</span>"
                   : "<span class='ag-overlay-loading-center'>Please wait while your rows are loading</span>"
               } 
               setParams={this.setUserGridColumns}  

              />
            }
          />
        }
        </div>
        <ConfirmationBox
          
          message={
            status === 'submit'
              ? 'Are you sure you want to submit User Profile details?'
              : 'Are you sure you want to clear User Profile details?'
          }
          submitModal={submitModal}
          submitValue={
            status === 'submit'
              ? () => {
                  this.submitUserProfile();
                }
              : () => this.cancelUserProfile()
          }
          closeModal={this.closeSubmitModal}
        />
      </div>
    );
  }
}

// SupplierSearch.defaultProps = {
//   fromPCX: true
// };

SupplierSearch.propTypes = {
  supplierForm: PropTypes.object,
  requestSupplierList: PropTypes.func,
  supplierList: PropTypes.array,
  change: PropTypes.func,
  Search:PropTypes.func,
  supplierName:PropTypes.string,
  submitUserProfile: PropTypes.func,
  requestLocationList: PropTypes.func,
  history: PropTypes.object
};

SupplierSearch.defaultProps = {
  fromPCX: true
};

const mapStateToProps = state => ({
  isSupplierUser: isSupplierUser(state),
  isFactoryUser: isFactoryUser(state),
  isRslUser: isRslUser(state),
  isLabUser: isLabUser(state),
  supplierList: getSupplierList(state),
  supplierForm: getFormValues('supplierForm')(state),
  userDetails: selectUserDetails(state),
  supplierName: get(state,`form.supplierForm.values.supplierName`, ''),
  labLocationId:
    selectUserDetails(state).labLocationId && parseInt(selectUserDetails(state).labLocationId, 10),
  userExist: !!selectUserDetails(state).userId
});

const mapDispatchToProps = dispatch => ({
  requestSupplierList: searchTerm => {
    dispatch(createServiceAction('supplierSearch', 'requested')(searchTerm));
  },
  Search: (data) => {
    dispatch(createServiceAction('supplierDetail', 'requested')(data));
  }
});

export default compose(
  reduxForm({
    form: 'supplierForm',
    initialValues: {
      supplierName: '',
      OSHID:'',
    },
    destroyOnUnmount: false,
    enableReinitialize: true
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SupplierSearch);