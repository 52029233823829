import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import { Button, Container, ConfirmationBox, H5 } from '../../components/index.js';
import { history } from '../../util/index.js';
import SupplierSearch from '../supplierSearch/SupplierSearch'
import PropTypes from 'prop-types';
import UserInfoGrid from '../userInfoGrid/userInfoGrid.js';
import { toggleAddSupplerButton } from '../../actions/navigationActions.js';
import { createServiceAction } from '../../util/actionHelper.js';
import { clearExceptionList, hideProgress } from '../../actions/adminUserActions.js';

export class AddException extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitModal: false,
      activeRSLUserAdd: false,
      activeFactorySupplierAdd: false,
      activeAllUsersAdd: true,
      selectedNodes: []
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.rslUserAdd = this.rslUserAdd.bind(this);
    this.factorySupplierAdd = this.factorySupplierAdd.bind(this);
    this.cancelAddException = this.cancelAddException.bind(this);
    this.confirmationBoxOpen = this.confirmationBoxOpen.bind(this);
    this.closeSubmitModal = this.closeSubmitModal.bind(this);
    this.submitExceptionList = this.submitExceptionList.bind(this);
    this.setGridParams = this.setGridParams.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
  }

  factorySupplierAdd = () => {
    const { clearExceptionList } = this.props;
    clearExceptionList();
    this.setState({ activeRSLUserAdd: false, activeFactorySupplierAdd: true, activeAllUsersAdd: false });
  }

  rslUserAdd = () => {
    const { clearExceptionList } = this.props;
    clearExceptionList();
    this.setState({ activeRSLUserAdd: true, activeFactorySupplierAdd: false, activeAllUsersAdd: false });
  }

  allUsersAdd = () => {
    const { clearExceptionList } = this.props;
    clearExceptionList();
    this.setState({ activeRSLUserAdd: false, activeFactorySupplierAdd: false, activeAllUsersAdd: true });
  }

  cancelAddException = () => {
    history.push('/');
  }

  componentDidMount() {
    const { requestExceptionDetails, toggleAddSupplerButton } = this.props;
    const { activeAllUsersAdd } = this.state;
    if (activeAllUsersAdd) {
      requestExceptionDetails({ userType: 'All' });
    }
    toggleAddSupplerButton();
  }

  componentDidUpdate(prevProps, prevState) {
    const { requestExceptionDetails, hideProgress, exceptionPercentage, exceptionRSLUsers, exceptionSupplierOrFactoryUsers, exceptionAllUsers } = this.props;
    const { activeRSLUserAdd, activeAllUsersAdd, selectedNodes } = this.state;
    // Request RSL user list with exception flag
    if ((prevState.activeRSLUserAdd !== activeRSLUserAdd) && activeRSLUserAdd) {
      requestExceptionDetails({ userType: 'Rsl' });
    }
    // Request all users list with exception flag
    if ((prevState.activeAllUsersAdd !== activeAllUsersAdd) && activeAllUsersAdd) {
      requestExceptionDetails({ userType: 'All' });
    }
    // Identify if the user is navigating between different tabs (RSL, Supplier/Factory, All Users) for setting the grid checkbox
    if (!isEqual(prevProps.exceptionRSLUsers, exceptionRSLUsers) ||
      !isEqual(prevProps.exceptionSupplierOrFactoryUsers, exceptionSupplierOrFactoryUsers) ||
      !isEqual(prevProps.exceptionAllUsers, exceptionAllUsers)
    ) {
      // Set Exception flag for each user in grid
      this.gridParams && this.gridParams.api.forEachNode(function (node) {
        if (node.data.tp2Exception) {
          node.setSelected(true);
        }
      });
    }
    // Set previously retained rows after select all option is unchecked
    if (isEmpty(selectedNodes) && !isEqual(selectedNodes, prevState.selectedNodes)) {
      this.gridParams && this.gridParams.api.forEachNode(function (node) {
        if (prevState.selectedNodes.includes(node.data)) {
          node.setSelected(true);
        }
      });
    }
    if (exceptionPercentage === 100) {
      setTimeout(() => {
        hideProgress();
      }, 500);
    }
  }

  handleSearch = (supplierName) => {
    const { Search } = this.props
    const requestObject = {};
    requestObject.supplierName = supplierName
    Search(requestObject.supplierName)
  }

  confirmationBoxOpen = (modalopen) => {
    this.setState({ submitModal: true });
  };

  closeSubmitModal = () => {
    this.setState({ submitModal: false });
  };

  submitExceptionList = () => {
    const { activeRSLUserAdd, activeFactorySupplierAdd, activeAllUsersAdd } = this.state;
    const { addExceptionUsers } = this.props;
    let postData = [];
    const selectedRows = this.gridParams.api.getSelectedRows();
    if (activeRSLUserAdd) {
      postData = [{ userType: 'Rsl', userIds: selectedRows.map(selectedRow => selectedRow.userId) }];
    } else if (activeFactorySupplierAdd) {
      postData = [{ userType: 'Suppliers', userIds: selectedRows.map(selectedRow => selectedRow.userId) }];
    } else if (activeAllUsersAdd) {
      postData = [{ userType: 'Rsl', userIds: selectedRows.filter(selectedRow => selectedRow.userType === 'Rsl').map(selectedRow => selectedRow.userId) },
      { userType: 'Suppliers', userIds: selectedRows.filter(selectedRow => selectedRow.userType === 'Suppliers' || selectedRow.userType === 'Factories').map(selectedRow => selectedRow.userId) }
      ];
    }
    !isEmpty(postData) && addExceptionUsers(postData);
  };

  setGridParams(params) {
    this.gridParams = params;
  }

  onSelectionChanged(params) {
    const rowData = [];
    params.api.forEachNode(node => rowData.push(node.data));
    // Get previously added rows for retaining after select all option is unchecked
    if (rowData.length !== params.api.getSelectedRows().length) {
      this.setState({ selectedNodes: [] });
    }
  }

  render() {
    const { activeRSLUserAdd, activeFactorySupplierAdd, activeAllUsersAdd, submitModal } = this.state;
    const { requestExceptionDetails, exceptionRSLUsers, exceptionSupplierOrFactoryUsers, exceptionAllUsers } = this.props;
    // Set grid parameters for each tab (RSL, Supplier/Factory, All Users)
    let exceptionUsers = [];
    let useGridFor = 'TPException';
    if (activeRSLUserAdd) {
      exceptionUsers = exceptionRSLUsers;
      useGridFor = 'TPExceptionRSL';
    } else if (activeFactorySupplierAdd) {
      exceptionUsers = exceptionSupplierOrFactoryUsers;
      useGridFor = 'TPExceptionFactoryOrSupplier';
    } else if (activeAllUsersAdd) {
      exceptionUsers = exceptionAllUsers;
      useGridFor = 'TPExceptionAllUsers';
    }
    return (
      <div>
        <Container section className="ml1-sm mr1-sm mt2-sm tab-class-container tab-class padding-0">
          <div className="ncss-col-sm-12 ncss-input-container">
            <div className="ncss-col-sm-12 align-left">
              <div className="pt6-sm pb3-sm" >
                <div className="ncss-col-sm-6 pb3-sm supplier_class">
                  <Button className={`supplier-add-new-button add-supplier-text u-bold  ${activeAllUsersAdd ? 'active-supplier-button ' : 'not-active-supplier-button'}`}
                    onClick={this.allUsersAdd}>
                    <i className="fas fa-users"></i> ALL USERS
                  </Button>
                  <span className="span_addsupplier"> | </span>
                  <Button className={`supplier-add-new-button add-supplier-text u-bold ${activeRSLUserAdd ? 'active-supplier-button ' : 'not-active-supplier-button'}`}
                    onClick={this.rslUserAdd}>
                    <i className="fas fa-chalkboard-teacher"></i> RSL USER
                  </Button>
                  <span className="span_addsupplier"> | </span>
                  <Button className={`supplier-add-new-button add-supplier-text u-bold  ${activeFactorySupplierAdd ? 'active-supplier-button ' : 'not-active-supplier-button'}`}
                    onClick={this.factorySupplierAdd}>
                    <i className="fas fa-warehouse"></i> FACTORY/SUPPLIER USER
                  </Button>
                </div>
                {activeFactorySupplierAdd &&
                  <div className="ncss-col-sm-6 pb1-sm align-right">
                    <div className="ncss-col-sm-2 inline-flex-display">
                      <H5 className="u-bold description">Supplier:</H5>
                    </div>
                    <div className="ncss-col-sm-10">
                      <SupplierSearch
                        fromPCX={false}
                        requestExceptionDetails={requestExceptionDetails}
                      />
                    </div>
                  </div>
                }
                <div className="ncss-row">
                  <div className="ncss-col-sm-12">
                    <UserInfoGrid
                      gridData={exceptionUsers}
                      useFor={useGridFor}
                      rowSelection='multiple'
                      rowMultiSelectWithClick={true}
                      userInfoGridParams={this.setGridParams}
                      overlayLoadingTemplate="<span class='ag-overlay-loading-center'>Please wait while your rows are loading</span>"
                      customNoRowTemplete={
                        exceptionUsers === undefined && "<span class='ag-overlay-loading-center'>Please wait while your rows are loading</span>"
                      }
                      onSelectionChanged={this.onSelectionChanged}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div className="ncss-col-sm-12 align-center">
          <Button
            className="ncss-btn-secondary-dark u-bold secondary-button update-exception-buttton"
            onClick={this.cancelAddException}
          >
            CANCEL
          </Button>
          <Button
            className="ncss-btn-grey u-bold new-material update-exception-buttton"
            onClick={() => this.confirmationBoxOpen(true, 'submit')}
          >
            UPDATE EXCEPTION
          </Button>
        </div>
        <ConfirmationBox
          message="Are you sure you want to submit current exception list?"
          submitModal={submitModal}
          submitValue={this.submitExceptionList}
          closeModal={this.closeSubmitModal}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    exceptionRSLUsers: state.adminUser.exceptionRSLUsers,
    exceptionSupplierOrFactoryUsers: state.adminUser.exceptionSupplierOrFactoryUsers,
    exceptionAllUsers: state.adminUser.exceptionAllUsers,
    exceptionPercentage: state.adminUser.getprogressCurrent
  }
};

export const mapDispatchToProps = dispatch => ({
  requestExceptionDetails: (data) => {
    // Get data to show on grid
    dispatch(createServiceAction('getExceptionUsers', 'requested')(data));
  },
  clearExceptionList: () => {
    dispatch(clearExceptionList());
  },
  addExceptionUsers: (data) => {
    dispatch(createServiceAction('exceptionUsers', 'submitted')(data));
  },
  toggleAddSupplerButton: () => {
    dispatch(toggleAddSupplerButton());
  },
  hideProgress: () => {
    dispatch(hideProgress());
  }
});

AddException.propTypes = {
  supplierName: PropTypes.object,
  toggleAddSupplerButton: PropTypes.func,
  requestExceptionDetails: PropTypes.func,
  hideProgress: PropTypes.func,
  clearExceptionList: PropTypes.func,
  Search: PropTypes.func,
  addExceptionUsers: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(AddException);
