const config = [
  {
    colId: 'supplierID',
    headerName: 'Supplier Id',
    field: 'supplierID',
    suppressMovable: true,
    suppressHeaderMenuButton: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'supplierName',
    headerName: 'Supplier Name',
    field: 'supplierName',
    suppressMovable: true,
    suppressHeaderMenuButton: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  }
];
export default config;
