import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ArrowContainer , Popover} from 'react-tiny-popover';

class TinyPopOver extends Component {
    constructor(...props) {
        super(...props);
        this.state = {
            isPopoverOpen: true
        };
    }
    componentDidMount() {
        const { activeStatus } = this.props;
        this.setState({ isPopoverOpen: activeStatus })

    }
    componentWillUpdate(nextProps) {
        const { activeStatus } = this.props;

        if (activeStatus !== nextProps.activeStatus) {
            this.setState({ isPopoverOpen: nextProps.activeStatus })
        }

    }
    displayPopOver = () => {
        const { notificationStatus } = this.props;
        const { isPopoverOpen } = this.state;
        const togglePopover = !isPopoverOpen;
        notificationStatus(!isPopoverOpen)
        this.setState({ isPopoverOpen: togglePopover });
    };

    render() {
        const { children, activeStatus } = this.props;
        const { isPopoverOpen } = this.state;
        return (
            <Popover
                isOpen={isPopoverOpen}
                position="bottom"
                containerClassName='notification-tiny-popover'
                padding={8}
                //onClickOutside={this.displayPopOver}
                content={({ position, targetRect, popoverRect }) => {
                    if (!targetRect || !popoverRect) {
                        return null;
                    }
                    return (
                        <ArrowContainer
                            position={position}
                            targetRect={targetRect}
                            popoverRect={popoverRect}
                            arrowColor="rgb(0, 120, 185)"
                            arrowSize={6}
                            arrowStyle={{ opacity: 1, left: '82%' }}
                        >
                            <div className="notification-popover-section">
                                {children}
                            </div>
                        </ArrowContainer>
                    );
                }}
            >
                <span>
                    <button type="button" className=" inline-display ncss-btn fs16-sm pt1-sm notification-button" onClick={this.displayPopOver}>
                        {!activeStatus ? <i className="fas fa-bell bell-style-inactive"></i> : <i className="fas fa-bell bell-style"></i>}
                    </button>
                </span>
            </Popover>
        );
    }
}
TinyPopOver.propTypes = {
    children: PropTypes.object
};

export default TinyPopOver;
