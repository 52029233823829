// Replace the below API_URL value with valid API endpoint domain for running the application locally
  const API_URL = process.env.REACT_APP_API;
 //  const API_URL = 'https://026vw2ohsb.execute-api.us-west-2.amazonaws.com/dev/'
// const TRF_LOCAL = 'https://9p4xtno8dl.execute-api.us-west-2.amazonaws.com/dev/';

export const serverHost = `${API_URL}/`;

export const apiConfig = {
  testSummary: {
    name: 'testSummary',
    endPointUrl: `${serverHost}get-test-summary`,
    method: 'GET'
  },
  testSummaryFavorites: {
    name: 'testSummaryFavorite',
    endPointUrl: `${serverHost}list-fav`,
    method: 'GET'
  },
  testSummaryCount: {
    name: 'testSummaryCount',
    endPointUrl: `${serverHost}get-trf-count`,
    method: 'GET'
  },
  trfData: {
    name: 'trfData',
    endPointUrl: `${serverHost}create-trf`,
    method: 'POST'
  },
  testDetails: {
    name: 'testDetails',
    endPointUrl: `${serverHost}get-test-details`,
    method: 'GET'
  },
  trfDataResult: {
    name: 'trfData',
    endPointUrl: `${serverHost}get-trf-details`,
    method: 'GET'
  },
  qrfData: {
    name: 'qrfData',
    endPointUrl: `${serverHost}create-qrf`,
    method: 'POST'
  },
  qrfUpdate: {
    name: 'qrfUpdate',
    endPointUrl: `${serverHost}update-qrf`,
    method: 'PUT'
  },
  frfUpdate: {
    name: 'frfUpdate',
    endPointUrl: `${serverHost}update-frf`,
    method: 'PUT'
  },
  updateStatus: {
    name: 'updateStatus',
    endPointUrl: `${serverHost}update-status`,
    method: 'PUT'
  },
  frfData: {
    name: 'frfData',
    endPointUrl: `${serverHost}create-frf`,
    method: 'POST'
  },
  trfTestDetails: {
    name: 'test',
    endPointUrl: `${serverHost}get-trf-tests`,
    method: 'GET'
  },
  getUserDetails: {
    name: 'getUserDetails',
    endPointUrl: `${serverHost}get-profile`,
    method: 'GET'
  },
  newUserDetails: {
    name: 'newUserDetails',
    endPointUrl: `${serverHost}create-profile`,
    method: 'POST'
  },
  supplierSearch: {
    name: 'supplierSearch',
    endPointUrl: `${serverHost}supplier-search`,
    method: 'GET'
  },
  locationSearch: {
    name: 'locationSearch',
    endPointUrl: `${serverHost}location-search`,
    method: 'GET'
  },
  materialSearch: {
    name: 'materialSearch',
    endPointUrl: `${serverHost}search-material`,
    method: 'GET'
  },
  labResults: {
    name: 'labResults',
    endPointUrl: `${serverHost}post-lab-results`,
    method: 'POST'
  },
  labResultsSubmit: {
    name: 'labResultsSubmit',
    endPointUrl: `${serverHost}set-lab-results`,
    method: 'PUT'
  },
  addMaterial: {
    name: 'addMaterial',
    endPointUrl: `${serverHost}add-material`,
    method: 'POST'
  },
  addSupplier: {
    name: 'addMaterial',
    endPointUrl: `${serverHost}add-supplier`,
    method: 'POST'
  },
  locationDetails: {
    name: 'locationDetails',
    endPointUrl: `${serverHost}location-details`,
    method: 'POST'
  },
  addLocation: {
    name: 'addLocation',
    endPointUrl: `${serverHost}add-location`,
    method: 'POST'
  },
  approveorReject_TD: {
    name: 'approveorReject_TD',
    endPointUrl: `${serverHost}evaluate-trf`,
    method: 'PUT'
  },
  updateLocation: {
    name: 'updateLocation',
    endPointUrl: `${serverHost}update-location`,
    method: 'PUT'
  },
  updateSupplier: {
    name: 'updateLocation',
    endPointUrl: `${serverHost}update-supplier`,
    method: 'PUT'
  },
  downloadAttachment: {
    name: 'downloadAttachment',
    endPointUrl: `${serverHost}get-attachment`,
    method: 'GET'
  },
  getTrfForEdit: {
    name: 'getTrfForEdit',
    endPointUrl: `${serverHost}get-trf`,
    method: 'GET'
  },
  trfUpdate: {
    name: 'trfUpdate',
    endPointUrl: `${serverHost}update-trf`,
    method: 'PUT'
  },
  userData: {
    name: 'userData',
    endPointUrl: `${serverHost}get-user-list`,
    method: 'GET'
  },
  userDataById: {
    name: 'userDataById',
    endPointUrl: `${serverHost}get-user-details`,
    method: 'GET'
  },
  updateUser: {
    name: 'updateUser',
    endPointUrl: `${serverHost}update-profile`,
    method: 'PUT'
  },
  supplierDetails: {
    name: 'supplierDetails',
    endPointUrl: `${serverHost}supplier-details`,
    method: 'GET'
  },
  getExceptionUsers: {
    name: 'getExceptionUsers',
    endPointUrl: `${serverHost}get-exception-users`,
    method: 'GET'
  },
  setExceptionUsers: {
    name: 'setExceptionUsers',
    endPointUrl: `${serverHost}set-tp2-users`,
    method: 'POST'
  },
  addUser: {
    name: 'addUser',
    endPointUrl: `${serverHost}add-user`,
    method: 'POST'
  },
  createNotification: {
    name: 'createNotification',
    endPointUrl: `${serverHost}set-notification`,
    method: 'POST'

  },
  setFavourites: {
    name: 'setFavourites',
    endPointUrl: `${serverHost}set-favorite`,
    method: 'PUT'
  },
  saveFilterHistory: {
    name: 'saveFilterHistory',
    endPointUrl: `${serverHost}search-history`,
    method: 'POST'
  },
  getFilterHistory: {
    name: 'getFilterHistory',
    endPointUrl: `${serverHost}get-search-history`,
    method: 'GET'
  },
  listNotification: {
    name: 'listNotification',
    endPointUrl: `${serverHost}list-notification`,
    method: 'GET'

  },
  deleteNotification: {
    name: 'deleteNotification',
    endPointUrl: `${serverHost}remove-notification`,
    method: 'PUT'
  },
  updateNotification: {
    name: 'updateNotification',
    endPointUrl: `${serverHost}update-notification`,
    method: 'PUT'

  },
  readNotification: {
    name: 'readNotification',
    endPointUrl: `${serverHost}/read-notification`,
    method: 'POST'

  },
  searchLicensee: {
    name: 'searchLicensee',
    endPointUrl: `${serverHost}search-licensee`,
    method: 'GET'

  },
  setLicensee: {
    name: 'setLicensee',
    endPointUrl: `${serverHost}set-licensee`,
    method: 'POST'

  },
  listLicensee: {
    name: 'listLicensee',
    endPointUrl: `${serverHost}list-licensee`,
    method: 'GET'

  },
  activeLicensee: {
    name: 'activeLicensee',
    endPointUrl: `${serverHost}active-licensee`,
    method: 'GET'

  },
  logVideo: {
    name: 'logVideo',
    endPointUrl: `${serverHost}log-video`,
    method: 'POST'

  },
  getArchive: {
    name: 'getArchive',
    endPointUrl: `${serverHost}search-archive`,
    method: 'GET'

  },
  getMaterialList: {
    name: 'getArchive',
    endPointUrl: `${serverHost}list-materials`,
    method: 'GET'

  },
  editMaterialStatus: {
    name: 'editMaterialStatus',
    endPointUrl: `${serverHost}material`,
    method: 'PUT'

  },
  userStatus: {
    name:'userStatus',
    endPointUrl:`${serverHost}user-status`,
    method: 'PUT'
  },
  listSuppliers: {
    name:'listSuppliers',
    endPointUrl:`${serverHost}list-suppliers`,
    method: 'GET'
  },
  supplierStatus: {
    name:'supplierStatus',
    endPointUrl:`${serverHost}supplier-status`,
    method:'PUT'
  },
  trfStatus: {
    name:'trfStatus',
    endPointUrl:`${serverHost}trf-status`,
    method:'PUT'
  },
  trfComments:{
    name:'trfComments',
    endPointUrl:`${serverHost}trf-comments`,
    method:'GET'
  },
  verifyOSHId:{
    name:'requestAccess',
    endPointUrl:`${serverHost}add-osh`,
    method:'POST'
  },
  addLitmos:{
    name:'addLitmos',
    endPointUrl:`${serverHost}add-litmos`,
    method:'POST'
  }
}