import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import moment from 'moment';
import isUndefined from 'lodash/isUndefined';
import { ArrowContainer,Popover } from 'react-tiny-popover';

import requireLogin from '../auth/requireLogin.js';
import { IconButton } from '../../components/index.js';

import {
    Container,
    DatePicker,
    Button,
    Radiobutton,
    PageHeader,
    H5,
    Spinner,
    ProgressBar,
    CommentModal
} from '../../components/index.js';
import {
    isSupplierUser,
    isLabUser,
    isRslUser,
    isFactoryUser,
    isNloUser,
    selectUserDetails,
    supplierName,
    getFavourites
  } from '../../reducers/authReducer.js';
import Grid from '../../components/grid/Grid.js';
import archiveConfig from '../../configs/gridConfigs/archive/archive.js';

import { getarchiveData, getArchiveRemainingPages, isArchiveLoading, getArchiveLoadStatus, getArchiveNextPage } from '../../reducers/archiveReducer.js';
import { createServiceAction } from '../../util/actionHelper.js';
import { clearArchive } from '../../actions/archiveActions.js'
import duration from '../../constants/json/archive/durations.json';
import pageConstants from './archive.constants.js';
import MultiTags from  '../../components/multiSearch/MultiTags.js'


export class Archive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paramsForExcel:{},
            isShow: false,
            params:null,
            isOpenComment: false,
            showCancel:false,
            checkboxCellParams:{},
            checkboxColId:null,
            newComments:null,
            clearTags:false,
            isPopoverOpen: false,
        };
        this.onPaginationChanged = this.onPaginationChanged.bind(this);
        sessionStorage.removeItem("ActionRequired");
        sessionStorage.removeItem("InProgress");
        sessionStorage.removeItem("Favorites");
    }
     componentDidMount(){
    const {change, archiveData}  = this.props;
    if(!archiveData){
        change('searchTerm',  null);
        change('duration',  null);
        change('fromDate',  null);
        change('toDate',  null);
        sessionStorage.setItem('searchAlPageNumber', 0)
    }
    sessionStorage.setItem("search_all",null);

     }
    
    componentWillUpdate(nextProps, nextState) {
        const { archive, change } = this.props;
        const { params } = this.state;
    if (!params && params !== nextState.params ) {
      let page = sessionStorage.getItem(`searchAlPageNumber`) ? sessionStorage.getItem(`searchAlPageNumber`) : 0;

      nextState.params && nextState.params.api.paginationGoToPage(Number(page))
    }
        if (nextProps.archive && 
            nextProps.archive.duration!== archive.duration && 
            nextProps.archive.duration === 'custome_date') {
                change('fromDate',  nextProps.archive.fromDate);
    
        }
    }
    onClickSubmit = (archive) => {
        const { requestArchive } = this.props;
        let fromDate=null,toDate =null;
        let day = new Date()
        const formatStr = 'YYYY-MM-DD';
        //sessionStorage.removeItem("search_all");
        sessionStorage.setItem("search_all",null);
        sessionStorage.setItem('searchAlPageNumber', 0)
        if(archive && archive.duration && archive.duration!=='custome_date'){
        day.setMonth(day.getMonth() - archive.duration)
        fromDate = moment.utc(day).format(formatStr)
        } else if(archive.duration ==='custome_date'){
            if( archive.fromDate){
                fromDate = moment.utc(archive.fromDate).format(formatStr)
            }
            if(archive.toDate){
                toDate = moment.utc(archive.toDate).format(formatStr)
            }

        }
        sessionStorage.setItem('searchTerm', archive.searchTerm?archive.searchTerm:null)
        sessionStorage.setItem('fromDate', fromDate?fromDate:null)
        sessionStorage.setItem('toDate', toDate?toDate:null)
        requestArchive(archive.searchTerm?archive.searchTerm:null,
            fromDate ? fromDate : null,
            toDate ? toDate : null)

    }

    onClickClear = () =>{
        const { change, requestClearData } = this.props;
        const { clearTags } = this.state
        this.setState({clearTags:!clearTags})
        change('searchTerm',  null);
        change('duration',  null);
        change('fromDate',  null);
        change('toDate',  null);
        requestClearData(null)
        //sessionStorage.removeItem("search_all");
        if(sessionStorage.getItem("search_all") !== 'null'){
          sessionStorage.setItem("search_all",null);
}

    }
    closeCommentModal = () => {
        const { checkboxCellParams } = this.state;
        this.setState({ isOpenComment: false });
        if(checkboxCellParams && checkboxCellParams.node){
         checkboxCellParams.node.selected = false;
         checkboxCellParams.node.setSelected(false,true);
        var row =  checkboxCellParams.api.getDisplayedRowAtIndex(checkboxCellParams.rowIndex);;
        checkboxCellParams.api.redrawRows({rowNodes:[row]})
        }
      };
    commentsChange = (comment) =>{
       this.setState({newComments:comment})
      }

    submitCommentsValue = () =>{
      const { trfStatusChange } = this.props;
      const { checkboxCellParams, newComments } = this.state;
      const statusUpdateTrfId = Number(checkboxCellParams.data.trfId.substr(2))
      const updatedStatus = !(checkboxCellParams.data.isActive)
      let term =null, fDate = null, tDate =null;
      if(sessionStorage.getItem('searchTerm') && sessionStorage.getItem('searchTerm') !== 'null'){
         term  = sessionStorage.getItem('searchTerm')
      }
      if(sessionStorage.getItem('fromDate') && sessionStorage.getItem('fromDate') !== 'null'){
        fDate  = sessionStorage.getItem('fromDate')
     }
     if(sessionStorage.getItem('toDate') && sessionStorage.getItem('toDate') !== 'null'){
      tDate  = sessionStorage.getItem('toDate')
   }
      
      this.setState({ isOpenComment: false });
      trfStatusChange(statusUpdateTrfId, updatedStatus, newComments,term, fDate, tDate)

      }

    onRowClick = params => {
        const colId = params.api.getFocusedCell().column.colId
        const { history, isLabUser, isRslUser, isNloUser, selectedButton, userId } = this.props;
        const trfId = Number(params.data.trfId.substr(2));
       
        if (colId !== 'proceedAction' && isUndefined(params.event.target.checked) && colId !== 'activeStatus'&& colId !== 'notes') {

          if (isLabUser) {
            history.push('/labresult', { trfId: trfId });
          }
          else if (selectedButton === 'Favorites' && params.data.statusValue === 'Review') {
            history.push('/trf', { trfId:trfId, preLoadMaterial:true });
          }
          else if (selectedButton !== 'InProgress' && params.data.overAllStatus !== 'In Progress') {
            history.push('/test-details', { trfId: trfId, rslId: params.data.trfId });
          }
          
          else if (isRslUser || isNloUser ) {
           
            if (params.data.statusValue === 'Review' && params.data.userId === userId && params.data.isActive) {
             
              history.push('/trf', { trfId: trfId, isPartialEdit: false,preLoadMaterial:true });
            }
            else if((params.data.isActive) && 
            ((params.data.overAllStatus === 'In Progress' && params.data.userId !== userId && params.data.trfId) ||
            (params.data.statusValue === 'Test pending with Lab' && params.data.userId === userId && params.data.trfId)) ) {
              history.push('/trf', { trfId: trfId, isPartialEdit: true,preLoadMaterial:true });
            }
            else {
              history.push(`/download-trf`, { trfId: trfId });
            }
          }
          else {
            if (params.data.frfId) {
              history.push(`/download-frf`, { trfId: trfId, qrfId: params.data.qrfId, frfId: params.data.frfId.substr(4) });
            }
            else if (params.data.qrfId) {
              history.push(`/download-qrf`, { trfId: trfId, qrfId: params.data.qrfId });
            }
            else if (params.data.statusValue === 'Review') {
              history.push('/trf', { trfId: trfId, isPartialEdit: false,preLoadMaterial:true });
            }
            else {
              history.push(`/download-trf`, { trfId:trfId });
            }
          }
        }
      };

    onCellClicked = (params) => {
        const { updateFavourites, supplierName, isRslUser, requestComments } = this.props;
        const colId = params.api.getFocusedCell().column.colId
        const trfId = Number(params.data.trfId.substr(2));

        if ( colId === 'favourites') {
            if (params.event.target.checked !== undefined) {
              updateFavourites({ trfId: trfId, status: params.event.target.checked, buttonStatus: 'All', supplierName: supplierName });
            }
          } else  if (isRslUser  && colId === 'activeStatus') {
            if (params.event.target.checked !== undefined) {
              this.setState({isOpenComment:true, commentsAreaShow:true, showCancel:false })
              this.setState({checkboxCellParams:params, checkboxColId: colId})
              requestComments(trfId)

            }
          } else if(isRslUser && colId === 'notes') {

             requestComments(trfId)
             this.setState({isOpenComment:true, commentsAreaShow:false, showCancel:true })
            
          }

    }
    

    setParams = params => {
        const { paramsForExcel } = this.state;
        const { isRslUser, isLabUser, isSupplierUser, isFactoryUser, isNloUser } = this.props;
        this.setState({ params: params });
        if (paramsForExcel) {
          params.columnKeys = ["trfId","supplierName","supLocName","materialType","material","otherMaterial", "materialNumber","refId","PDMNumber","brand","product","labLocation","defaultTest","overAllStatus","labRecDate","statusValue","serviceRequested","favourites","activeStatus"];
          params.fileName = `Trf_List`;
          params.sheetName = `Trf_List`;
          this.setState({ paramsForExcel: params });
        }
       
    // Diplaying columns based on user group
    const rslUserColumns = isRslUser && [...pageConstants.rslUserColumns];
    const nloUserColumns = isNloUser && [...pageConstants.supplierUserColumns];
    const labUserColumns = isLabUser && [...pageConstants.labUserColumns];
    const supplierUserColumns = isSupplierUser && [...pageConstants.supplierUserColumns];
    const factoryUserColumns = isFactoryUser && [...pageConstants.factoryUserColumns];

    const columnsToDisplay = rslUserColumns || labUserColumns || supplierUserColumns || factoryUserColumns || nloUserColumns;
    this.setState({ gridApi: params.api, params: params });
    if(params.columnApi){
    const allColumns = params.columnApi.getColumnState();
    const filteredColumns = allColumns.map(column => {
      if (columnsToDisplay.indexOf(column.colId) === -1) {
        column.hide = true;
      } else {
        column.hide = false;
      }
      return column;
    });
    params.columnApi.setColumnState(filteredColumns);
    // Fix for IE
    params.api.sizeColumnsToFit();
  }
      };

    exportExcel = (params) => {
        params.api.exportDataAsExcel(params);
      }

    getRowDataForGrid(rows, favArr) {
        sessionStorage.removeItem("ActionRequired");
        sessionStorage.removeItem("InProgress");
        sessionStorage.removeItem("Favorites");
        if (favArr) {
            rows.forEach(item => {
              let favObj = favArr.filter(dataObj => dataObj ===  Number(item.trfId.substr(2)))[0] ? true : false;
              item.favExists = favObj;
            });
      
          }
       
          //sessionStorage.setItem("search_all", JSON.stringify(rows));
                  
          if(sessionStorage.getItem('search_all') !== 'null'){
            sessionStorage.setItem("search_all", JSON.stringify(rows));
            //sessionStorage.setItem("search_all", JSON.stringify(rows));
          }    
        return rows;
    }
    
    onPaginationChanged = (e) => {
        const { params } = this.state;

        let pageNumber = sessionStorage.getItem(`searchAlPageNumber`) ? sessionStorage.getItem(`searchAlPageNumber`) : 0;
    
        if (e.newPage) {
            sessionStorage.setItem('searchAlPageNumber', params.api.paginationGetCurrentPage())
          } else if (e.newData) {
            params && params.api.paginationGoToPage(Number(pageNumber))
          }
    }
    displayPopOver = () => {
      const { isPopoverOpen } = this.state;
      const togglePopover = !isPopoverOpen;
      this.setState({ isPopoverOpen: togglePopover });
    };


    render() {
       const { archive, archiveData, isArchiveLoading, getFavourites, archiveNextPage, searchAllLoadCompleted, commentsDetails } = this.props;
        const { paramsForExcel , isOpenComment, commentsAreaShow, showCancel,isPopoverOpen, clearTags } = this.state;
        let totalPages= 0;
        if(archiveData){
            totalPages = Math.ceil(parseInt(archiveData.data.count/500))+1
        }
        return (
            <span>

                <div className="pb2-sm">
                    <div className="archive-page-align-items" data-testid="archive-component">
                        <PageHeader title="SEARCH ALL">
                            <div className="user-page ie-icon-align">
                                <i className="fas fa-search" />
                            </div>
                        </PageHeader>
                        <Container section>
                            <span>
                                <div className="ncss-row p2-bottom-sm displayFlex">
                                    <div className="ncss-col-sm-offset-2 ncss-col-sm-2 inline-flex-display">
                                    <H5 className="u-bold description p2-top-sm p2-bottom-sm add-supplier-text">Search Text
                                    <Popover
                  isOpen={isPopoverOpen}
                  position="bottom"
                  padding={5}
                  onClickOutside={this.displayPopOver}
                  content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer
                      position={position}
                      childRect={childRect}
                      popoverRect={popoverRect}
                      arrowColor="#dddcde"
                      arrowSize={0}
                      arrowStyle={{ opacity: 0.7, left: '120px' }}
                    >
                      <IconButton icon="times" className="popover-button" onClick={this.displayPopOver} />
                      <div className="popover-trfsection">
                        <H5 className="u-bold ">GUIDELINES FOR SEARCH TEXT</H5>
                        <ol className="ncss-list-ol ol-font-size">
                          <li className="ncss-li">
                            Press Enter after each search string.
                          </li>
                          <li className="ncss-li">
                            Please enter 'eye-wear' for all records having eye-wear OR 'no-eye-wear' in case of non eye-wear records.
                          </li>
                          <li className="ncss-li">
                            Please enter the complete TRF number eg: R-0003270 to get the details of all the RE-TEST TRF linked records.
                          </li>                                                   
                        </ol>
                      </div>
                    </ArrowContainer>
                  )}
                >
                  <span className='info-box'>
                    <IconButton icon="comment-alt" extraClassName="color-trf" onClick={this.displayPopOver} />
                  </span>
                </Popover>
                                        <span className=""></span></H5>
                                    </div>
                                    <div id="multiSearch" className=' ncss-col-sm-6 ncss-input-container pr0-sm pl2-sm'>
                                       <Field
                                        component={MultiTags}
                                        type='text' 
                                        name="searchTerm"
                                        placeholder='Search ...'
                                        className="tagArea__input"
                                        containerClass="archive-search-area-height-adjust"
                                        clearTags={clearTags}
                                        key={0}
                                        />
                                    </div>


                                </div>

                                <div className="ncss-row p2-bottom-sm">
                                    <div className="ncss-col-sm-offset-2 ncss-col-sm-2 p2-bottom-sm add-supplier-text">
                                        Select Duration 
                                    </div>
                                    <div className="ncss-col-sm-8  ncss-radio-container pl0-sm">
                                        {duration.map(data => (
                                            <div key={data.label} className="ncss-col-sm-3  u-bold ">
                                            <Field
                                                type="radio"
                                                component={Radiobutton}
                                                name="duration"
                                                value={data.value}
                                                checked={false}
                                                label={data.label}
                                                id={data.id}
                                                mandatory={data.mandatory}
                                                isIcon={false}
                                            />
                                            </div>
                                    ))}
                                    </div>
                                </div>
                                {archive && archive.duration&& archive.duration === 'custome_date'?

                                <div className="ncss-row">
                                    <div className="ncss-col-sm-offset-2 ncss-col-sm-2 inline-flex-display">
                                        <H5 className="u-bold description p2-top-sm p2-bottom-sm add-supplier-text">From Date</H5>
                                    </div>
                                    <div className='ncss-col-sm-2 '>
                                        <Field
                                            component={DatePicker}
                                            defaultValue={archive.fromDate?archive.fromDate:null}
                                            showTime={false}
                                            placeholder="From Date"
                                            name="fromDate"
                                            max={new Date()}
                                        />
                                    </div>
                                    <div className=" ncss-col-sm-offset-1 ncss-col-sm-1 inline-flex-display">
                                        <H5 className="u-bold description p2-top-sm p2-bottom-sm add-supplier-text pl1-sm">To Date</H5>
                                    </div>
                                    <div className=' ncss-col-sm-2 pl0-sm pr0-sm'>
                                        <Field
                                            component={DatePicker}
                                            showTime={false}
                                            defaultValue={archive.toDate?archive.toDate:null}
                                            placeholder="To Date"
                                            name="toDate"
                                            max={new Date()}
                                        />
                                    </div>
                                </div>
                                :
                                null
                                }
                                <div className="ncss-row">
                                    <div className="ncss-col-sm-12 align-center pt5-sm">
                                        {archive &&(( archive.duration && archive.duration!=='custome_date')||
                                         (archive.searchTerm) ||
                                         (archive.duration && archive.duration==='custome_date' &&(archive.fromDate || archive.toDate))) ?
                                            <Button
                                                className="ncss-btn-primary-dark u-bold submit-button-enable archive-button"
                                                onClick={() => this.onClickSubmit(archive)}
                                            >
                                                SEARCH
                                        </Button>
                                            :
                                            <Button className="ncss-btn-grey u-bold submit-button-enable archive-button" disabled>
                                                SEARCH
                                        </Button>
                                        }
                                        <Button
                                            className="ncss-btn-secondary-dark u-bold secondary-button archive-button"
                                            onClick={() => this.onClickClear()}
                                        >
                                            CLEAR
                                        </Button>
                                        <span className="">
                                        {archiveData && archiveData.data && archiveData.data.rows&&archiveData.data.count&&archiveData.data.count>0&&archiveData.data.rows.length>=archiveData.data.count  ?
                      <span className="archive-export mr5-sm archive-export-button">
                        <i className="fa fas fa-download" />
                        <Button onClick={()=>this.exportExcel(paramsForExcel)} className="archive-export-caption text-color-accent">
                          Export Excel
                      </Button>
                      </span>
                       :
                      <span>
                      {archiveData && archiveData.data&& archiveData.data.count>0?
                      <span>
                        <span className=" archive-export-caption text-color-accent">Loading Reports</span>
                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

                      </span>
                      :
                      null}
                      </span>
                    } 
                  </span>
                                    </div>
                                </div>

                            </span>


                        </Container>
                        {!isArchiveLoading ?
                        <span>
                            {archiveData&&archiveData.data.count&&archiveData.data&&!searchAllLoadCompleted&&
                            archiveNextPage<=totalPages?
                  <div className="ncss-col-sm-12 progress_bar">
                    <ProgressBar percent={(archiveNextPage / totalPages) * 100}
                      message={"Loading"} />
                  </div>
                  : null
                }
                            <span>
                                {(archiveData && archiveData.data && archiveData.data.rows)||
                                  ((sessionStorage.getItem("searchLoadFinish")) && sessionStorage.getItem("searchLoadFinish")==='done'&&sessionStorage.getItem('search_all') !== 'null')? 
                                   <Container section>
                                        <div className="ncss-col-sm-12 ">
                                            <Grid
                                                rowData={
                                                  sessionStorage.getItem("searchLoadFinish")==='done'&&sessionStorage.getItem('search_all') !== 'null'?
                                                    JSON.parse(sessionStorage.getItem('search_all'))
                                                        :
                                                        this.getRowDataForGrid(archiveData.data.rows, getFavourites)
                                                    }
                                                columnDefs={archiveConfig} 
                                                setParams={this.setParams}
                                                onRowClick={this.onRowClick}
                                                onCellClicked= {this.onCellClicked}
                                                onPaginationChanged={this.onPaginationChanged}
                                            />
                                        </div>
                                    </Container>
                                    :
                                    null
                                }
                                </span>
                            </span> :
                            <Spinner large />
                        }
                    </div>
                </div>
                <CommentModal
                   isOpenComment={isOpenComment}
                   commentsDetails={commentsDetails}
                   closeModal={this.closeCommentModal}
                   commentAreaChange= {this.commentsChange}
                   submitValue = {this.submitCommentsValue}
                   commentsAreaShow={commentsAreaShow}
                   showCancel={showCancel}
                />

            </span >
        );
    }
}

Archive.propTypes = {

};

const mapStateToProps = state => ({
    archive: getFormValues('Archive')(state),
    archiveData: getarchiveData(state),
    remainingPages: getArchiveRemainingPages(state),
    archiveNextPage:getArchiveNextPage(state),
    isArchiveLoading: isArchiveLoading(state),
    searchAllLoadCompleted: getArchiveLoadStatus(state),
    userId: selectUserDetails(state).userId,
    isSupplierUser: isSupplierUser(state),
    isLabUser: isLabUser(state),
    isRslUser: isRslUser(state),
    isFactoryUser: isFactoryUser(state),
    isNloUser: isNloUser(state),
    getFavourites: getFavourites(state),
    supplierName: supplierName(state),
    commentsDetails: state.adminUser.comments,

});

const mapDispatchToProps = dispatch => ({

    requestArchive: (searchTerm, fromDate, toDate) => {

        // Get data to show on grid
        dispatch(createServiceAction('getArchive', 'requested')({ q: searchTerm, fromDate: fromDate, toDate: toDate }));

    },

    updateFavourites: (data) => {
        dispatch(createServiceAction('updateFavourites', 'submitted')(data));
      },

    requestComments:(id) =>{
       dispatch(createServiceAction('listComments','requested')({id:id}))
    },

    trfStatusChange:(id, status, comment, searchTerm, fromDate, toDate) =>{
      dispatch(createServiceAction('setTrfStatus','submitted')({id:id, status:status, comment:comment,searchTerm:searchTerm,fromDate:fromDate,toDate:toDate}))
   },

    requestClearData: (params) => {
        dispatch(clearArchive(params))
      },

});

export default compose(
    requireLogin('User'),
    reduxForm({
        form: 'Archive',
        initialValues: {
            //duration: 12
          },
          destroyOnUnmount: false
    }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Archive);