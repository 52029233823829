import React, { Component } from 'react';
import { reduxForm, Field, getFormValues, reset } from 'redux-form';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { ArrowContainer,Popover } from 'react-tiny-popover';

import { createServiceAction } from '../../../util/actionHelper.js';
import { H4, H5, TextInput, Button, IconButton, Radiobutton } from '../../../components/index.js';
import newMaterialTypes from '../../../constants/json/radiobuttons/addNewMaterialTypes.json';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#app-host');

class AddMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopoverOpen: false
    };
    this.filterTrfData = this.filterTrfData.bind(this);
  }
  filterTrfData = (addMaterialStates, supplierLocationId, supplierId) => {
    const resultArray = {};
    const { submitMaterialData, productId } = this.props;

    resultArray.supplierId = supplierId;
    resultArray.materialName = addMaterialStates.materialName;
    resultArray.refId = addMaterialStates.referenceId;
    resultArray.materialDesc = addMaterialStates.materialDescription;
    resultArray.isExist = addMaterialStates.typeOfMaterial;
    resultArray.supplierLocationId = supplierLocationId;
    resultArray.productId = productId;
    submitMaterialData(resultArray);
  };
  displayPopOver = () => {
    const { isPopoverOpen } = this.state;
    const togglePopover = !isPopoverOpen;
    this.setState({ isPopoverOpen: togglePopover });
  };

  render() {
    const { isModalOpen,
      toggleModal,
      addMaterialStates,
      supplierLocationId,
      supplierId,
      clearMaterialFilled,
      isMaterialFilled } = this.props;
    const { isPopoverOpen } = this.state;
    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => { toggleModal(false); clearMaterialFilled() }}
        className="modal-container add-material-modal-container"
        overlayClassName="modal-overlay"
      >
        <IconButton icon="times" className="modal-close-btn" onClick={() => { toggleModal(false); clearMaterialFilled() }} />
        <form className="form">
          <div className="ncss-col-sm-5">
            <div className="p2-sm">
              <H4 className="text-color-accent u-bold page-title-has-back sub-heading">ADD NEW MATERIAL</H4>
            </div>
            <div className="ncss-col-sm-12 ncss-input-container ">
              <H5 className="u-bold description p2-top-sm p2-bottom-sm">Material Name</H5>
              <Field component={TextInput} name="materialName" type="text" />
            </div>
            <div className="ncss-col-sm-12 ncss-input-container ">
              <H5 className="u-bold description p2-top-sm p2-bottom-sm">Material ID
            <span>
                  {' '}
                  <Popover
                    isOpen={isPopoverOpen}
                    position="bottom"
                    padding={5}
                    onClickOutside={this.displayPopOver}
                    content={({ position, targetRect, popoverRect }) => (
                      <ArrowContainer
                        position={position}
                        targetRect={targetRect}
                        popoverRect={popoverRect}
                        arrowColor="#dddcde"
                        arrowSize={0}
                        arrowStyle={{ opacity: 0.7, left: '120px' }}
                      >
                        <IconButton icon="times" className="popover-button-new-materialsection" onClick={this.displayPopOver} />
                        <div className="popover-new-materialsection">
                          <div>Use Nike Material number or Style number if known.  For new materials use your own material number if available</div>
                        </div>
                      </ArrowContainer>
                    )}
                  >
                    <span>
                      {' '}
                      <IconButton icon="comment-alt" extraClassName="color-trf" onClick={this.displayPopOver} />
                    </span>
                  </Popover>
                </span>
              </H5>
              <Field component={TextInput} name="referenceId" type="text" />
            </div>
            <div className="ncss-col-sm-12 ncss-input-container ">
              <H5 className="u-bold description p2-top-sm p2-bottom-sm">Material Description</H5>
              <Field component={TextInput} name="materialDescription" type="text" />
            </div>
          </div>
          <div className="ncss-col-sm-7 pcx-attribute">
            <div className="ncss-col-sm-12 ncss-input-container ">
              <H5 className="u-bold description p2-top-sm p2-bottom-sm">Type Of Material</H5>
              <div className="ncss-col-sm-12 ">
                {newMaterialTypes.map((newMaterialData, pcxIndx) => (
                  <span key={newMaterialData.label}>
                    <div key={newMaterialData.label} className="ncss-col-sm-12 u-bold pb1-sm">
                      <span className="ncss-radio-container">
                        <Field
                          type="radio"
                          component={Radiobutton}
                          name="typeOfMaterial"
                          value={newMaterialData.value}
                          checked={false}
                          label={newMaterialData.label}
                          isIcon={false}
                          id={newMaterialData.id}
                        />
                      </span>
                    </div>
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="ncss-col-sm-12 ">
            <div className="bottom-section p4-top-sm">
              <Button
                className="ncss-btn-secondary-dark u-bold secondary-button trf-button-selected"
                onClick={() => { toggleModal(false); clearMaterialFilled() }}
              >
                CANCEL
          </Button>
              {addMaterialStates &&
                addMaterialStates.materialName &&
                addMaterialStates.materialDescription &&
                isMaterialFilled ?
                <Button className="ncss-btn-grey u-bold new-material"
                  onClick={() => {
                    this.filterTrfData(addMaterialStates, supplierLocationId, supplierId)
                    toggleModal(false); clearMaterialFilled()
                  }}
                >
                  SUBMIT
          </Button> :
                <Button className="ncss-btn-grey u-bold new-material disabled " disabled>
                  SUBMIT
    </Button>}
            </div>
          </div>
        </form>
      </Modal>
    );
  }
}

AddMaterial.propTypes = {
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  clearMaterialFilled: PropTypes.func,
};
const mapStateToProps = state => ({
  addMaterialStates: getFormValues('addMaterial')(state),
  isMaterialFilled: state.trf.isMaterialFilled
});

const mapDispatchToProps = dispatch => ({
  submitMaterialData: (data) => {
    dispatch(createServiceAction('addMaterial', 'submited')(data));
  },
  clearMaterialFilled: () => {
    const forms = ['addMaterial'];
    forms.map(form => dispatch(reset(form)));
  },
});

export default reduxForm({ form: 'addMaterial' })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddMaterial));
