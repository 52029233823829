import React, { Component } from 'react';
import moment from 'moment';

import { Spinner } from '../../index.js';
class CommentsCard extends Component {
  render() {
    const { commentsDetails } = this.props;

    return (
      <span>
      
      
        {commentsDetails?
        <span>
          {commentsDetails.length>0?
          <span>
          {commentsDetails.map((comments,index)=>(
            <span>
        <div key={index} className="mb1-sm outer-box">
        <div className="ncss-col-sm-6 comments-info-section">
        <div className="ncss-col-sm-12 "> 
         <div className="ncss-col-sm-12 chart-view-popup"> Update By: <span className="pl1-sm">{comments.commentBy}</span> </div>
         </div>
         <div className="ncss-col-sm-12 "> 
         <div className="ncss-col-sm-10 chart-view-popup">Update On: <span className="pl1-sm">{moment.utc(comments.createdAt).format('DD-MMM-YYYY')}</span> </div>
        </div>
         </div>
         <div className="ncss-col-sm-5 comments-text">
            {comments.comment}
        </div>
        </div>
        </span>
          ))} 
        </span>
        :
        <div>No Previous Commets</div>
          }
        </span>
        :
        <Spinner large />
        }
       
       
       </span>
    );
  }
}
CommentsCard.propTypes = {
 
};

export default CommentsCard;
