import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { H4, H6 } from '../../../components/index.js';
import find from 'lodash/find';
import union from 'lodash/union';
import filter from 'lodash/filter';
import productInfo from '../../../constants/json/checkboxes/productInfo.json';
import materialTypes from '../../../constants/json/radiobuttons/materialTypes.json';
import individualTest from '../../../constants/json/checkboxes/individualTestPackages.json';
import Season from '../../../constants/json/checkboxes/season.json';
import location from '../../../constants/json/radiobuttons/location.json';
import brandInfo from '../../../constants/json/checkboxes/brandInfo.json';
import { flattenItems, strUpperCase, trfSampleTypeName, trfAgeGroupName, trfCertificationName } from '../../../util/index.js';

const TestPackageComponent = ({ trfDetails }) => {
  const finalTestPackages = Array.isArray(trfDetails.testPackages) ? trfDetails.testPackages.filter((ele) => ele !== null) : []
  let testsUnion = union(
    individualTest[0].common,
    individualTest[0].toys,
    individualTest[0].electronic,
    individualTest[0].packaging,
    individualTest[0].jewelry
  );
  return (
    <div>
      <div className="ncss-col-sm-3 description u-bold p2-sm ">Test Packages </div>
      <div className="ncss-col-sm-9  u-bold  inline-flex-display pl0-sm">
        <div className="p2-sm">
          {finalTestPackages && finalTestPackages.length > 0 ? 
           trfDetails.testPackages && trfDetails.testPackages.map(trfInfo =>
            trfInfo !== null ? (
              <div key={trfInfo} className="ncss-col-sm-12 pl0-sm  u-bold">
                {filter(
                  testsUnion,
                  { variants: [{ id: trfInfo }] }
                ).length >= 1 ? (
                    <H6 className="pt2-sm pb2-sm pl0-sm">
                      {
                        filter(
                          testsUnion,
                          { variants: [{ id: trfInfo }] }
                        )[0].label
                      }
                     {trfInfo !== 594 && trfInfo !== 506 && trfInfo !== 429 ? 
                        find(flattenItems(testsUnion,'variants'),['id', trfInfo]) ?  ' - '+ find(flattenItems(testsUnion,'variants'),['id', trfInfo]).label : null
                       : ' ' }
                    </H6>
                  ) : ( find(testsUnion, ['id', trfInfo]) ?
                     trfInfo !== 429 ?  (
                      <H6>
                       { find(testsUnion, ['id', trfInfo]).label }
                      </H6>
                    ) : ''
                    : null
                  )}
              </div>
            ) : null
          ) : (<div  className="ncss-col-sm-12 pl0-sm  u-bold">
          <H6 className="pt2-sm pb2-sm pl0-sm">Not Added</H6></div>)}
        </div>
      </div>
    </div>
  );
}
const returnMaterialSection = (trfDetails) => {
  return (
    <div className="ncss-col-sm-9  u-bold  inline-flex-display pl0-sm">
        {trfDetails && trfDetails.materialType && trfDetails.materialType !== null && trfDetails.materialType.length > 0 ?
          <div className="p2-sm">
            {trfDetails.materialType.map(trfInfo =>
              trfInfo !== null ? (
                <div key={trfInfo} className="ncss-col-sm-12 pl0-sm  u-bold">
                  {trfInfo === 4050 || trfInfo === 4051  ? (
                      <H6 className="pt2-sm pb2-sm pl0-sm">
                        { "Inks and Paints" } {' - '} {trfInfo === 4050 || trfInfo === 4051 ? 'Screenprint Inks' : '' } {' - '} {trfInfo === 4050 ? 'Not White' : 'White' }
                      </H6>
                    ) : ''}
                  {filter(materialTypes, { variants: [{ id: trfInfo }] }).length >= 1 ? (
                    <H6 className="pt2-sm pb2-sm pl0-sm">
                      {
                        filter(materialTypes, { variants: [{ id: trfInfo }] })[0].label
                      }
                      {' - '}
                      {
                        find(flattenItems(materialTypes, 'variants'), ['id', trfInfo]).label
                      }
                    </H6>
                  ) : find(flattenItems(materialTypes, 'variants'), ['id', trfInfo])?
                 (
                      <H6>
                        {' '}
                        {
                          find(flattenItems(materialTypes, 'variants'), ['id', trfInfo]) ? find(flattenItems(materialTypes, 'variants'), ['id', trfInfo]).label : ''
                        }
                      </H6>
                    ):null}
                </div>
              ) : null
            )}
          </div>
          :
          <div className="p2-sm error-text-not-ink">No Materials Added</div>
          }
      </div>
  )
}
const returnInkSection = (trfDetails) => {
  return (
    trfDetails && typeof trfDetails !== 'undefined' && trfDetails.materialType && trfDetails.pColor ?
        (<div className="ncss-col-sm-12">
          <span className="ncss-col-sm-offset-3 ncss-col-sm-9">
            <H6 className="  ncss-col-sm-3 u-bold description flex-head-center ink-label">
              Ink System Name
                </H6>
            {typeof trfDetails.inkSystem !== 'undefined' && trfDetails.inkSystem !== null && trfDetails.inkSystem !== '' ? (
              <H6 className="  ncss-col-sm-6 u-bold flex-head-center ink-text align-left">
                {trfDetails.inkSystem}
              </H6>
            ) : (
                <H6 className="  ncss-col-sm-6 u-bold error-text-not-ink flex-head-center align-left">
                  Not Added
                </H6>
              )}
          </span>
          <span className="ncss-col-sm-offset-3 ncss-col-sm-9">
            <H6 className="  ncss-col-sm-3 u-bold description flex-head-center ink-label">
              Base Name
                </H6>
            {typeof trfDetails.bName !== 'undefined' && trfDetails.bName !== null && trfDetails.bName !== '' ? (
              <H6 className="  ncss-col-sm-6 u-bold flex-head-center ink-text align-left">
                {trfDetails.bName}
              </H6>
            ) : (
                <H6 className="  ncss-col-sm-6 u-bold error-text-not-ink flex-head-center align-left">
                  Not Added
                </H6>
              )}
          </span>
          <span className="ncss-col-sm-offset-3 ncss-col-sm-9">
            <H6 className="  ncss-col-sm-3 u-bold description flex-head-center ink-label">
              Pigment Color
                </H6>
            {typeof trfDetails.pColor !== 'undefined' && trfDetails.pColor !== null && trfDetails.pColor !== '' ? (
              <H6 className="  ncss-col-sm-6 u-bold flex-head-center ink-text align-left">
                {trfDetails.pColor}
              </H6>
            ) : (
                <H6 className="  ncss-col-sm-6 u-bold error-text-not-ink flex-head-center align-left">
                  Not Added
                </H6>
              )}
          </span>
          <span className="ncss-col-sm-offset-3 ncss-col-sm-9">
            <H6 className="  ncss-col-sm-3 u-bold description flex-head-center ink-label">
              Additive 1 Name
                </H6>
            {typeof trfDetails.addit1 !== 'undefined' && trfDetails.addit1 !== null && trfDetails.addit1 !== '' ? (
              <H6 className="  ncss-col-sm-6 u-bold flex-head-center ink-text align-left">
                {trfDetails.addit1}
              </H6>
            ) : (
                <H6 className="  ncss-col-sm-6 u-bold error-text-not-ink flex-head-center align-left">
                  Not Added
                </H6>
              )}
          </span>
          <span className="ncss-col-sm-offset-3 ncss-col-sm-9">
            <H6 className="  ncss-col-sm-3 u-bold description flex-head-center ink-label">
              Additive 2 Name
                </H6>
            {typeof trfDetails.addit2 !== 'undefined' && trfDetails.addit2 !== null && trfDetails.addit2 !== '' ? (
              <H6 className="ncss-col-sm-6 u-bold flex-head-center ink-text align-left">
                {trfDetails.addit2}
              </H6>
            ) : (
                <H6 className="  ncss-col-sm-6 u-boldy error-text-not-ink flex-head-center ink-text align-left">
                  Not Added
                </H6>
              )}
          </span>
          <span className="ncss-col-sm-offset-3 ncss-col-sm-9">
            <H6 className="  ncss-col-sm-3 u-bold description flex-head-center ink-label">
              Additive 3 Name
                </H6>
            {typeof trfDetails.addit3 !== 'undefined' && trfDetails.addit3 !== null && trfDetails.addit3 !== '' ? (
              <H6 className="  ncss-col-sm-6 u-bold flex-head-center ink-text  align-left">
                {trfDetails.addit3}
              </H6>
            ) : (
                <H6 className="  ncss-col-sm-6 u-bold error-text-not-ink flex-head-center ink-text align-left">
                  Not Added
                </H6>
              )}
          </span>
        </div>) : null
  )
}

const displayCertification = (testingInfoStates) => {
  const certificationArry = testingInfoStates.certification
  return (
    <div>
      {certificationArry && certificationArry.length > 0 && typeof certificationArry !== 'undefined'
       && Object.keys(certificationArry).length !== 0? (
        certificationArry.map((data, index) => {
       return (
        <Fragment><div class="ncss-col-sm-3 description u-bold p2-sm  flex-head-center">{index === 0 ? "Certificate" : '' } </div>
        <div class="ncss-col-sm-9 p2-sm inline-flex-display material_description ml-7">
          <div class="ncss-col-sm-12 h-20">
            <h6 class=" ncss-col-sm-5 tab-class u-bold ">{trfCertificationName(data.name) === 'Other' ? strUpperCase(data.name) : trfCertificationName(data.name)}</h6>
            <div class="ncss-col-sm-2 description u-bold p2-sm ">Number</div>
            <div class="ncss-col-sm-5 p2-sm inline-flex-display  ">
                <h6 class="ncss-col-sm-5 tab-class u-bold ">{data.number}</h6>
            </div>
          </div>
        </div></Fragment>
       )
      })
      ) : ''}
    </div>
  )
}
const MaterialTypeComponent = ({ trfDetails }) => {
  if (!trfDetails.materialType) {
  }
  //To remove the plastic radio button id from Material Type
  const materialTypeArry = trfDetails.materialType.filter((ele) => ele!== 429)
  return (
    <div>
       { (trfDetails.product !== 204 &&  trfDetails.product !== 206)? (
        <Fragment>
      <div className="ncss-col-sm-3 description u-bold p2-sm ">Material Types</div>
      {returnMaterialSection(trfDetails)}
      {returnInkSection(trfDetails)}
        <div>
        </div>
        </Fragment>) : ''}
        { (trfDetails.product === 204 || trfDetails.product === 206) ? (
        <Fragment>
      <div className="ncss-col-sm-3 description u-bold p2-sm ">Material Types </div>
     
      {trfDetails && trfDetails.materialType && trfDetails.materialType !== null && materialTypeArry.length > 0 &&
      ( <Fragment>
        {returnMaterialSection(trfDetails)}
        {returnInkSection(trfDetails)}
      </Fragment>)
       
        }
        <div>
        </div>
        </Fragment>) : ''}
    </div>
   
  );
}

const BrandAndProductTypeComponent = ({ trfDetails }) => {
  return (
    <Fragment>
      <div>
        <div className="ncss-col-sm-3 description p2-sm u-bold p2-sm">Brand Tested For</div>
        {typeof trfDetails.brand !== 'undefined' ? (
          <div className="ncss-col-sm-9  u-bold">
            {typeof trfDetails.brand !== 'undefined' && trfDetails.brand !== null ? (
              <H6 className="ncss-col-sm-5 pl0-sm ">
              {find(brandInfo, ['id',  trfDetails.brand]).value}
            </H6>
            ) : (
                <H6 className="error-text-not text-left ">Not Added</H6>
              )}
          </div>
        ) : (
            <div className="ncss-col-sm-2">
              <H6 className="error-text-not text-left ">Not Added</H6>
            </div>
          )}
      </div>
      <div className="ncss-col-sm-3 description u-bold p2-sm">Product Type</div>
      {typeof trfDetails.product !== 'undefined' && trfDetails.product !== null ? (
        <div className="ncss-col-sm-9  u-bold">
          {typeof trfDetails.product !== 'undefined' ? (
            <H6 className="ncss-col-sm-5 pl0-sm ">
              {find(productInfo, ['id', trfDetails.product]).label}
            </H6>
          ) : (
              <H6 className="error-text-not text-left ">Not Added</H6>
            )}
        </div>
      ) : (
          <div className="ncss-col-sm-2">
            <H6 className="error-text-not text-left ">Not Added</H6>
          </div>
        )}
      <div className="ncss-col-sm-3 description u-bold p2-sm">Licensee</div>
      {typeof trfDetails.licensee !== 'undefined' && trfDetails.licensee !== null ? (
        <div className="ncss-col-sm-9  u-bold">
          {typeof trfDetails.licensee !== 'undefined' ? (
            <H6 className="ncss-col-sm-5 pl0-sm ">
              {trfDetails.licensee}
            </H6>
          ) : (
              <H6 className="error-text-not text-left ">Not Added</H6>
            )}
        </div>
      ) : (
          <div className="ncss-col-sm-2">
            <H6 className="error-text-not text-left ">Not Added</H6>
          </div>
        )}
    </Fragment>
  );
}
const Trf = ({ trfDetails, isDownload, showTitle }) => {
  return (
    <div>
      <div className={isDownload ? 'pl5-sm pr5-sm pt5-sm pb2-sm' : 'p2-sm'}>
        {showTitle && (<H4 className="text-color-accent u-bold page-title-has-back sub-heading">TRF INFORMATION</H4>)}
      </div>
      <div className={isDownload ? 'pl5-sm pr5-sm pb5-sm' : 'p2-sm'}>
        <div>
          <div className="ncss-col-sm-1 small-text description u-bold p2-sm">Name:</div>
          <div className="ncss-col-sm-3  u-bold " style={{whiteSpace:"nowrap"}}>
            {typeof trfDetails.name !== 'undefined' ? (
              <H6 className="ncss-col-sm-5 small-text in-line pl0-sm">{trfDetails.name}</H6>
            ) : (
                <H6 className="error-text-not small-text text-left ">Not Added</H6>
              )}
          </div>
          <div className="ncss-col-sm-1 small-text description  u-bold p2-sm">E-mail:</div>
          <div className="ncss-col-sm-4 small-text  u-bold ">
            {typeof trfDetails.userEmail !== 'undefined' ? (
              <H6 className="ncss-col-sm-5 small-text pl0-sm">{trfDetails.userEmail}</H6>
            ) : (
                <H6 className="error-text-not text-left ">Not Added</H6>
              )}
          </div>
          <div className="ncss-col-sm-1 small-text description  u-bold p2-sm">Contact:</div>
          <div className="ncss-col-sm-2 small-text u-bold ">
            {trfDetails.userContactNumber ? (
              <H6 className="ncss-col-sm-8 pl0-sm">{trfDetails.userContactNumber}</H6>
            ) : (
                <H6 className="error-text-not text-left ">Not Added</H6>
              )}
          </div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">RSL Number</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.RSLNo !== 'undefined' ? (
              <H6 className="ncss-col-sm-3 pl0-sm">{trfDetails.RSLNo}</H6>
            ) : (
                <H6 className="error-text-not text-left ">Not Added</H6>
              )}
          </div>
        </div>
        <BrandAndProductTypeComponent trfDetails={trfDetails} />
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm ">Supplier Name</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.supplierName !== 'undefined' ? (
              <H6 className="ncss-col-sm-12 pl0-sm ">{trfDetails.supplierName}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div>
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm ">Supplier Location</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.supLoc !== 'undefined' ? (
              <H6 className="ncss-col-sm-12 pl0-sm ">{trfDetails.supLoc}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div>
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm vertical-top">Material Name</div>
          <div className="ncss-col-sm-9  u-bold mt-10">
            {typeof trfDetails.materialName !== 'undefined' ? (
              <H6 className="ncss-col-sm-12 pl0-sm ">{trfDetails.materialName}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div>
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">Material Number</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.materialNumber !== 'undefined' ? (
              <H6 className="ncss-col-sm-12 pl0-sm">{trfDetails.materialNumber}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div>
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">PDM Number</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.PDMNumber !== 'undefined' && trfDetails.PDMNumber !== null ? (
              <H6 className="ncss-col-sm-12 pl0-sm">{trfDetails.PDMNumber}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div>
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">Material ID</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.refId !== 'undefined' && trfDetails.refId !== null ? (
              <H6 className="ncss-col-sm-12 pl0-sm">{trfDetails.refId}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div>
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">Sample Type</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.sampleType !== 'undefined' && trfDetails.sampleType !== null ? (
              <H6 className="ncss-col-sm-12 pl0-sm">{trfSampleTypeName(trfDetails.sampleType)}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div>
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">Color Code</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.colorCode !== 'undefined' && trfDetails.colorCode !== null && trfDetails.colorCode !== '' ? (
              <H6 className="ncss-col-sm-12 pl0-sm">{trfDetails.colorCode}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div>
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">Color Name</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.colorName !== 'undefined' && trfDetails.colorName !== null && trfDetails.colorName !== '' ? (
              <H6 className="ncss-col-sm-12 pl0-sm">{trfDetails.colorName}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div>
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">Color Way</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.colorWay !== 'undefined' && trfDetails.colorWay !== null && trfDetails.colorWay !== '' ? (
              <H6 className="ncss-col-sm-12 pl0-sm">{trfDetails.colorWay}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div>
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">Age Group</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.ageGroup !== 'undefined' && trfDetails.ageGroup !== null && trfDetails.ageGroup !== '' ? (
              <H6 className="ncss-col-sm-12 pl0-sm">{trfAgeGroupName(trfDetails.ageGroup)}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div>
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">Sample Color</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.sampleColor !== 'undefined' && trfDetails.sampleColor !== null && trfDetails.sampleColor !== '' ? (
              <H6 className="ncss-col-sm-12 pl0-sm">{trfDetails.sampleColor !== '' ? strUpperCase(trfDetails.sampleColor) : ''}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div>
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm vertical-top">Sample Description</div>
          <div className="ncss-col-sm-9  u-bold mt-10">
            {typeof trfDetails.description !== 'undefined' && trfDetails.description !== null && trfDetails.description !== '' ? (
              <H6 className="ncss-col-sm-12 pl0-sm">{trfDetails.description}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div>
        {trfDetails && typeof trfDetails !== 'undefined' && typeof trfDetails.certification !== 'undefined' && trfDetails.certification.length > 0 ?
       displayCertification(trfDetails) : ''}
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">Default Test</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.testLogic !== 'undefined' ? (
              <H6 className="ncss-col-sm-8 pl0-sm">
                {trfDetails.testLogic}
              </H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div>
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">Testing Type</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.testingType !== 'undefined' ? (
              <H6 className="ncss-col-sm-8 pl0-sm">
                {trfDetails.testingType}
              </H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div>
        <div className="ncss-col-sm-3 description u-bold p2-sm">Season</div>
        <div className="ncss-col-sm-9  u-bold ">
          {typeof trfDetails.seasonId !== 'undefined' ? (
            <H6 className="ncss-col-sm-3 u-bold  pl0-sm ">
              {find(Season, ['id', trfDetails.seasonId]).label}{' '}
            </H6>
          ) : (
              <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
            )}
        </div>
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">Year</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.year !== 'undefined' ? (
              <H6 className="ncss-col-sm-3 pl0-sm">{trfDetails.year}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div>

        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">Does material have repellent finish?</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.repellent !== 'undefined' ? (
              <H6 className="ncss-col-sm-3 pl0-sm">{trfDetails.repellent ? 'Yes' : 'No'}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div>
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">Is the material intended to come into contact with food or mouth?</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.foodOrMouth !== 'undefined' ? (
              <H6 className="ncss-col-sm-3 pl0-sm">{trfDetails.foodOrMouth ? 'Yes' : 'No'}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div>
        { (trfDetails.product === 204 ||   trfDetails.product === 206) ? 
        (<div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">Will this electonic material be embedded into a finished product (e.g. Footwear)?</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.isElectEmbedded !== 'undefined' &&  trfDetails.isElectEmbedded !== null  ? (
              <H6 className="ncss-col-sm-3 pl0-sm">{trfDetails.isElectEmbedded ? 'Yes' : 'No'}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Available</H6>
              )}
          </div>
         <div className="ncss-col-sm-3 description u-bold p2-sm">
          Is this electronic material intended to come in contact with user's skin?</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.skinContact !== 'undefined' &&  trfDetails.skinContact !== null  ? (
              <H6 className="ncss-col-sm-3 pl0-sm">{trfDetails.skinContact ? 'Yes' : 'No'}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Available</H6>
              )}
          </div> 
        </div>) : '' }
        {trfDetails.product === 202 ? 
        (<div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">Is this material intended for eye-wear frames?</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.eyeWear !== 'undefined' &&  trfDetails.eyeWear !== null  ? (
              <H6 className="ncss-col-sm-3 pl0-sm">{trfDetails.eyeWear ? 'Yes' : 'No'}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Available</H6>
              )}
          </div>
        </div>) : '' }
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">Enable TP2</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.enableTP2 !== 'undefined' ? (
              <H6 className="ncss-col-sm-3 pl0-sm">{trfDetails.enableTP2 === true ? 'Yes' : 'No'}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div>
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">Does this material sample contain recycled content?</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.recycle !== 'undefined' ? (
              <H6 className="ncss-col-sm-3 pl0-sm">{trfDetails.recycle === true ? 'Yes' : 'No'}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div>
        {trfDetails.recycle === true ? (
          <Fragment>
          <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">
            List the samples recycled material source (e.g. post industrial, post consumer, pre consumer, etc.)</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.listRecycle !== 'undefined' ? (
              <H6 className="ncss-col-sm-3 pl0-sm">{trfDetails.listRecycle}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div>
        <div>
        <div className="ncss-col-sm-3 description u-bold p2-sm">
          Describe the recycled content in the material sample (e.g. 20% recycled PET, 10% recycled TPU, etc.)</div>
        <div className="ncss-col-sm-9  u-bold ">
          {typeof trfDetails.describeRecycle !== 'undefined' ? (
            <H6 className="ncss-col-sm-3 pl0-sm">{trfDetails.describeRecycle}</H6>
          ) : (
              <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
            )}
        </div>
      </div>
      </Fragment>
        ) : ''}

        {/* <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm"> Does this material contain recycled content?</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.recycle !== 'undefined' ? (
              <H6 className="ncss-col-sm-3 pl0-sm">{trfDetails.recycle && trfDetails.recycle !== null ? trfDetails.recycle : 'No'}</H6>
            ) : (
                <H6 className="error-text-not text-left ncss-col-sm-3">Not Added</H6>
              )}
          </div>
        </div> */}
        {/* <span className="html2pdf__page-break" /> */}
        <MaterialTypeComponent trfDetails={trfDetails} />
        <TestPackageComponent trfDetails={trfDetails} />
        <div className="ncss-col-sm-3 description u-bold p2-sm">Lab</div>
        {typeof trfDetails.materialType !== 'undefined' ? (
          <div className="ncss-col-sm-9  u-bold">
            {filter(location, { location: [{ id: trfDetails.labLocationId }] }).length >= 1 ? (
              <H6 className="ncss-col-sm-5 pl0-sm ">
                {filter(location, { location: [{ id: trfDetails.labLocationId }] })[0].lab}
              </H6>
            ) : (
                <H6 className="ncss-col-sm-5 pl0-sm ">No Location Added</H6>
              )}
          </div>
        ) : (
            <div className="ncss-col-sm-2">
              <H6 className="error-text-not text-left ">Not Added</H6>
            </div>
          )}
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">Location</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.labLocationId !== 'undefined' ? (
              <H6 className="ncss-col-sm-3 pl0-sm">
                {find(flattenItems(location, 'location'), ['id', trfDetails.labLocationId]).area} {' - '}
                {find(flattenItems(location, 'location'), ['id', trfDetails.labLocationId]).country}
              </H6>
            ) : (
                <H6 className="error-text-not text-left ">Not Added</H6>
              )}
          </div>
        </div>
        <div>
          <div className="ncss-col-sm-3 description u-bold p2-sm">Service</div>
          <div className="ncss-col-sm-9  u-bold ">
            {typeof trfDetails.service !== 'undefined' ? (
              <H6 className="ncss-col-sm-3 pl0-sm">{trfDetails.service}</H6>
            ) : (
                <H6 className="error-text-not text-left ">Not Added</H6>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

Trf.propTypes = {
  trfDetails: PropTypes.object.isRequired,
  isDownload: PropTypes.bool,
  showTitle: PropTypes.bool
};
export default Trf;