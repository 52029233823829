import React, { Component } from 'react';
import { compose } from 'redux';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import { H5, TextInput, Button, Container, ConfirmationBox } from '../../components/index.js';
import Grid from '../../components/grid/Grid.js';
import listUsersGridConfig, { autoGroupColumnDefForAdd } from '../../configs/gridConfigs/licencee/listUsersGridConfig.js';
import { toggleAddMaterailModal } from '../../actions/trfActions.js';
import { clearEditLocationList } from '../../actions/adminUserActions.js';
import { createServiceAction } from '../../util/actionHelper.js';
import { history } from '../../util/index.js';
import { getLicenseeList } from '../../reducers/licenseeReducer.js';
import ListLicensees from './listLicensees/listLicensees.js';


class AddLicensee extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submitModal: false,
            selectedNodes: [],
            selectedNodesOnDefault: [],
            activeNewLicenseeAdd: true,
            activelistLicensee: false,
            statusChanged: false,
            supName: null,
            initialStateSave: true
        };

        this.confirmationBoxOpen = this.confirmationBoxOpen.bind(this);
        this.setLicensee = this.setLicensee.bind(this);
        this.setGridParams = this.setGridParams.bind(this);
        this.cancelAddLicensee = this.cancelAddLicensee.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);

    }
    componentDidMount() {
        const { clearEditLocationList } = this.props;
        clearEditLocationList();

    }

    componentDidUpdate(prevProps, prevState) {
        const { licenseeListFetch, licenceeList } = this.props;
        const { selectedNodes, supName, statusChanged } = this.state;

        if ((isEmpty(selectedNodes) && isEqual(selectedNodes, prevState.selectedNodes)) || (prevState.statusChanged !== statusChanged && statusChanged)) {
            this.params && this.params.api.forEachNode(function (node) {
                if (node.data && node.data.isLicensee) {
                    node.setSelected(true);
                }


            });

        }
        if (prevState.supName && supName && statusChanged && prevState.statusChanged !== statusChanged) {
            this.setState({ statusChanged: false })
            licenseeListFetch(supName);

        }
        if (!statusChanged && prevProps.licenceeList !== licenceeList) {

            this.params && this.params.api.forEachNode(function (node) {
                if (node.data && node.data.isLicensee) {
                    node.setSelected(true);
                }


            });

            if (this.params && this.params.api.getSelectedRows()) {
                this.setState({ selectedNodesOnDefault: this.params.api.getSelectedRows(), selectedNodes: this.params.api.getSelectedRows() })
            }
        }
        // if (!isEmpty(selectedNodes) && initialStateSave) {
        //     this.setState({ selectedNodesOnDefault: selectedNodes, initialStateSave: false })
        // }

    }

    newLicenseeAdd = status => this.setState({ activeNewLicenseeAdd: status, activelistLicensee: false, statusChanged: status });
    
    listLicenseeButton = status => this.setState({ activeNewLicenseeAdd: false, activelistLicensee: status, statusChanged: false });

    confirmationBoxOpen = () => this.setState({ submitModal: true });

    closeSubmitModal = () => this.setState({ submitModal: false });


    onSelectionChanged = params => {
        const rowData = [];
        params.api.forEachNode(node => rowData.push(node.data));
        const previousSelected = rowData.filter(item => item && item.isLicensee)
        let removedLicensees = (previousSelected).filter(x => !(params.api.getSelectedRows()).includes(x))
        let addedLicensees = (params.api.getSelectedRows()).filter(x => !(previousSelected).includes(x))

        let removedItems = removedLicensees.map(item => ({ userId: item.userId, supName: item.supplierName, isActive: false }))
        let addedItems = addedLicensees.map(item => ({ userId: item.userId, supName: item.supplierName, isActive: true }))

        if (JSON.stringify(rowData.filter(item => item && item.isLicensee)) === JSON.stringify(params.api.getSelectedRows())) {
            this.setState({ selectedNodes: removedItems.concat(rowData.filter(item => item && item.isLicensee)) })
        } else {
            this.setState({ selectedNodes: removedItems.concat(addedItems) })
        }

    }

    setLicensee = selectedNodes => {
        const { setLicenseeInfo } = this.props;
        const { supName } = this.state;
        setLicenseeInfo({ users: selectedNodes, listParams: { searchSup: supName, isAll: true } })

    }

    licenseeListRequest = supName => {
        const { requestLicenseeList } = this.props;
        //const { supName } = this.state;
        this.setState({ supName })
        requestLicenseeList(supName);

    }

    cancelAddLicensee = () => {

        history.push('/');
    };

    setGridParams = params => {
        this.params = params;

        params && params.api.forEachNode(function (node) {
            if (node.data && node.data.isLicensee) {
                node.setSelected(true);
            }
        });
        if (params) {
            this.setState({ selectedNodesOnDefault: params.api.getSelectedRows() })
        }

    }

    render() {

        const { licenceeList, addLicenseeForm } = this.props;
        const { activeNewLicenseeAdd, activelistLicensee, submitModal, selectedNodes, supName, selectedNodesOnDefault } = this.state;

        return (
            <div >
                <Container section className="ml1-sm mr1-sm mt2-sm tab-class-container tab-class">
                    <div className="ncss-col-sm-12 ncss-input-container">
                        <div className="ncss-col-sm-10 align-left">

                            <div className="pb2-sm" >
                                <div className="ncss-col-sm-12 pb3-sm supplier_class">
                                    <div className="ncss-col-sm-6 p2-sm">
                                        <Button className={`supplier-add-new-button add-supplier-text u-bold ${activeNewLicenseeAdd ? 'active-supplier-button ' : 'not-active-supplier-button'}`}
                                            onClick={() => this.newLicenseeAdd(true)}>
                                            <i className="fas fa-address-card"></i> Add Licensees
                                    </Button>
                                        <span className="span_addsupplier"> | </span>

                                        <Button className={`supplier-add-new-button add-supplier-text u-bold ${activelistLicensee ? 'active-supplier-button ' : 'not-active-supplier-button'} `}
                                            onClick={() => this.listLicenseeButton(true)}>
                                            <i className="fas fa-user-edit"></i>  Show Licensees
                                    </Button>
                                    </div>
                                </div>
                                {activeNewLicenseeAdd ?
                                    <span>
                                        <span>
                                            <div className="ncss-col-sm-2 inline-flex-display">
                                                <H5 className="u-bold description p2-top-sm p2-bottom-sm add-supplier-text">Licensee Name<span className="mandatory_supplier"></span></H5>
                                            </div>
                                            <div className="ncss-col-sm-4 ncss-input-container inline-flex-display no-padding-supplier">
                                                <Field component={TextInput} name="supplierName" type="text" containerClass="u-full-width" />
                                            </div>
                                            <div className="ncss-col-sm-2 ncss-input-container inline-flex-display">
                                                {addLicenseeForm && addLicenseeForm.supplierName && addLicenseeForm.supplierName.length >= 3 ?
                                                    <Button className="ncss-btn-grey u-bold add-supplier-search add-supplier-search-color ncss-input pt2-sm pr4-sm pb2-sm pl4-sm"
                                                        onClick={
                                                            () => this.licenseeListRequest(addLicenseeForm && addLicenseeForm.supplierName)
                                                        }>
                                                        <i className="fas fa-search" />
                                                        Search
                                                </Button>
                                                    :
                                                    <Button className="ncss-btn-grey u-bold add-supplier-search  pt2-sm pr4-sm pb2-sm pl4-sm disabled " disabled>
                                                        <i className="fas fa-search" />
                                                     Search
                                                </Button>
                                                }
                                            </div>
                                        </span>


                                        {licenceeList && supName ?
                                            <div className="pt2-sm ncss-col-sm-offset-2 ncss-col-sm-11 pl0-sm">

                                                <Grid rowData={[...new Set(licenceeList)]}
                                                    rowSelection='multiple'
                                                    groupSelectsChildren={true}
                                                    onSelectionChanged={this.onSelectionChanged}
                                                    setParams={this.setGridParams}
                                                    columnDefs={listUsersGridConfig}
                                                    autoGroupColumnDef={autoGroupColumnDefForAdd}
                                                />
                                            </div>
                                            :
                                            <div className="grid-height-adjust"></div>
                                        }


                                    </span>
                                    :
                                    <span>
                                        < ListLicensees />
                                    </span>
                                }
                            </div>
                            <div>

                            </div>
                        </div>

                    </div>
                </Container>
                <ConfirmationBox
                    message={
                        `Are you sure you want to update the licensee list?`

                    }
                    submitModal={submitModal}
                    submitValue={
                        () => {
                            this.setLicensee(selectedNodes);
                        }

                    }
                    closeModal={this.closeSubmitModal}
                />

                <div className="ncss-col-sm-12 ">

                    <div className="ncss-col-sm-12 align-center">
                        <Button className="ncss-btn-secondary-dark u-bold secondary-button add-supplier-location-buttton"
                            onClick={this.cancelAddLicensee}>
                            CANCEL
                      </Button>
                        {JSON.stringify(selectedNodes) !== JSON.stringify(selectedNodesOnDefault) && activeNewLicenseeAdd ?
                            <Button
                                className="ncss-btn-grey u-bold new-material update-exception-buttton"
                                onClick={() => this.confirmationBoxOpen()}
                            >
                                UPDATE LICENSEES
                            </Button>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

AddLicensee.propTypes = {
    toggleAddMaterailModal: PropTypes.func,
    isAddMaterialModalOpen: PropTypes.bool,
    requestSupplierList: PropTypes.func,
    history: PropTypes.object
};
const mapStateToProps = state => ({
    allData: state,
    isAddMaterialModalOpen: state.trf.isAddMaterialModalOpen,
    supplierData: state.adminUser.supplierData,
    licenseeData: state.licensee.licenseeDetails,
    supplierLocationList: state.adminUser.supplierLocationList,
    locationDetails: state.adminUser.locationDetails,
    addLicenseeForm: getFormValues('addLicensee')(state),
    errorStatusAddLicensee: state.adminUser.errorStatusAddLicensee,
    errorStatusUpdateSupplier: state.adminUser.errorStatusUpdateSupplier,
    licenceeList: getLicenseeList(state)
});

const mapDispatchToProps = dispatch => ({
    toggleAddMaterailModal: isOpen => {
        dispatch(toggleAddMaterailModal(isOpen));
    },

    clearEditLocationList: () => {
        dispatch(clearEditLocationList());
    },
    submitSupplierData: (data) => {
        dispatch(createServiceAction('addLicensee', 'submited')({ ...data }));
    },

    setLicenseeInfo: (data) => {
        dispatch(createServiceAction('setLicensee', 'requested')({ ...data }));
    },

    requestLicenseeList: searchTerm => {
        dispatch(createServiceAction('listLicensee', 'requested')({ searchSup: searchTerm, isAll: true }));
    },

    licenseeListFetch: searchTerm => {
        dispatch(createServiceAction("listLicensee", 'requested')({ searchSup: searchTerm, isAll: true }))
    }

});

export default compose(reduxForm({ form: 'addLicensee' }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(AddLicensee);
