import moment from 'moment';

function EditComponent(params) {
    return (
        <div class="test_button_proceed"><i class="fas fa-edit"></i> Edit Notification </div>
    );
}
function DeleteComponent(params) {
    return (
        <div class="test_button_proceed"><i class="fas fa-trash"></i> Delete Notification </div>
    );
}
const config = [
    {
        colId: 'startDate',
        headerName: 'Start Date',
        field: 'startDate',
        suppressMovable: true,
        suppressHeaderMenuButton: true,
        width: 100,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        },
        cellRenderer: data => {
            return moment(data.value).utc().format('DD-MMM-YYYY')
          }
    },
    {
        colId: 'endDate',
        headerName: 'End Date',
        field: 'endDate',
        suppressMovable: true,
        suppressHeaderMenuButton: true,
        width: 100,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        },
        cellRenderer: data => {
            return moment(data.value).utc().format('DD-MMM-YYYY');
          }
    },
    {
        colId: 'message',
        headerName: 'Notifications',
        field: 'message',
        suppressMovable: true,
        suppressHeaderMenuButton: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        }
    },
    {
        colId: 'userGroups',
        headerName: 'User Groups',
        field: 'userGroups',
        suppressMovable: true,
        suppressHeaderMenuButton: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        }
    },
    {
        headerName: " ",
        field: "editNotify",
        colId: "editNotify",
        suppressToolPanel: true,
        suppressSizeToFit: true,
        width: 180,
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        cellRenderer: params => {

            return <EditComponent/>;

        }
    },
    {
        headerName: " ",
        field: "deleteNotify",
        colId: "deleteNotify",
        suppressToolPanel: true,
        suppressSizeToFit: true,
        width: 180,
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        cellRenderer: params => {

            return <DeleteComponent/>;

        }
    }
];
export default config;
