import React from 'react';

function ActionComponent(params) {
const { node: { data } } = params;
  const activeStatus = data.isActive ? 'checked' : '';
  const [checked, setSwitchChecked] = React.useState(activeStatus);

  React.useEffect(() => {
    setSwitchChecked(activeStatus);
  }, [activeStatus]);

  const handleChange = () => {
    setSwitchChecked(!checked);
  };
  return (
    <label class="switch">
        <input type="checkbox" onChange={handleChange} checked={checked}/>
        <span class="slider round"></span>
      </label>
  );
}
const config = [
  {
    colId: 'materialNumber',
    headerName: 'Material Number',
    field: 'materialNumber',
    suppressHeaderMenuButton: true,
    minWidth:50,
    suppressMovable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    floatingFilter: true,
  },
  {
    colId: 'materialName',
    headerName: 'Material Name',
    field: 'materialName',
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    minWidth:50,
    suppressHeaderMenuButton: true,
    tooltipField: 'materialName',
    suppressMovable: true,
    cellStyle: { color: '#3498db' },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    floatingFilter: true,
  },
  {
    colId: 'materialDesc',
    headerName: 'Material Description',
    field: 'materialDesc',
    minWidth:50,
    suppressHeaderMenuButton: true,
    tooltipField: 'materialDesc',
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressMovable: true,
    cellStyle: { color: '#3498db' },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    floatingFilter: true,
  },
  {
    colId: 'styleNumber',
    headerName: 'Style Number',
    field: 'styleNumber',
    suppressHeaderMenuButton: true,
    minWidth:50,
    suppressMovable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    floatingFilter: true,
  },
  {
    headerName: "Active/De-Activate",
    field: "IsActive",
    colId: "activeStatus",
    suppressSizeToFit: true,
    minWidth:150,
    suppressToolPanel: true,
    toolPanelClass: "ag-hide-toolpanel",
    suppressHeaderMenuButton: true,
    tooltipField: 'IsActive',
    suppressMovable: true,
    cellRenderer: params => {
      return <ActionComponent {... params}/>
    }
},
];
export default config;
