import React, { useCallback, useEffect, useRef, useState } from "react";
import "flatpickr/dist/themes/material_green.css";

import { useGridDate } from "ag-grid-react";
import flatpickr from 'flatpickr';

 const CustomDateFilter = ({ date, onDateChange }) => {
  const [picker, setPicker] = useState(null);
  const refFlatPickr = useRef(null);
  const refInput = useRef(null);

  useEffect(() => {
    setPicker(
      flatpickr(refFlatPickr.current, {
        onChange: (selectedDates) => onDateChange(selectedDates[0]),
        wrap: true,
      }),
    );
  }, [refFlatPickr, onDateChange]);

  useEffect(() => {
    if (picker && picker.calendarContainer) {
      picker.calendarContainer.classList.add("ag-custom-component-popup");
    }
  }, [picker]);
 
  useEffect(() => {
    if (picker) {
      picker.setDate(date);
    }
  }, [date, picker]);

  const setInputPlaceholder = useCallback((placeholder) => {
    if (refInput.current) {
      refInput.current.setAttribute("placeholder", placeholder);
    }
  }, []);

  const setInputAriaLabel = useCallback((label) => {
    if (refInput.current) {
      refInput.current.setAttribute("aria-label", label);
    }
  }, []);

  useGridDate({
    setInputPlaceholder,
    setInputAriaLabel,
  });

  return (
    <div
      className="ag-input-wrapper custom-date-filter"
      role="presentation"
      ref={refFlatPickr}
    >
      <input ref={refInput} data-input style={{ width: "100%" }} className="flat-picker-filter ag-filter-border" />
      <button type="button" className="input-button" title="clear" data-clear>
        <i className="fa fa-times"></i>
      </button>
    </div>    
  );
};
export default CustomDateFilter;
