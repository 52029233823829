import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import { H5, Button, IconButton } from '../index.js';

class ContentPopup extends Component {
  render() {
    const { title, content, closeModal, isOpen } = this.props;
    return (
      <Modal isOpen={isOpen} className="modal-container" overlayClassName="modal-overlay">
        <IconButton icon="times" className="modal-close-btn" onClick={() => closeModal()} />
        <div className="ncss-col-sm-12 ">
          <H5 className="modal-text p2-bottom-sm">{title && title}</H5>
          {content && content}
        </div>
        <div className="ncss-col-sm-12 ">
          <div className="bottom-section ncss-col-sm-3 p4-top-sm popup-bottom">
            <Button className="ncss-btn-grey u-bold no-button " onClick={() => closeModal()}>
              CANCEL
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

ContentPopup.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  title: PropTypes.string,
  //content: PropTypes.string
};

export default ContentPopup;
