import React, { Component } from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { Button, PageHeader, ProgressBar } from '../../components/index.js';
import AddSupplier from '../addSupplier/addSupplier.js';
import AddException from '../addException/addException.js';
import UserMismatchError from '../Generic/UserMismatchError.js'
import EditUser from '../EditUser/editUser.js';
import EditMaterial from '../editMaterial/editMaterial.js';
import AddLicensee from '../addLicensee/addLicensee.js';
import { isRslUser } from '../../reducers/authReducer.js';
import { getMaterialLoadStatus } from '../../reducers/materialListReducer.js';
import { getSupplierProgress } from '../../reducers/listAllSupplierReducer.js';


export class RslAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeEditSuplier: false,
            activeTP1TP2Exception: false,
            activeEditUser: true,
            activeAddLicensee: false,
            activeEditMaterial: false
        };
        this.AddTP1TP2Exception = this.AddTP1TP2Exception.bind(this);
        this.EditSuplier = this.EditSuplier.bind(this);
        this.EditUser = this.EditUser.bind(this);
        this.addLicensee = this.addLicensee.bind(this);
    }
    EditSuplier = (status) => {
        this.setState({ activeEditMaterial: false, activeEditSuplier: status, activeTP1TP2Exception: false, activeEditUser: false, activeAddLicensee: false })
    }

    AddTP1TP2Exception = (status) => {
        this.setState({ activeEditMaterial: false, activeEditSuplier: false, activeTP1TP2Exception: status, activeEditUser: false, activeAddLicensee: false });
    }

    EditUser = (status) => {
        this.setState({ activeEditMaterial: false, activeEditSuplier: false, activeTP1TP2Exception: false, activeEditUser: status, activeAddLicensee: false });
    }

    addLicensee = (status) => {
        this.setState({ activeEditMaterial: false, activeEditSuplier: false, activeTP1TP2Exception: false, activeEditUser: false, activeAddLicensee: status });
    }

    editMaterial = (status) => {
        this.setState({ activeEditMaterial: status, activeEditSuplier: false, activeAddSuplier: false, activeTP1TP2Exception: false, activeEditUser: false, activeAddLicensee: false});
    }

    render() {
        const { activeEditSuplier, activeTP1TP2Exception, activeEditUser, activeAddLicensee, activeEditMaterial } = this.state
        const { exceptionPercentage, editPercentage, isRslUser, editPercentMaterial,editSupplierProgress } = this.props
        
        return (
            <div>
                {isRslUser ?
                    <form className="page-align-items extra-bottom-margin">
                        <PageHeader title="RSL ADMIN">
                            <div className="user-page ie-icon-align">
                                <i className="fas fa-user-shield" />
                            </div>
                        </PageHeader>
                        <div className="ncss-col-sm-12 ncss-input-container tab-class">
                        {activeEditSuplier && !activeEditUser &&
                                editSupplierProgress > 0 ?
                                <div className="ncss-col-sm-12 progress_bar">
                                    <ProgressBar percent={editSupplierProgress}
                                        message={"Loading"} />
                                </div>
                                :
                                null
                            }

                            {!activeEditSuplier && activeEditUser &&
                                editPercentage > 0 ?
                                <div className="ncss-col-sm-12 progress_bar">
                                    <ProgressBar percent={editPercentage}
                                        message={"Loading"} />
                                </div>
                                :
                                null
                            }
                            {!activeEditSuplier && !activeEditUser && exceptionPercentage > 0 ?
                                <div className="ncss-col-sm-12 progress_bar">
                                    <ProgressBar percent={exceptionPercentage}
                                        message={"Loading"} />
                                </div>
                                :
                                null
                            }
                        {activeEditMaterial && editPercentMaterial > 0 ?
                                <div className="ncss-col-sm-12 progress_bar">
                                    <ProgressBar percent={editPercentMaterial}
                                        message={"Loading"} />
                                </div>
                                :
                                null
                            }
                            <div className="ncss-col-sm-10 align-left tab-class  tab-class-bottom">
                                <div className="pb3-sm tab-class-bottom" >
                                    <div className="ncss-col-sm-12 pb3-sm supplier_class-tab tab-class-bottom">
                                        <div className="ncss-col-sm-12 p2-sm tab-class tab-class-bottom">
                                            <Button className={`supplier-add-new-button add-supplier-text u-bold ${activeEditSuplier ? 'active-supplier-button ' : 'not-active-supplier-button'}`}
                                                onClick={() => this.EditSuplier(true)}>
                                                <i className="fas fa-user-tie"></i> EDIT SUPPLIER LOCATION
                                    </Button>
                                            <span className="span_addsupplier"> | </span>
                                            <Button className={`supplier-add-new-button add-supplier-text u-bold  ${activeTP1TP2Exception ? 'active-supplier-button ' : 'not-active-supplier-button'}`}
                                                onClick={() => this.AddTP1TP2Exception(true)}>
                                                <i className="fas fa-user-check"></i> TP1/TP2 EXCEPTIONS
                                    </Button>
                                            <span className="span_addsupplier"> | </span>
                                            <Button className={`supplier-add-new-button add-supplier-text u-bold  ${activeEditUser ? 'active-supplier-button ' : 'not-active-supplier-button'}`}
                                                onClick={() => this.EditUser(true)}>
                                                <i className="fas fa-user-edit"></i> EDIT USER PROFILE
                                    </Button>
                                            <span className="span_addsupplier"> | </span>
                                            <Button className={`supplier-add-new-button add-supplier-text u-bold  ${activeAddLicensee ? 'active-supplier-button ' : 'not-active-supplier-button'}`}
                                                onClick={() => this.addLicensee(true)}>
                                                <i className="fas fa-user-tag"></i> LICENSEE
                                    </Button>
                                            <span className="span_addsupplier"> | </span>
                                            <Button className={`supplier-add-new-button add-supplier-text u-bold ${activeEditMaterial ? 'active-supplier-button ' : 'not-active-supplier-button'}`}
                                                onClick={() => this.editMaterial(true)}>
                                                <i className="fas fa-address-card"></i> EDIT MATERIAL
                                    </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {activeEditSuplier ? <AddSupplier /> : activeEditUser ? <EditUser /> : activeTP1TP2Exception ? <AddException /> : activeEditMaterial ? <EditMaterial /> : <AddLicensee />}
                    </form>
                    :
                    <div> <UserMismatchError /></div>}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        exceptionPercentage: state.adminUser.getprogressCurrent,
        editPercentage: state.adminUser.getprogress,
        editPercentMaterial: getMaterialLoadStatus(state),
        editSupplierProgress: getSupplierProgress(state),
        isRslUser: isRslUser(state)
    }
};
const mapDispatchToProps = dispatch => ({});

export default compose(reduxForm({ form: 'rslAdmin' }),
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(RslAdmin);