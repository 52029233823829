import React from 'react';

function SwitchComponent(params) {
  const { node: { data } } = params;
  const activeStatus = data.isActive ? 'checked' : '';
  const [checked, setSwitchChecked] = React.useState(activeStatus);

  React.useEffect(() => {
    setSwitchChecked(activeStatus);
  }, [activeStatus]);

  const handleChange = () => {
    setSwitchChecked(!checked);
  };
  return (
    <label class="switch">
        <input type="checkbox" onChange={handleChange} checked={checked}/>
        <span class="slider round"></span>
      </label>
  );
}
function EditUserComponent(params) {
  return (
    <div class="test_button"><i class="fas fa-edit edit-user-button-adjust"></i> Edit </div>
  );
}
function EditSupplierComponent(params) {
  return (
    <div class="test_button"><i class="fas fa-edit edit-user-button-adjust"></i> Edit Supplier Location </div>
  );
}
const config = [
  {
    colId: 'tpException',
    headerName: 'Exception Status',
    field: 'tpException',
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    sortable: false,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    minWidth:170,
    maxWidth:190
  },
  {
    colId: 'name',
    headerName: 'Name',
    field: 'name',
    minWidth:150,
    filter: 'agTextColumnFilter',
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    floatingFilter: true
  },
  {
    colId: 'supplierId',
    headerName: 'Supplier Id',
    field: 'supplierId',
    minWidth:150,
    filter: 'agTextColumnFilter',
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    floatingFilter: true
  },
  {
    colId: 'supplierFacilityName',
    headerName: 'Facility Name',
    field: 'supplierName',
    minWidth:150,
    tooltipField: 'facilityName',
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },    
    floatingFilter: true,
   // hide:true,
  },
  {
    colId: 'companyType',
    headerName: 'Company Type',
    field: 'companyType',
    minWidth:150,
    filter: 'agTextColumnFilter',
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    floatingFilter: true
  },
  {
    colId: 'email',
    headerName: 'E-Mail',
    field: 'email',
    minWidth:150,
    tooltipField: 'email',
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    floatingFilter: true,
  },
  {
    colId: 'userType',
    headerName: 'User Type',
    field: 'userType',
    minWidth:100,
    tooltipField: 'email',
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    floatingFilter: true,
  },
  {
    colId: 'contact',
    headerName: 'Contact',
    field: 'contact',
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    floatingFilter: true,
   // hide:true
  },
  {
    colId: 'facilityName',
    headerName: 'Facility Name',
    field: 'facilityName',
    minWidth:150,
    tooltipField: 'facilityName',
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    floatingFilter: true,
  },
  {
    colId: 'city',
    headerName: 'City',
    field: 'city',
    minWidth:180,
    tooltipField: 'city',
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    floatingFilter: true
  },
  {
    colId: 'country',
    headerName: 'Country',
    field: 'country',
    minWidth:180,
    tooltipField: 'country',
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    floatingFilter: true
  },
  {
    colId: 'OSHId',
    headerName: 'OS ID',
    field: 'OSHId',
    minWidth:180,
    tooltipField: 'OSHId',
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    floatingFilter: true
  },
  {
    headerName: "Edit User",
    field: "editUser",
    colId: "editUser",
    suppressHeaderMenuButton: true,
    pinned: 'right',
    suppressSizeToFit: true,
    width: 150,
    suppressMovable: true,
    cellRenderer: params => {
      if((params.data.userType !== 'Rsl')&& (params.data.userType !== 'Nlo')){
      return <EditUserComponent {...params}/>
      }    
    },
  },
  {
    headerName: "Add/Edit Supplier Data",
    field: "editSupplier",
    colId: "editSupplier",
    suppressHeaderMenuButton: true,
    pinned: 'right',
    suppressSizeToFit: true,
    width: 180,
    suppressMovable: true,
    cellRenderer: params => {
      
      return  <EditSupplierComponent {...params}/>; 
         
    }
  },
  // {
  //   headerName: "",
  //   field: "addLocation",
  //   colId: "addLocation",
  //   suppressFilter: true,
  //   suppressHeaderMenuButton: true,
  //   pinned: 'right',
  //   suppressSizeToFit: true,
  //   width: 150,
  //   suppressMovable: true,
  //   cellRenderer: params => {
      
  //     return `<div class="test_button"><i class="fas fa-plus-circle edit-user-button-adjust"></i> Add Location </div>`; 
         
  //   }
  // },
  {
    headerName: "Active/De-Activate",
    field: "activeStatus",
    colId: "activeStatus",
    pinned: 'right',
    suppressSizeToFit: true,
    width: 150,
    suppressToolPanel: true,
    toolPanelClass: "ag-hide-toolpanel",
    suppressHeaderMenuButton: true,
    tooltipField: 'activeStatus',
    suppressMovable: true,
    cellRenderer: params => {
      return <SwitchComponent {...params}/>
    }
  },
  {
    headerName: "Active Status",
    field: "supplierActiveStatus",
    colId: "supplierActiveStatus",
    pinned: 'right',
    suppressSizeToFit: true,
    width: 150,
    suppressToolPanel: true,
    toolPanelClass: "ag-hide-toolpanel",
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    cellRenderer: params => {
      return <SwitchComponent {...params}/>
    },
    hide:true
  },
  {
    colId: 'tp2Exception',
    headerName: 'tp2Exception',
    field: 'tp2Exception',
    hide:true
  },
  {
    colId: 'id',
    headerName: 'UserId',
    field: 'id',
    hide:true
  }
];
export default config;
