import React from 'react';

function CustomCheckBoxComponent({data}) {
  const favExists = data?.favExists ?? false;
  const [checked, setChecked] = React.useState(favExists);

  React.useEffect(() => {
    setChecked(favExists);
  }, [favExists]);

  const handleChange = () => {
    setChecked(!checked);
    // Add any additional logic here if needed
  };
  return (
    <input
      type="checkbox"
      checked={checked}
      onChange={handleChange}
    />
  );
}
function SwitchComponent(params) {
  const { node: { data } } = params;
  const activeStatus = data.isActive ? 'checked' : '';
  const [checked, setSwitchChecked] = React.useState(activeStatus);

  React.useEffect(() => {
    setSwitchChecked(activeStatus);
  }, [activeStatus]);

  const handleChange = () => {
    setSwitchChecked(!checked);
  };
  return (
    <label class="switch">
        <input type="checkbox" onChange={handleChange} checked={checked}/>
        <span class="slider round"></span>
      </label>
  );
}
function NotesComponent(params) {
  return (
    <div class="grid_comments_button mt1-sm"><i class="fas fa-comments"></i> </div>
  );
}

const config = [
    {
        colId: 'trfId',
        headerName: 'TRF No.',
        field: 'trfId',
        suppressColumnsToolPanel: true,
        toolPanelClass: "ag-hide-toolpanel",
        pinned: 'left',
        suppressSizeToFit: true,
        width: 135,
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        floatingFilter: true,
        icons: {
          sortAscending: '<i class="fas fa-caret-up"></i>',
          sortDescending: '<i class="fas fa-caret-down"></i>'
        },
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ["contains", "startsWith", "endsWith"],
          defaultOption: "contains",
          caseSensitive: false,
          suppressAndOrCondition: true,
        }
    },
    {
      colId: 'supplierName',
      headerName: 'Supplier Name',
      field: 'supplierName',
      suppressSizeToFit: true,
      width: 270,
      resizable: false,
      tooltipField: 'supplierName',
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        defaultOption: 'contains',
        maxNumConditions: 1,
        caseSensitive: false
      },
      
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        },
      enableRowGroup: true,
      enableValue: true,
    },
    {
        colId: 'supLocName',
        headerName: 'Supplier Location',
        field: 'supLocName',
        suppressSizeToFit: true,
        width: 270,
        resizable: false,
        floatingFilter: true,
        tooltipField: 'supLocName',
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains'],
            defaultOption: 'contains',
            maxNumConditions: 1,
            caseSensitive: false
        },
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        },
       enableRowGroup: true,
       enableValue: true,
    },
    {
        colId: 'materialType',
        headerName: 'Material Type',
        field: 'materialType',
        floatingFilter: true,
        suppressSizeToFit: true,
        filter: 'agTextColumnFilter',
        width: 270,
        suppressHeaderMenuButton: true,
        suppressMovable: true,

        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        },
        enableRowGroup: true,
        enableValue: true
    },
    {
        colId: 'material',
        headerName: 'Material Name',
        field: 'material',
        tooltipField: 'material',
        suppressSizeToFit: true,
        width: 220,
        floatingFilter: true,
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
            filterOptions: ['contains'],
            defaultOption: 'contains',
            maxNumConditions: 1,
            caseSensitive: false
        },
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        },
        enableRowGroup: true,
        enableValue: true,
    },
    {
        colId: 'otherMaterial',
        headerName: 'Material Number',
        field: 'otherMaterial',
        suppressSizeToFit: true,
        width: 200,
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          defaultOption: 'contains',
          maxNumConditions: 1,
          caseSensitive: false
        },
        icons: {
          sortAscending: '<i class="fas fa-caret-up"></i>',
          sortDescending: '<i class="fas fa-caret-down"></i>'
        },
        enableRowGroup: true,
        enableValue: true,
      },
      {
        colId: 'materialNumber',
        headerName: 'PCX Number',
        field: 'materialNumber',
        suppressSizeToFit: true,
        width: 200,
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          defaultOption: 'contains',
          maxNumConditions: 1,
          caseSensitive: false
        },
        icons: {
          sortAscending: '<i class="fas fa-caret-up"></i>',
          sortDescending: '<i class="fas fa-caret-down"></i>'
        },
        enableRowGroup: true,
        enableValue: true,
      },
      {
        colId: 'refId',
        headerName: 'Material ID',
        field: 'refId',
        suppressSizeToFit: true,
        width: 200,
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          defaultOption: 'contains',
          maxNumConditions: 1,
          caseSensitive: false
        },
        icons: {
          sortAscending: '<i class="fas fa-caret-up"></i>',
          sortDescending: '<i class="fas fa-caret-down"></i>'
        },
        floatingFilter: true,
        enableRowGroup: true,
        enableValue: true,
      },
      {
        colId: 'PDMNumber',
        headerName: 'PDM Number',
        field: 'PDMNumber',
        suppressSizeToFit: true,
        width: 200,
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          defaultOption: 'contains',
          maxNumConditions: 1,
          caseSensitive: false
        },
        icons: {
          sortAscending: '<i class="fas fa-caret-up"></i>',
          sortDescending: '<i class="fas fa-caret-down"></i>'
        },
        enableRowGroup: true,
        enableValue: true
      },
    {
        colId: 'brand',
        headerName: 'Brand Tested For',
        field: 'brand',
        filter: 'agSetColumnFilter',
        suppressSizeToFit: true,
        width: 150,
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        floatingFilter: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        },
        enableRowGroup: true,
        enableValue: true
    },
    {
        colId: 'product',
        headerName: 'Product Type',
        field: 'product',
        filter: 'agSetColumnFilter',
        suppressSizeToFit: true,
        width: 150,
        floatingFilter: true,
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        },
        enableRowGroup: true,
        enableValue: true,
    },
    {
        colId: 'labLocation',
        headerName: 'Lab ',
        field: 'labLocation',
        suppressSizeToFit: true,
        width: 200,
        filter: 'agSetColumnFilter',
        //filterParams: { selectAllOnMiniFilter: true },

        floatingFilter: true,
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        },
        enableRowGroup: true,
        enableValue: true,
    },
    {
        colId: 'defaultTest',
        headerName: 'Default Test',
        field: 'defaultTest',
        suppressSizeToFit: true,
        width: 170,
        floatingFilter: true,
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            defaultOption: 'contains',
            caseSensitive: false
        },
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        },
        enableRowGroup: true,
        enableValue: true,
    },
    {
        colId: 'overAllStatus',
        headerName: 'Overall Test Result',
        field: 'overAllStatus',
        pinned: 'left',
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true,
        floatingFilter: true,
        width: 170,
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            defaultOption: 'contains',
            caseSensitive: false
        },
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        },
        cellStyle: params => {
            if (params.value && (params.value === 'Pass')) {
                return { color: '#87e300' };
            }
            else if (params.value && (params.value === 'Fail' || params.value === 'Kid Fail')) {
                return { color: '#ea723f' };
            }
            return { color: '#3498db' };
        }
    },
    {
      colId: 'testingType',
      headerName: 'Testing Type',
      field: 'testingType',
      tooltipField: 'testingType',
      width: 200,
      resizable: false,
      suppressSizeToFit: true,
      floatingFilter: true,
      filter: 'agSetColumnFilter',
      suppressHeaderMenuButton: true,
      suppressMovable: true,
      icons: {
          sortAscending: '<i class="fas fa-caret-up"></i>',
          sortDescending: '<i class="fas fa-caret-down"></i>'
      }
  },
    {
        colId: 'labRecDate',
        headerName: 'Test Report Date',
        field: 'labRecDate',
        tooltipField: 'labRecDate',
        width: 200,
        resizable: false,
        suppressSizeToFit: true,
        floatingFilter: true,
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        },
        filter: 'agSetColumnFilter',
        enableRowGroup: true,
        enableValue: true,
    },
    {
        colId: 'statusValue',
        headerName: 'Status',
        field: 'statusValue',
        pinned: 'right',
        suppressColumnsToolPanel: true,
        toolPanelClass: "ag-hide-toolpanel",
        tooltipField: 'action',
        width: 160,
        resizable: false,
        suppressSizeToFit: true,
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          defaultOption: 'contains',
          caseSensitive: false
        },
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        icons: {
          sortAscending: '<i class="fas fa-caret-up"></i>',
          sortDescending: '<i class="fas fa-caret-down"></i>'
        }
      },
    {
        colId: 'serviceRequested',
        headerName: 'Lab Service Requested',
        field: 'serviceRequested',
        suppressSizeToFit: true,
        width: 150,
        floatingFilter: true,
        suppressHeaderMenuButton: true,
        filter: 'agSetColumnFilter',
        suppressMovable: true,
        icons: {
            sortAscending: '<i class="fas fa-caret-up"></i>',
            sortDescending: '<i class="fas fa-caret-down"></i>'
        }
    },
    {
      colId: 'labRefNum',
      headerName: 'Lab Reference Number',
      field: 'labRefNum',
      tooltipField: 'labRefNum',
      suppressSizeToFit: true,
      width: 200,
      suppressHeaderMenuButton: true,
      resizable: false,
      suppressMovable: true,
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        defaultOption: 'contains',
        suppressAndOrCondition: true,
        caseSensitive: false
      },
      icons: {
        sortAscending: '<i class="fas fa-caret-up"></i>',
        sortDescending: '<i class="fas fa-caret-down"></i>'
      },
      hide:true,
      enableRowGroup: true,
      enableValue: true,
  },
    {
        // headerName: "Favorites",
        // field: "favExists",
        // colId: "favourites",
        // pinned: 'right',
        // suppressSizeToFit: true,
        // width: 100,
        // suppressToolPanel: true,
        // toolPanelClass: "ag-hide-toolpanel",
        // suppressFilter: true,
        // suppressHeaderMenuButton: true,
        // tooltipField: 'favourites',
        // suppressMovable: true,
        // cellRenderer: params => {
        //   return `<input type='checkbox' ${params.node.data.favExists ? 'checked' : ''} />`;
        // }
        headerName: "Favorites",
    field: "favExists",
    colId: "favourites",
    pinned: 'right',
    suppressColumnsToolPanel: true,
    suppressSizeToFit: true,
    width: 100,
    toolPanelClass: "ag-hide-toolpanel",
    suppressHeaderMenuButton: true,
    tooltipField: 'favourites',
    suppressMovable: true,
    cellRenderer: params => {
      return <CustomCheckBoxComponent {...params} />;
    },
    },
    {
        headerName: "Active/De-Activate",
        field: "isActive",
        colId: "activeStatus",
        pinned: 'right',
        suppressSizeToFit: true,
        width: 150,
        suppressToolPanel: true,
        toolPanelClass: "ag-hide-toolpanel",
        suppressHeaderMenuButton: true,
        tooltipField: 'activeStatus',
        suppressMovable: true,
        cellRenderer: params => {
        return <SwitchComponent {...params}/>
        }
    },
    {
        headerName: "Notes",
        field: "notes",
        colId: "notes",
        pinned: 'right',
        suppressToolPanel: true,
        suppressSizeToFit: true,
        width: 100,
        suppressHeaderMenuButton: true,
        tooltipField: 'notes',
        suppressMovable: true,
        cellRenderer: params => {
         
            return <NotesComponent {...params} />;
          
        }
      },
];
export default config;
