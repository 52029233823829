
import React from 'react';

import Tooltip from './Tooltip';
/* const InfoTooltip = ({ trigger, children }) => {
  return (

    <div data-testid="tooltip-component">

      {trigger && <Tooltip>{children}</Tooltip>}

      <i data-testid="info-icon" className="fa fa-info-circle text-color-accent fs18-sm" />

    </div>

  );

}; */
//UI Need to check
const InfoTooltip = ({trigger,  children, className, useBareClass, ...props }) => (
  <div data-testid="tooltip-component" className={`${useBareClass || 'fs18-sm d-sm-ib'} ${className || ''}`}>
    {trigger && <Tooltip {...props} content={children} placement="right">
    </Tooltip> }
    <i data-testid="info-icon"  className="fa fa-info-circle text-color-accent fs18-sm" />
  </div>
);



export default InfoTooltip;
