import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getFormValues } from 'redux-form';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import get from 'lodash/get';
import moment from 'moment';

import { H5, H6, Button, Collapsible } from '../../components/index.js';
//import Grid from '../../components/grid/InfiniteGrid.js';
import Grid from '../../components/grid-infinite/InfiniteGrid.js'
import columnDefs from '../../configs/gridConfigs/test-details/testDetailConfig.js';
import { createServiceAction } from '../../util/actionHelper.js';
import { selectUserId, isRslUser, isLabUser } from '../../reducers/authReducer.js';
import { reduxForm } from 'redux-form';
import TestDetailInfo from './testDetailInfo/TestDetailInfo';
import { Spinner } from '../../components/index.js';
import LabInputs from '../trf_review/labInputs/LabInputs.js';
import { PDFExport } from '@progress/kendo-react-pdf';
import TestDetailsPDF from "./testDetailsPdf.js"
import PageTemplate from "./pageTemplate.js";
import { getTestDetailData, getTestDetailsTotalCount, getTestDetailsByClass } from '../../reducers/testDetailReducer.js';
import TrfPrintable from '../trf_review/Trf/TrfPrintable.js';
import { formatDate } from '../../util/dateHelper.js';
import { convertToRSLNumber } from '../../util/index.js';
import { onChangeRetest } from '../../actions/trfActions';

const style = {
  fontWeight: '800',
  fontSize: '1.8rem',
  lineHheight: '1.5',
  color: '#0078B9'
};
const ExtendedPageTemplate = props => (
  <PageTemplate header ="Nike RSL Test Report" {...props} />
);
const TRFPageTemplate = props => {
  return <PageTemplate  header="NIKE TEST REQUEST FORM (TRF)" isTRFReport="true" {...props} />
};

class TestDetails extends Component {
  constructor(...props) {
    super(...props);
    this.state = {
      isPopoverOpen: false,
      paramsForExcel: {}
    };
  }

  componentDidMount() {
    const { getTrfDetails, location, requestTestDetails } = this.props;

    if (location.state && location.state.trfId) {
      getTrfDetails(location.state.trfId);
      const info = { trfId: location.state && location.state.trfId, pageCount: 10 }
      requestTestDetails(info);
      sessionStorage.setItem("reportId", location.state.rslId);
    }
  }

  setParams = params => {
    const { paramsForExcel } = this.state;
    if (paramsForExcel) {
      this.setState({ paramsForExcel: params });
    }
  };

  displayPopOver = () => {
    const { isPopoverOpen } = this.state;
    const togglePopover = !isPopoverOpen;
    this.setState({ isPopoverOpen: togglePopover });
  };

  exportData = (params, type) => {
    const { trfDetails } = this.props;
    const rslNO = trfDetails.data.trfDetails.RSLNo;
    const date = new Date().getDate();
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    const currDate = `${date}-${month}-${year} `;
    params.fileName = `${rslNO}_${currDate}`;
    params.sheetName = `${rslNO}_${currDate}`;
    if (type === 'xlsx') {
      params.api.exportDataAsExcel(params);
    } else if (type === 'csv') {
      params.api.exportDataAsCsv(params);
    }

  };

  displayButton = (status, qrfId, frfId,currentStatus,overAllStatus) => {
    //For old TRF's Added The Below Checks for QRF & FRF Pending status
    let renderText = '';
    if ((status === 'Pending FRF' || status === 'Rejected by Nike') && frfId!== null ) {
      renderText =  `EDIT FRF`
    } else if ((status === 'Pending QRF' || status === 'Rejected by Nike') && qrfId !== null && frfId === null) {
      renderText =`EDIT QRF`
    } 
    else if ((status === 'Pending QRF' || status === 'Rejected by Nike') && qrfId !== null && frfId!== null) {
      renderText =`EDIT QRF`
    }else if (status === 'Pending QRF' ){
      renderText =  `CREATE QRF`;
    }
    else if (status === 'Pending FRF' ){
      renderText = `CREATE FRF`;
    }
    else if (currentStatus === 'Completed' && (overAllStatus === 'Fail' || overAllStatus === 'Warning')){
      renderText = `RE-TEST TRF`;
    }
    else if (status === 'Retest Needed'){
      renderText = `RE-TEST TRF`;
    }
    return renderText;
  };

  formatDateTime = updatedDate => {
    let isIE = /*@cc_on!@*/false || !!document.documentMode;
    let isEdge = !isIE && !!window.StyleMedia;
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const formatStr = 'DD-MMM-YYYY HH:mm:ss';
    const month = { Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12 };
    let localDate;
    if (isEdge || isSafari) {
      const dateAndTime = updatedDate.split(' ');
      const dateUpdated = dateAndTime[0].split('/');
      const timeUpdated = dateAndTime[1].split(':');
      const monthInChar = dateUpdated[1]
      const fullDate = month[monthInChar] + "/" + dateUpdated[2] + "/" + dateUpdated[0] + " " + timeUpdated[0] + ":" + timeUpdated[1] + ":" + timeUpdated[2];
      localDate = moment.utc(fullDate).local().format(formatStr);
    }

    else {

      localDate = moment.utc(updatedDate).local().format(formatStr);
    }
    return localDate;
  }
  exportPDF = () => {
    this.testPdf.save();
  }
  exportTrfPDF = () =>{
    this.trfPdf.save();
  }

  groupBy = (arr, prop) => {
    const map = new Map(Array.from(arr, obj => [obj[prop], []]));
    arr.forEach(obj => map.get(obj[prop]).push(obj));
    return Array.from(map.values());
  }

  reorderTestData = (testDetailData) => {
    let testDetailsModified = []
    const sumExcludedClass =[597,586];
    if (testDetailData.data) {
      let groupedTests = this.groupBy(testDetailData.data, "class");
      groupedTests.forEach(groupArray => {
        let sumTest = groupArray.filter(ob => (ob.casNo.toUpperCase().includes('SUM') && !sumExcludedClass.includes(ob.class)));
        if (sumTest.length > 0) {
          let resTests = groupArray.filter(ob => !ob.casNo.toUpperCase().includes('SUM'));
          testDetailsModified = testDetailsModified.concat(Array.from(resTests.values()), Array.from(sumTest.values()));
        } else {
          testDetailsModified = testDetailsModified.concat(groupArray);
        }
      });
    }
    testDetailData.data = testDetailsModified;
    return testDetailData;
  }

  render() {
   

    const {
      history,
      location,
      trfDetails,
      testDetailsByClass,
      isRslUser,
      userID,
      isLabUser,
      isLoading,
      totalCount,
      updateRetest
    } = this.props;
    let { testDetailData } = this.props;
    let lvlPrev = -1;
    let commaNeed = null;
    let arrowNeed = null;
    let lvlPrevRT = -1;
    let commaNeedRT = null;
    let arrowNeedRT = null;
    if(trfDetails && trfDetails.data){
      sessionStorage.setItem("expiry", formatDate(trfDetails.data.trfDetails.expiry));
    }
    const { isPopoverOpen, paramsForExcel } = this.state;
    testDetailData = this.reorderTestData(testDetailData);
    if (isLoading || !trfDetails) return <Spinner large />;
    return (
      <div className="page-align-items">
        {!isLabUser && trfDetails ? (
          <div className="TestDetails">
            <div className="ncss-row vertical-align-baseline m0-bottom-sm">
              <div className={`${isRslUser ? 'ncss-col-sm-8' : 'ncss-col-sm-11'}`}>
                <H5 className="text-color-accent u-bold page-title-has-back test-detail-heading">
                  NIKE RSL REPORT {get(trfDetails, `data.trfDetails.RSLNo`, '')} -
                {get(trfDetails, `data.trfDetails.status`) === 'ActionRequired' ? (
                    <span className="color-rd"> ACTION REQUIRED</span>
                  ) : (
                      <span className="color-bl"> COMPLETED</span>
                    )}
                  <div className="icon big-button big-button-color">
                    {get(trfDetails, `data.trfDetails.status`) === 'ActionRequired' ? (
                      <i className="fas fa-exclamation fa-fw text-color-medium-grey" />
                    ) : (
                        <i className="fas fa-thumbs-up fa-fw text-color-medium-grey" />
                      )}
                  </div>
                </H5>
              </div>
              {trfDetails && (
                <div
                  className={
                    get(trfDetails, `data.trfDetails.userId`) === userID &&
                      (get(trfDetails, `data.trfDetails.statusValue`) === 'Pending QRF' ||
                      get(trfDetails, `data.trfDetails.statusValue`) === 'Retest Needed' ||
                        get(trfDetails, `data.trfDetails.statusValue`) === 'Pending FRF' || get(trfDetails, `data.trfDetails.statusValue`) === 'Rejected by Nike' ||
                        (get(trfDetails, `data.trfDetails.status`) === 'Completed' &&
                        (get(trfDetails, `data.trfDetails.overAllStatus`) === 'Fail' ||
                        get(trfDetails, `data.trfDetails.overAllStatus`) === 'Warning')))
                      ? 'ncss-col-sm-1 fl-sm-r button-top-adjust'
                      : 'hide-button'
                  }
                >
                  <Button
                    onClick={() => {
                      if ((get(trfDetails, `data.trfDetails.statusValue`) === 'Pending QRF' || get(trfDetails, `data.trfDetails.statusValue`) === 'Rejected by Nike') && trfDetails.data.frfDetails.frfId === null) {
                        history.push('/qrf', { trfId: location.state.trfId });
                      } else if ((get(trfDetails, `data.trfDetails.statusValue`) === 'Pending FRF' || get(trfDetails, `data.trfDetails.statusValue`) === 'Rejected by Nike') && trfDetails.data.frfDetails.qrfId !==null) {
                        history.push('/frf', { trfId: location.state.trfId });
                      } else if ((trfDetails.data.trfDetails.overAllStatus === 'Fail' || 
                      trfDetails.data.trfDetails.overAllStatus === 'Warning') &&
                      trfDetails.data.trfDetails.status === 'Completed') {
                        history.push('/trf',{ rslId: trfDetails.data.trfDetails.RSLNo , isRetest: 'Yes',trfId: location.state.trfId, isPartialEdit: true,preLoadMaterial:false});
                      }
                      else if (trfDetails.data.trfDetails.statusValue === 'Retest Needed') {
                        updateRetest('Yes')
                         history.push('/trf',{ rslId: trfDetails.data.trfDetails.RSLNo , isRetest: 'Yes',trfId: location.state.trfId,isPartialEdit: false,preLoadMaterial:false});
                        }
                    }}
                  >
                    {this.displayButton(
                      trfDetails.data.trfDetails.statusValue,
                      trfDetails.data.qrfDetails.qrfId,
                      trfDetails.data.frfDetails.frfId,
                      trfDetails.data.trfDetails.status,
                      trfDetails.data.trfDetails.overAllStatus

                    )}
                  </Button>
                </div>
              )}
              <div className="ncss-row detail">
                <H6 className="u-bold description test-inline">Last Update: </H6>
                <H6 className="u-bold test-inline">
                  {get(trfDetails, `data.trfDetails.updatedAt`) &&
                    this.formatDateTime(trfDetails.data.trfDetails.updatedAt)}
                </H6>
              </div>
              {trfDetails.data.trfDetails.linkage.originalTRFArr !== 'undefined' &&
                  trfDetails.data.trfDetails.linkage.originalTRFArr !== null &&
                  trfDetails.data.trfDetails.linkage.originalTRFArr.length > 0 ? (
               <div className="ncss-row detail">
                
                   <H6 className="u-bold description test-inline">
                    Link TRF :{" "}
                   </H6>
                    {trfDetails.data.trfDetails.linkage.originalTRFArr.map(
                      (data, index) => {
                        if(lvlPrev === -1){
                          lvlPrev = data.level;
                        }
                        if(data.level === lvlPrev && index !== 0){
                          commaNeed = true;
                          arrowNeed = false;
                        }
                        else if ((lvlPrev > -1) && data.level !== lvlPrev){
                          lvlPrev = data.level;
                          commaNeed = false;
                          arrowNeed = true;
                        }
                        return (
                        <H6 className="u-bold test-inline">
                          {arrowNeed ? "  >>  " : ""}
                          {commaNeed ? ", " : ""}
                        <Button
                          className="download-print-button text-color-accent"
                          onClick={() => {
                            if(data.overAllStatus === 'In Progress' && data.action === 'Review')
                            {
                              history.push('/trf', { trfId: data.id, isPartialEdit: false});

                            } else if(data.overAllStatus === 'In Progress' && data.action === 'Test pending with Lab') {
                              history.push('/trf', { trfId: data.id, isPartialEdit: true});
                            }
                            else {
                            history.push('/test-details', { trfId: data.id, rslId: convertToRSLNumber(data.id) });
                            history.go();
                            }
                          }}
                        >
                          {convertToRSLNumber(data.id)}
                        </Button>
                        </H6>
                      )}
                    )}  
                </div>
                ) : null}
                { trfDetails.data.trfDetails.linkage.retestTRFArr !== 'undefined' &&
                  trfDetails.data.trfDetails.linkage.retestTRFArr !== null &&
                  trfDetails.data.trfDetails.linkage.retestTRFArr.length > 0  ? (
                <div className="ncss-row detail">
                
                <H6 className="u-bold description test-inline">
                 Re-Test TRF :{" "}
                </H6>
                 { trfDetails.data.trfDetails.linkage.retestTRFArr.map(
                   (data, index) => {
                    if(lvlPrevRT === -1){
                      lvlPrevRT = data.level;
                    }
                    if(data.level === lvlPrevRT && index !== 0){
                      commaNeedRT = true;
                      arrowNeedRT = false;
                    }
                    else if ((lvlPrevRT > -1) && data.level !== lvlPrevRT){
                      lvlPrevRT = data.level;
                      commaNeedRT = false;
                      arrowNeedRT = true;
                    } 
                    return (
                     <H6 className="u-bold test-inline">
                       {arrowNeedRT ? "  >>  " : ""}
                       {commaNeedRT ? ", " : ""}
                     <Button

                       className="download-print-button text-color-accent"
                       onClick={() => {
                        if(data.overAllStatus === 'In Progress' && data.action === 'Review')
                            {
                              history.push('/trf', { trfId: data.id, isPartialEdit: false});

                            } else if(data.overAllStatus === 'In Progress' && data.action === 'Test pending with Lab') {
                              history.push('/trf', { trfId: data.id, isPartialEdit: true});
                            }
                            else {
                         history.push('/test-details', { trfId: data.id, rslId: convertToRSLNumber(data.id) });
                         history.go();
                            }
                       }}
                     >
                       {convertToRSLNumber(data.id)}
                     </Button>
                     </H6>
                   )}
                 )} 

             </div>
            ) : null} 
              {trfDetails && trfDetails.data.reason.length > 0 ? (
                <div className="ncss-row detail">
                  <H6 className="u-bold description test-inline">Non-Compliances: </H6>
                  {trfDetails.data.reason.map((label, index) =>
                    <H6 key={label} className={index === 0 ? 'u-bold test-inline' : 'u-bold test-padding'}>
                      {label}
                    </H6>
                  )}
                </div>) : null
              }
              <div className="ncss-row">
                <div className="ncss-col-sm-6">
                  {get(trfDetails, `data.trfDetails.status`) === 'ActionRequired' ? (
                    <div className="ncss-row detail">
                      <H6 className="u-bold description">Further Steps:
                    <span className="color-or"> Proceed to next stage of Failure Resolution Process </span>
                      </H6>
                    </div>
                  ) :
                    null}

                </div>
                <div className="ncss-col-sm-6 download-print-section">
                  <span className="mr5-sm">
                    {testDetailData && testDetailsByClass && testDetailData.data && testDetailData.totalCount && (testDetailData.data).length >= testDetailData.totalCount ?
                      <span>
                        <i className="fa fas fa-download" />
                           <Button onClick={this.exportTrfPDF} className="download-print-button text-color-accent mr5-sm">
                           Test Request Form (TRF)
                           </Button>
                         
                        <i className="fa fas fa-download" />
                        <Button onClick={this.exportPDF} className="download-print-button text-color-accent">
                          Nike RSL Test Report
                      </Button>
                      </span>
                      :
                      <span>
                        <span className="text-color-accent">Loading Reports</span>
                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

                      </span>
                    }
                  </span>
                  {testDetailData && testDetailsByClass && testDetailData.data && testDetailData.totalCount && (testDetailData.data).length >= testDetailData.totalCount ?
                    <Popover
                      isOpen={isPopoverOpen}
                      position="bottom"
                      padding={10}
                      onClickOutside={this.displayPopOver}
                      content={({ position, childRect, popoverRect }) => (
                        <ArrowContainer
                          position='bottom'
                          childRect={childRect}
                          popoverRect={popoverRect}
                          arrowColor="rgb(0, 120, 185)"
                          arrowSize={10}
                          arrowStyle={{ opacity: 1, left: '126px' }}
                        >
                          <div className="popover-section">
                            <Button
                              className="download-print-button button-hover"
                              onClick={() => {
                                this.exportData(paramsForExcel, 'csv');
                              }}
                            >
                              Download (.csv)
                        </Button>
                            <Button
                              className="download-print-button button-hover"
                              onClick={() => {
                                this.exportData(paramsForExcel, 'xlsx');
                              }}
                            >
                              Download (.xlsx)
                        </Button>
                          </div>
                        </ArrowContainer>
                      )}
                    >
                      <span>
                        <i className="fa fas fa-download" />
                        <Button className="download-print-button text-color-accent" onClick={this.displayPopOver}>
                          Raw Test Data
                    </Button>
                      </span>
                    </Popover>
                    :
                    null
                  }
                </div>

              </div>
            </div>

            {trfDetails && trfDetails.data.trfDetails.trfId ? (
              <div>
                <Collapsible
                  trigger={
                    <div className="ncss-col-sm-12 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0"> TRF INFORMATION</div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right" />
                      </div>
                    </div>
                  }
                  triggerWhenOpen={
                    <div className="ncss-col-sm-12 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0"> TRF INFORMATION</div>
                      <div className="ncss-col-sm-1">
                        <i className="fas fa-caret-up fa-pull-right" />
                      </div>
                    </div>
                  }
                  triggerStyle={style}
                  triggerClassName="collapsible"
                >
                  <TestDetailInfo from="trfDetails" />
                </Collapsible>
              </div>
            ) : null}
            <div>
              <Collapsible
                trigger={
                  <div className="ncss-col-sm-12 collapse-p0">
                    <div className="ncss-col-sm-11 collapse-p0">DETAILS OF TEST RESULT</div>
                    <div className="ncss-col-sm-1 ">
                      <i className="fas fa-caret-down fa-pull-right" />
                    </div>
                  </div>
                }
                triggerWhenOpen={
                  <div className="ncss-col-sm-12 collapse-p0">
                    <div className="ncss-col-sm-11 collapse-p0">DETAILS OF TEST RESULT</div>
                    <div className="ncss-col-sm-1">
                      <i className="fas fa-caret-up fa-pull-right" />
                    </div>
                  </div>
                }
                triggerStyle={style}
                triggerClassName="collapsible"
              >
                <div className="ncss-row">
                  <div className="ncss-col-sm-12">
                    <Grid
                      columnDefs={columnDefs}
                      rowData={get(testDetailData, `data`, [])}
                      totalCount={totalCount}
                      currentCount={parseInt(get(testDetailData, `currentPage`, 1)) * 10}
                      showFilter={false}
                      adjustHeight
                      headerHeight={90}
                      setParams={this.setParams}
                      infiniteScroll
                      rowModelType='infinite'
                      pagination={false}
                      maxConcurrentDatasourceRequests={1}
                      infiniteInitialRowCount={10}
                    />
                  </div>
                </div>
              </Collapsible>
            </div>
            <div>
              <Collapsible
                trigger={
                  <div className="ncss-col-sm-12 collapse-p0">
                    <div className="ncss-col-sm-11 collapse-p0">ADDITIONAL LAB INPUTS</div>
                    <div className="ncss-col-sm-1 ">
                      <i className="fas fa-caret-down fa-pull-right" />
                    </div>
                  </div>
                }
                triggerWhenOpen={
                  <div className="ncss-col-sm-12 collapse-p0">
                    <div className="ncss-col-sm-11 collapse-p0">ADDITIONAL LAB INPUTS</div>
                    <div className="ncss-col-sm-1">
                      <i className="fas fa-caret-up fa-pull-right" />
                    </div>
                  </div>
                }
                triggerStyle={style}
                triggerClassName="collapsible"
              >
                <LabInputs trfDetail={trfDetails}   isLabUser={isLabUser} isRslUser={isRslUser} />
              </Collapsible>
            </div>
            {trfDetails && trfDetails.data.qrfDetails.qrfId ? (
              <div>
                <Collapsible
                  trigger={
                    <div className="ncss-col-sm-12 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0"> QRF INFORMATION</div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right" />
                      </div>
                    </div>
                  }
                  triggerWhenOpen={
                    <div className="ncss-col-sm-12 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0"> QRF INFORMATION</div>
                      <div className="ncss-col-sm-1">
                        <i className="fas fa-caret-up fa-pull-right" />
                      </div>
                    </div>
                  }
                  triggerStyle={style}
                  triggerClassName="collapsible"
                >
                  <TestDetailInfo from="qrfDetails" />
                </Collapsible>
              </div>
            ) : null}
            {trfDetails && trfDetails.data.frfDetails.frfId ? (
              <div>
                <Collapsible
                  trigger={
                    <div className="ncss-col-sm-12 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0"> FRF INFORMATION</div>
                      <div className="ncss-col-sm-1 ">
                        <i className="fas fa-caret-down fa-pull-right" />
                      </div>
                    </div>
                  }
                  triggerWhenOpen={
                    <div className="ncss-col-sm-12 collapse-p0">
                      <div className="ncss-col-sm-11 collapse-p0"> FRF INFORMATION</div>
                      <div className="ncss-col-sm-1">
                        <i className="fas fa-caret-up fa-pull-right" />
                      </div>
                    </div>
                  }
                  triggerStyle={style}
                  triggerClassName="collapsible"
                >
                  <TestDetailInfo from="frfDetails" />
                </Collapsible>
              </div>
            ) : null}
            <div className="contact-section">
              <H5 className="description">Contact:</H5>
              <H6 className="description">For questions regarding Product contact <span className='text-color-accent'>RSLSupport@nike.com</span> and for Packaging <span className='text-color-accent'>PRSL.Support@nike.com</span></H6>
            </div>
          </div>
        ) : (
            <div className="pagenotFound">Error! Page you are looking for is not found</div>
          )}

        {trfDetails && testDetailData && testDetailData.data && testDetailsByClass && (testDetailData.data).length >= testDetailData.totalCount && <div className="testDetailsPDF">
          <span>

            <PDFExport paperSize="A4"
              pageTemplate={ExtendedPageTemplate}
              scale={0.8}
              margin={{ top: 50, left: 20, right: 20, bottom: 50 }}
              multiPage
              forcePageBreak=".page-break"
              fileName={`Nike RSLReport - R${trfDetails.data.trfDetails.trfId}.pdf`}
              repeatHeaders
              title=""
              subject=""
              keywords=""
              landscape="true"
              keepTogether=".prevent-split"
              ref={(r) => this.testPdf = r}>
              <div className="ncss-col-sm-12">
                <TestDetailsPDF trfDetails={trfDetails} isLabUser={isLabUser} isRslUser={isRslUser} testDetails={testDetailData} testDetailsByClass={testDetailsByClass} />
              </div>
            </PDFExport>
          </span>

          <span>

            <PDFExport paperSize="A4"
              pageTemplate={TRFPageTemplate}
              scale={0.8}
              margin={{ top: 50, left: 20, right: 20, bottom: 50 }}
              multiPage
              forcePageBreak=".page-break"
              fileName={`TRF Report - R${trfDetails.data.trfDetails.trfId}.pdf`}
              repeatHeaders
              title=""
              subject=""
              keywords=""
              landscape="true"
              keepTogether=".prevent-split"
              ref={(r) => this.trfPdf = r}>
              <div className="ncss-col-sm-12">
                <TrfPrintable trfDetails={trfDetails.data.trfDetails} showBilling={true}/>
              </div>
            </PDFExport>
          </span>
        </div>}
      </div>
    );
  }
}

TestDetails.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  getTrfDetails: PropTypes.func,
  trfDetails: PropTypes.object,
  requestTestDetails: PropTypes.func,
  testDetailData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  isRslUser: PropTypes.bool,
  userID: PropTypes.number,
  isLabUser: PropTypes.bool,
  testDetailStates: PropTypes.object,
  submitStatus: PropTypes.func,
  isLoading: PropTypes.bool,
  rslNO: PropTypes.string
};

const mapStateToProps = state => ({
  testDetailStates: getFormValues('testDetails')(state),
  trfDetails: state.trf.trfData,
  testDetailData: getTestDetailData(state),//state.testDetail.get('testDetailData'),
  testDetailsByClass: getTestDetailsByClass(state),
  totalCount: getTestDetailsTotalCount(state),
  isSuccess: state.testDetail.isSuccess,
  isRslUser: isRslUser(state),
  userID: selectUserId(state),
  isLabUser: isLabUser(state),
  isLoading: state.testDetail.isLoading
});

const mapDispatchToProps = dispatch => ({
  submitStatus: (data) => {
    dispatch(createServiceAction('testDetailsSubmit', 'submitted')({ ...data }));
  },
  getTrfDetails: data => {
    dispatch(createServiceAction('trfDetails', 'requested')(data));
  },
  requestTestDetails: data => {
    dispatch(createServiceAction('testDetails', 'requested')(data));
  },
  updateRetest: (data) => {
    dispatch(onChangeRetest(data));
  },
});

export default reduxForm({
  form: 'testDetails',
  destroyOnUnmount: false,
  enableReinitialize: true
})(connect(
  mapStateToProps,
  mapDispatchToProps)(TestDetails));