import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import union from 'lodash/union';
import filter from 'lodash/filter';
import productInfo from '../../../constants/json/checkboxes/productInfo.json';
import materialTypes from '../../../constants/json/radiobuttons/materialTypes.json';
import individualTest from '../../../constants/json/checkboxes/individualTestPackages.json';
import Season from '../../../constants/json/checkboxes/season.json';
import location from '../../../constants/json/radiobuttons/location.json';
import brandInfo from '../../../constants/json/checkboxes/brandInfo.json';
import { flattenItems, strUpperCase } from '../../../util/index.js';
import { styles } from '../../test_details/style.js';
import { formatDate, trfSampleTypeName, trfAgeGroupName, trfCertificationName } from '../../../util/index.js';
import moment from 'moment';
import { testPackageArry } from '../../test_details/testPackageTableLogic.js';
import { convertToRSLNumber } from '../../../util/index.js';

const createTestPackage = (trfDetails, testPacks, key, showBilling) => {
  const filteredtestPackages = Array.isArray(trfDetails.testPackages) ? trfDetails.testPackages.filter(ele => ele !== null) : []
  return (
    <Fragment>
      <tr className="ncss-col-sm-12 ml-n-10">
        <td className="ncss-col-sm-2 table-align u-bold">
          <span className='ml-8 mt-9'>{key}</span></td>
        {filteredtestPackages && filteredtestPackages.length > 0 ?
          (
            <td className="ncss-col-sm-10 padding-0 vertical-top ml-n-10">
              <tr className="ncss-col-sm-12">
                <td className="ncss-col-sm-10 padding-0">
                  <table className='tbl-cl'>
                    <thead>
                      <tr>
                        <th className='cs-n-tstpck'>
                          <div className="ncss-col-sm-3 u-bold th-cl grey-background-groupsum">
                            <span className='padding-3'>TP1</span>
                          </div>
                        </th>
                        <th className='cs-n-tstpck'>
                          <div className="ncss-col-sm-3 u-bold th-cl grey-background-groupsum">
                            <span className='padding-3'>TP2</span>
                          </div>
                        </th>
                        <th className='cs-n-tstpck'>
                          <div className="ncss-col-sm-3 u-bold th-cl grey-background-groupsum">
                            <span className='padding-3'>Individual</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    {showBilling === false && testPacks.length === 0 ? (
                      <tbody className='tbody-cl padding-0'>
                        <div className="row">
                          <div className="cell ncss-col-sm-3">
                            {showBilling === false && trfDetails.testingType === 'PR' ? "Will be known only after TRF Submission" : 'Not Applicable'}
                          </div>
                          <div className="cell ncss-col-sm-3">
                            {showBilling === false && trfDetails.testingType === 'PR' ? "Will be known only after TRF Submission" : 'Not Applicable'}
                          </div>
                          <div className="cell ncss-col-sm-3">
                            <p>{'Not Added'}</p>
                          </div>
                        </div>
                      </tbody>
                    ) :
                      (<tbody className='tbody-cl padding-0'>
                        {showBilling === false && testPacks.map((data, index) =>
                          <div className="row">
                            <div className="cell ncss-col-sm-3">
                              {(index === 0 && trfDetails.testingType === 'PR') ? "Will be known only after TRF Submission" : index > 0 ? '' : 'Not Applicable'}
                            </div>
                            <div className="cell ncss-col-sm-3">
                              {index === 0 && trfDetails.testingType === 'PR' ? "Will be known only after TRF Submission" : index > 0 ? '' : 'Not Applicable'}
                            </div>
                            <div className="cell ncss-col-sm-3">
                              <p>{data.CUSTOM}</p>
                            </div>
                          </div>
                        )}
                      </tbody>)}
                    {showBilling === true &&
                      <tbody className='tbody-cl padding-0'>
                        {testPacks.map((data, index) =>
                          <div className="row">
                            <div className="cell ncss-col-sm-3">
                              {index === 0 && showBilling === false ? "Will be known only after TRF Submission" : data.TP1}
                            </div>
                            <div className="cell ncss-col-sm-3">
                              {index === 0 && showBilling === false ? "Will be known only after TRF Submission" : data.TP2}
                            </div>
                            <div className="cell ncss-col-sm-3">
                              <p>{data.CUSTOM}</p>
                            </div>
                          </div>
                        )}
                      </tbody>}
                  </table>
                </td>
              </tr>
            </td>) :
          (<td className="ncss-col-sm-10 padding-0 vertical-top ml-n-10">
            <span className='ml-10 mt-9'>No Test Packages Added</span>
          </td>)}
      </tr>
    </Fragment>
  );
}

function testPackages(trfDetails, testPackages, customeTestpackages, showBilling) {
  let packages = [];
  /* Object.keys(testPackages).map((key, i) =>
    packages = packages.concat(getTestPackagesWithType(testPackages[key], key.toUpperCase()))
  ) */
  if (testPackages) {
    Object.keys(testPackages).map((key) =>
      packages = packages.concat(getTestPackagesWithType(testPackages[key], key.toUpperCase()))
    );
  }
  let customePackages = getTestPackagesWithType(customeTestpackages, "CUSTOM");
  packages = packages.concat(customePackages);
  packages.sort();
  let filteredResult = testPackageArry(packages)
  return createTestPackage(trfDetails, filteredResult, "Test Packages", showBilling)
}

function getTestPackagesWithType(testPackages, testType) {
  let testPackageFilterArry = [...new Set(testPackages)];
  let testPackage = testPackageFilterArry.length >= 1 && testPackageFilterArry.filter(trfInfo => trfInfo !== null).map(trfInfo => {
    if (trfInfo && filter(
      union(
        individualTest[0].common,
        individualTest[0].toys,
        individualTest[0].electronic,
        individualTest[0].packaging,
        individualTest[0].jewelry
      ),
      { variants: [{ id: trfInfo }] }
    ).length >= 1) {
      return filter(
        union(
          individualTest[0].common,
          individualTest[0].toys,
          individualTest[0].electronic,
          individualTest[0].packaging,
          individualTest[0].jewelry
        ),
        { variants: [{ id: trfInfo }] }
      )[0].label + ' - ' +
        find(
          flattenItems(
            union(
              individualTest[0].common,
              individualTest[0].toys,
              individualTest[0].electronic,
              individualTest[0].packaging,
              individualTest[0].jewelry
            ),
            'variants'
          ),
          ['id', trfInfo]
        ).label + ':' + testType
    }
    else {
      return find(
        union(
          individualTest[0].common,
          individualTest[0].toys,
          individualTest[0].electronic,
          individualTest[0].packaging,
          individualTest[0].jewelry
        ),
        ['id', trfInfo]
      ).label + ':' + testType
    }
  })
  return testPackage
}

const formatDateTime = updatedDate => {
  let isIE = /*@cc_on!@*/false || !!document.documentMode;
  let isEdge = !isIE && !!window.StyleMedia;
  let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const formatStr = 'DD-MMM-YYYY HH:mm:ss';
  let localDate;
  if (isEdge || isSafari) {

    const dateAndTime = updatedDate.split('T');
    const dateUpdated = dateAndTime[0].split('-');
    const timeUpdated = dateAndTime[1].split(':')
    const monthInChar = dateUpdated[1]
    const fullDate = monthInChar + "/" + dateUpdated[2] + "/" + dateUpdated[0] + " " + timeUpdated[0] + ":" + timeUpdated[1] + ":" + timeUpdated[2].split('.')[0];

    localDate = moment.utc(fullDate).local().format(formatStr);
  }

  else {
    localDate = moment.utc(updatedDate).local().format(formatStr);
  }
  return localDate;
}

const TestPackageComponent = ({ trfDetails }) => {
  let packages = getTestPackagesWithType(trfDetails.testPackages, "CUSTOM");
  packages.sort();
  let list = [];
  for (let i = 0; i < packages.length; i++) {
    const splitColumn = packages[i].split(":")
    const name = splitColumn[0]
    list.push({
      "TP1": "", "TP2": "",
      "CUSTOM": name
    });
  }
  const filterd = list.filter(e => Object.keys(e).length)
  var filteredResult = filterd.filter(function (el) {
    if (el.TP1 === '' && el.TP2 === '' && el.CUSTOM === '') {
      return filteredResult;
    }
    else {
      return el
    }
  });
  return createTestPackage(trfDetails, filteredResult, "Test Packages", false)
}

const materialTypeSection = (materialTypes, trfDetails) => {
  return (
    <Fragment>
      <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
        Material Types
      </td>
      <td className="ncss-col-sm-7 padding-0 vertical-top">
        {trfDetails && trfDetails.materialType && trfDetails.materialType !== null && trfDetails.materialType.length > 0 ?
          (<div>
            {trfDetails.materialType.map(trfInfo =>
              trfInfo !== null ? (
                <div key={trfInfo}>
                  {trfInfo === 4050 || trfInfo === 4051 ? (
                    <span className='mt-9'>
                      {"Inks and Paints"} {' - '} {trfInfo === 4050 || trfInfo === 4051 ? 'Screenprint Inks' : ''} {' - '} {trfInfo === 4050 ? 'Not White' : 'White'}
                    </span>
                  ) : ''}
                  {filter(materialTypes, { variants: [{ id: trfInfo }] }).length >= 1 ? (
                    <span className='mt-9'>
                      {
                        filter(materialTypes, { variants: [{ id: trfInfo }] })[0].label
                      }
                      {' - '}
                      {
                        find(flattenItems(materialTypes, 'variants'), ['id', trfInfo]).label
                      }
                    </span>
                  ) : find(flattenItems(materialTypes, 'variants'), ['id', trfInfo]) ?
                    (
                      <span>
                        {' '}
                        {
                          find(flattenItems(materialTypes, 'variants'), ['id', trfInfo]) ? find(flattenItems(materialTypes, 'variants'), ['id', trfInfo]).label : ''
                        }
                      </span>
                    ) : null}
                </div>
              ) : null
            )}

          </div>)
          :
          (<span className='mt-9'>No Materials Added</span>)}

      </td>
    </Fragment>
  )
}

const materialSectionUi = (trfDetails) => {
  return (
    <div>
      {trfDetails.materialType.map(trfInfo =>
        trfInfo !== null ? (
          <div key={trfInfo}>
            {trfInfo === 4050 || trfInfo === 4051 ? (
              <span className='mt-9'>
                {"Inks and Paints"} {' - '} {trfInfo === 4050 || trfInfo === 4051 ? 'Screenprint Inks' : ''} {' - '} {trfInfo === 4050 ? 'Not White' : 'White'}
              </span>
            ) : ''}
            {filter(materialTypes, { variants: [{ id: trfInfo }] }).length >= 1 ? (
              <span className='mt-9'>
                {
                  filter(materialTypes, { variants: [{ id: trfInfo }] })[0].label
                }
                {' - '}
                {
                  find(flattenItems(materialTypes, 'variants'), ['id', trfInfo]).label
                }
              </span>
            ) : find(flattenItems(materialTypes, 'variants'), ['id', trfInfo]) ?
              (
                <span>
                  {' '}
                  {
                    find(flattenItems(materialTypes, 'variants'), ['id', trfInfo]) ? find(flattenItems(materialTypes, 'variants'), ['id', trfInfo]).label : ''
                  }
                </span>
              ) : null}
          </div>
        ) : null
      )}

    </div>
  )
}
const materialTypeUIComponent = (trfDetails) => {
  return (
    trfDetails && trfDetails.materialType && trfDetails.materialType !== null && trfDetails.materialType.length > 0 &&
      (<Fragment>
        <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
          Material Types
        </td>
        <td className="ncss-col-sm-7 padding-0 vertical-top">
          {materialSectionUi(trfDetails)}
        </td></Fragment>
      ) 
  )
}


const MaterialTypeComponent = ({ trfDetails }) => {
  if (!trfDetails.materialType) {
  }
  return (
    <div className='mt-6'>
      <tr className="ncss-col-sm-12 padding-0 mt-2">
        {(trfDetails.product !== 204 && trfDetails.product !== 206) ? materialTypeSection(materialTypes, trfDetails)
          : materialTypeUIComponent(trfDetails)
        }
      </tr>
    </div>
  );
}

const summaryTable = (trfDetails) => {
  return (
    <table className="pdf_table mb4-sm" >
      <thead>
        <tr>
          <th className='cs-n'>
            <div className="th-cl-sum header-background padding-3">
              <span className='u-bold' colSpan={2}>{'SUMMARY'}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody className='add-border-top'>
        <tr className="ncss-col-sm-12 padding-0 mt-2" >
          <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
            {'RSL Number'}
          </td>
          <td className="ncss-col-sm-7 padding-0 vertical-top  " >
            {typeof trfDetails.RSLNo !== 'undefined' ? (
              <span className='mt-9'>
                {trfDetails.RSLNo}</span>
            ) : (
              <span className='mt-9'>
                {'Not Added'}</span>
            )}
          </td>
        </tr>
        <tr className="ncss-col-sm-12 padding-0 mt-9">
          <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
            {'TRF Submit Date'}
          </td>
          <td className="ncss-col-sm-7 padding-0 vertical-top  " >
            {typeof trfDetails.createdAt !== 'undefined' ? (
              <span className='mt-9'>
                {formatDateTime(trfDetails.createdAt)}</span>
            ) : (
              <span className='mt-9'>
                {'Not Added'}</span>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  )
}
const supplierTable = (trfDetails) => {
  return (
    <table className="pdf_table mb4-sm" style={{ width: '100%' }}>
      <thead>
        <tr>
          <th className='cs-n'>
            <div className="th-cl-sum header-background padding-3">
              <span className='u-bold' colSpan={2}>{'SUPPLIER INFORMATION'}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody className='add-border-top'>
        <tr className="ncss-col-sm-12 padding-0 mt-2">
          <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
            {'Supplier Name'}
          </td>
          <td className="ncss-col-sm-7 padding-0 vertical-top  " >
            {typeof trfDetails.supplierName !== 'undefined' ? (
              <span className='mt-9'>
                {trfDetails.supplierName}</span>
            ) : (
              <span className='mt-9'>
                {'Not Added'}</span>
            )}
          </td>
        </tr>
        <tr className="ncss-col-sm-12 padding-0 mt-9">
          <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
            {'Supplier Location'}
          </td>
          <td className="ncss-col-sm-7 padding-0 vertical-top  " >
            {typeof trfDetails.supLoc !== 'undefined' ? (
              <span className='mt-9'>
                {trfDetails.supLoc}</span>
            ) : (
              <span className='mt-9'>
                {'Not Added'}</span>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  )

}
const genaralInformationTable = (trfDetails) => {
  const filterRetestTrf = trfDetails.linkage.retestTRFArr.filter((data) => (data.level === 0));
  const retestTrf = filterRetestTrf.map((retestData) => (convertToRSLNumber(retestData.id)));
  const filterOriginalTrf = trfDetails.linkage.originalTRFArr.filter((data) => (data.level === 0));
  const originalTrf = filterOriginalTrf.map((originalData) => (convertToRSLNumber(originalData.id)));
  return (
    <table className="pdf_table mb4-sm" style={{ width: '100%' }}>
      <thead>
        <tr>
          <th className='cs-n'>
            <div className="th-cl-sum header-background padding-3">
              <span className='u-bold' colSpan={2}>{'GENERAL INFORMATION'}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody className='add-border-top'>
        <tr className="ncss-col-sm-12 padding-0 mt-2">
          <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
            {'Brand Tested For'}
          </td>
          {typeof trfDetails.brand !== 'undefined' ? (
            <td className="ncss-col-sm-7 padding-0 vertical-top" >
              {typeof trfDetails.brand !== 'undefined' && trfDetails.brand !== null ? (
                <span className='mt-9'>
                  {find(brandInfo, ['id', trfDetails.brand]).value}
                </span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          ) : (
            <td className="ncss-col-sm-7 padding-0 vertical-top " >
              <span className='mt-9'>Not Added</span>
            </td>
          )}
        </tr>
        <tr className="ncss-col-sm-12 padding-0 mt-9">
          <td className="ncss-col-sm-5  padding-0   vertical-top u-bold">
            {'Product Type'}
          </td>
          {typeof trfDetails.product !== 'undefined' && trfDetails.product !== null ? (
            <td className="ncss-col-sm-7 padding-0 vertical-top" >
              {typeof trfDetails.product !== 'undefined' ? (
                <span className='mt-9'>
                  {find(productInfo, ['id', trfDetails.product]).label}</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          ) : (
            <td className="ncss-col-sm-7 padding-0 vertical-top" >
              <span className='mt-9'>Not Added</span>
            </td>
          )}
        </tr>
        <tr className="ncss-col-sm-12 padding-0 mt-9">
          <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
            {'Licensee'}
          </td>
          {typeof trfDetails.licensee !== 'undefined' && trfDetails.licensee !== null ? (
            <td className="ncss-col-sm-7 padding-0 vertical-top  " >
              {typeof trfDetails.licensee !== 'undefined' ? (
                <span className='mt-9'>
                  {trfDetails.licensee}</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          ) : (
            <td className="ncss-col-sm-7 padding-0 vertical-top" >
              <span className='mt-9'>Not Added</span>
            </td>
          )}
        </tr>
        {/* For Original TRF */}

        {typeof trfDetails.linkage.originalTRFArr !== 'undefined' && 
          trfDetails.linkage.originalTRFArr !== null &&
          trfDetails.linkage.originalTRFArr.length > 0
          ? (
        <tr className="ncss-col-sm-12 padding-0 mt-9">
          <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
            {'Link TRF'}
          </td>          
          <td className="ncss-col-sm-7 padding-0 vertical-top  " >
            <span className='mt-9'>
              {originalTrf.join(" , ")}
            </span>            
          </td>         
        </tr>
         ) : (
          null
        )}
        {/* END */}
        {/* Re-test Trf */}
        {typeof trfDetails.linkage.retestTRFArr !== 'undefined' && 
          trfDetails.linkage.retestTRFArr !== null &&
          trfDetails.linkage.retestTRFArr.length > 0
          ? (
        <tr className="ncss-col-sm-12 padding-0 mt-9">
          <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
            {'Re-Test TRF'}
          </td>
          <td className="ncss-col-sm-7 padding-0 vertical-top  " >           
            <span className='mt-9'>
             {retestTrf.join(" , ")}
            </span>            
          </td>        
        </tr>
         ) : (
           null
         )}
        {/* End */}
        <tr className="ncss-col-sm-12 padding-0 mt-9">
            <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
              Season
            </td>
            <td className="ncss-col-sm-7 padding-0 vertical-top  " >
              {typeof trfDetails.seasonId !== 'undefined' ? (
                <span className='mt-9'>
                  {find(Season, ['id', trfDetails.seasonId]).label}{' '}</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          </tr>
          <tr className="ncss-col-sm-12 padding-0 mt-9">
            <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
              Year
            </td>
            <td className="ncss-col-sm-7 padding-0 vertical-top  " >
              {typeof trfDetails.year !== 'undefined' ? (
                <span className='mt-9'>
                  {trfDetails.year}</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          </tr>
      </tbody>
    </table>
  )
}
const billingInformationTable = (trfDetails) => {
  return (
    <table className="pdf_table mb4-sm">
      <thead>
        <tr>
          <th className='cs-n'>
            <div className="th-cl-sum header-background padding-3">
              <span className='u-bold' colSpan={2}>{'BILLING INFORMATION'}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody className='add-border-top'>
        <tr className="ncss-col-sm-12 padding-0 mt-2 p2-sm">
          <td className="ncss-col-sm-2 padding-0   vertical-top u-bold">
            Name :
          </td>
          <td className="ncss-col-sm-10 padding-0 vertical-top  " >
            <div className='billing-text-box ' style={{ width: "60%" }}></div>
          </td>
        </tr>
        <tr className="ncss-col-sm-12 padding-0">
          <td className="ncss-col-sm-2 padding-0   vertical-top u-bold">
            Street Address :
          </td>
          <td className="ncss-col-sm-10 padding-0 vertical-top  " >
            <div className='billing-text-box ' style={{ width: "60%" }}></div>
          </td>
        </tr>
        <tr className="ncss-col-sm-12 padding-0">
          <td className="ncss-col-sm-2 padding-0   vertical-top u-bold">
            City :
          </td>
          <td className="ncss-col-sm-2 padding-0 vertical-top  " >
            <div className='ncss-col-sm-12 ncss-input-container billing-text-box'></div>
          </td>
          <td className="ncss-col-sm-2 padding-0   vertical-top u-bold text-center" >
            Postal Code :
          </td>
          <td className="ncss-col-sm-6 padding-0 vertical-top  " >
            <div className='ncss-col-sm-4 ncss-input-container billing-text-box'></div>
          </td>
        </tr>
        <tr className="ncss-col-sm-12 padding-0">
          <td className="ncss-col-sm-2 padding-0   vertical-top u-bold">
            Region / State / Province :
          </td>
          <td className="ncss-col-sm-2 padding-0 vertical-top  " >
            <div className='ncss-col-sm-12 ncss-input-container billing-text-box'></div>
          </td>
          <td className="ncss-col-sm-2 padding-0   vertical-top u-bold text-center country-padding">
            Country :
          </td>
          <td className="ncss-col-sm-6 padding-0 vertical-top  " >
            <div className='ncss-col-sm-4 ncss-input-container billing-text-box'></div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
const materialInformationTable = (trfDetails, showBilling) =>{
  return (
    <table className="pdf_table mb4-sm" style={{ width: '100%' }}>
        <thead>
          <tr>
            <th className='cs-n'>
              <div className="th-cl-sum header-background padding-3">
                <span className='u-bold' colSpan={2}>MATERIAL INFORMATION</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className='add-border-top'>
          <tr className="ncss-col-sm-12 padding-0 mt-2">
            <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
              Material Name
            </td>
            <td className="ncss-col-sm-7 padding-0 vertical-top  " >
              {typeof trfDetails.materialName !== 'undefined' ? (
                <span className='mt-9'>
                  {trfDetails.materialName}</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          </tr>
          <tr className="ncss-col-sm-12 padding-0 mt-9">
            <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
              Material Number
            </td>
            <td className="ncss-col-sm-7 padding-0 vertical-top  " >
              {typeof trfDetails.materialNumber !== 'undefined' ? (
                <span className='mt-9'>
                  {trfDetails.materialNumber}</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          </tr>
          <tr className="ncss-col-sm-12 padding-0 mt-9">
            <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
              PDM Number
            </td>
            <td className="ncss-col-sm-7 padding-0 vertical-top  " >
              {typeof trfDetails.PDMNumber !== 'undefined' && trfDetails.PDMNumber !== null ? (
                <span className='mt-9'>
                  {trfDetails.PDMNumber}</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          </tr>
          <tr className="ncss-col-sm-12 padding-0 mt-9">
            <td className="ncss-col-sm-5 padding-0 vertical-top u-bold">
              Material ID
            </td>
            <td className="ncss-col-sm-7 padding-0 vertical-top  " >
              {typeof trfDetails.refId !== 'undefined' && trfDetails.refId !== null ? (
                <span className='mt-9'>
                  {trfDetails.refId}</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          </tr>
          <tr className="ncss-col-sm-12 padding-0 mt-9">
            <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
              Sample Type
            </td>
            <td className="ncss-col-sm-7 padding-0 vertical-top  " >
              {typeof trfDetails.sampleType !== 'undefined' && trfDetails.sampleType !== null ? (
                <span className='mt-9'>
                  {trfSampleTypeName(trfDetails.sampleType)}</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          </tr>
          <tr className="ncss-col-sm-12 padding-0 mt-9">
            <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
              Color Name
            </td>
            <td className="ncss-col-sm-7 padding-0 vertical-top  " >
              {typeof trfDetails.colorName !== 'undefined' && trfDetails.colorName !== null && trfDetails.colorName !== '' ? (
                <span className='mt-9'>
                  {trfDetails.colorName}</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          </tr>
          <tr className="ncss-col-sm-12 padding-0 mt-9">
            <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
              Color Code
            </td>
            <td className="ncss-col-sm-7 padding-0 vertical-top  " >
              {typeof trfDetails.colorCode !== 'undefined' && trfDetails.colorCode !== null && trfDetails.colorCode !== '' ? (
                <span className='mt-9'>
                  {trfDetails.colorCode}</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          </tr>
          <tr className="ncss-col-sm-12 padding-0 mt-9">
            <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
              Color Way
            </td>
            <td className="ncss-col-sm-7 padding-0 vertical-top  " >
              {typeof trfDetails.colorWay !== 'undefined' && trfDetails.colorWay !== null && trfDetails.colorWay !== '' ? (
                <span className='mt-9'>
                  {trfDetails.colorWay}</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          </tr>
          <tr className="ncss-col-sm-12 padding-0 mt-9">
            <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
              Age Group
            </td>
            <td className="ncss-col-sm-7 padding-0 vertical-top  " >
              {typeof trfDetails.ageGroup !== 'undefined' && trfDetails.ageGroup !== null && trfDetails.ageGroup !== '' ? (
                <span className='mt-9'>
                  {trfAgeGroupName(trfDetails.ageGroup)}</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          </tr>
          <tr className="ncss-col-sm-12 padding-0 mt-9">
            <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
              Sample Color
            </td>
            <td className="ncss-col-sm-7 padding-0 vertical-top  " >
              {typeof trfDetails.sampleColor !== 'undefined' && trfDetails.sampleColor !== null && trfDetails.sampleColor !== '' ? (
                <span className='mt-9'>
                  {trfDetails.sampleColor !== '' ? strUpperCase(trfDetails.sampleColor) : ''}</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          </tr>
          <tr className="ncss-col-sm-12 padding-0 mt-9">
            <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
              Sample Description
            </td>
            <td className="ncss-col-sm-7 padding-0 vertical-top  " >
              {typeof trfDetails.description !== 'undefined' && trfDetails.description !== null && trfDetails.description !== '' ? (
                <span className='mt-9'>
                  {trfDetails.description}</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          </tr>
          {trfDetails && typeof trfDetails !== 'undefined' && typeof trfDetails.certification !== 'undefined' && trfDetails.certification.length > 0 ?
            displayCertification(trfDetails) : ''}
          <tr className="ncss-col-sm-12 padding-0 mt-9">
            <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
              Does material have repellent finish?
            </td>
            <td className="ncss-col-sm-7 padding-0 vertical-top  " >
              {typeof trfDetails.repellent !== 'undefined' ? (
                <span className='mt-9'>
                  {trfDetails.repellent ? 'Yes' : 'No'}</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          </tr>
          <tr className="ncss-col-sm-12 padding-0 mt-9">
            <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
              Is the material intended to come into contact with food or mouth?
            </td>
            <td className="ncss-col-sm-7 padding-0 vertical-top  " >
              {typeof trfDetails.foodOrMouth !== 'undefined' ? (
                <span className='mt-9'>
                  {trfDetails.foodOrMouth ? 'Yes' : 'No'}</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          </tr>
          {(trfDetails.product === 204 || trfDetails.product === 206) ? (
            <Fragment>
            <tr className="ncss-col-sm-12 padding-0 mt-9">
              <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
              Will this electonic material be embedded into a finished product (e.g. Footwear)?
              </td>
              <td className="ncss-col-sm-7 padding-0 vertical-top  " >
                {typeof trfDetails.isElectEmbedded !== 'undefined' ? (
                  <span className='mt-9'>
                    {trfDetails.isElectEmbedded ? 'Yes' : 'No'}</span>
                ) : (
                  <span className='mt-9'>Not Added</span>
                )}
              </td>
            </tr>
            <tr className="ncss-col-sm-12 padding-0 mt-9">
            <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
            Is this electronic material intended to come in contact with user's skin?
            </td>
            <td className="ncss-col-sm-7 padding-0 vertical-top  " >
              {typeof trfDetails.skinContact !== 'undefined' ? (
                <span className='mt-9'>
                  {trfDetails.skinContact ? 'Yes' : 'No'}</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          </tr>
          </Fragment>
          ) : ''}
          {trfDetails.product === 202 ?
            (<tr className="ncss-col-sm-12 padding-0 mt-9">
              <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
                Is this material intended for eye-wear frames?
              </td>
              <td className="ncss-col-sm-7 padding-0 vertical-top  " >
                {typeof trfDetails.eyeWear !== 'undefined' && trfDetails.eyeWear !== null ? (
                  <span className='mt-9'>
                    {trfDetails.eyeWear ? 'Yes' : 'No'}</span>
                ) : (
                  <span className='mt-9'>Not Available</span>
                )}
              </td>
            </tr>) : ''}
          <tr className="ncss-col-sm-12 padding-0 mt-9">
            <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
              Enable TP2
            </td>
            <td className="ncss-col-sm-7 padding-0 vertical-top  " >
              {typeof trfDetails.enableTP2 !== 'undefined' ? (
                <span className='mt-9'>
                  {trfDetails.enableTP2 === true ? 'Yes' : 'No'}</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          </tr>
          <tr className="ncss-col-sm-12 padding-0 mt-9">
            <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
            Does this material sample contain recycled content?
            </td>
            <td className="ncss-col-sm-7 padding-0 vertical-top  " >
              {typeof trfDetails.recycle !== 'undefined' ? (
                <span className='mt-9'>
                  {trfDetails.recycle === true ? 'Yes' : 'No'}</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          </tr>
          {trfDetails.recycle === true ? (
            <Fragment>
           <tr className="ncss-col-sm-12 padding-0 mt-9">
           <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
           List the samples recycled material source
           </td>
           <td className="ncss-col-sm-7 padding-0 vertical-top  " >
             {typeof trfDetails.listRecycle !== 'undefined' ? (
               <span className='mt-9'>
                 {trfDetails.listRecycle}</span>
             ) : (
               <span className='mt-9'>Not Added</span>
             )}
           </td>
         </tr> 
          <tr className="ncss-col-sm-12 padding-0 mt-9">
          <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
          Describe the recycled content in the material sample
          </td>
          <td className="ncss-col-sm-7 padding-0 vertical-top  " >
            {typeof trfDetails.describeRecycle !== 'undefined' ? (
              <span className='mt-9'>
                {trfDetails.describeRecycle}</span>
            ) : (
              <span className='mt-9'>Not Added</span>
            )}
          </td>
        </tr> </Fragment>
          ) : ''}
          <MaterialTypeComponent trfDetails={trfDetails} />
          <tr className="ncss-col-sm-12 padding-0 mt-9 mt-6">
            <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
            Default Test 
            </td>
            <td className="ncss-col-sm-7 padding-0 vertical-top  " >
              {typeof trfDetails.testLogic !== 'undefined' ? (
                <span className='mt-9'>
                  {trfDetails.testLogic}</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          </tr>
          <tr className="ncss-col-sm-12 padding-0 mt-9 mt-6">
            <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
              Testing Type
            </td>
            <td className="ncss-col-sm-7 padding-0 vertical-top  " >
              {typeof trfDetails.testingType !== 'undefined' ? (
                <span className='mt-9'>
                  {trfDetails.testingType }</span>
              ) : (
                <span className='mt-9'>Not Added</span>
              )}
            </td>
          </tr>
          {showBilling === true ? testPackages(trfDetails, trfDetails.defaultClass, trfDetails.customClass, showBilling) :
            <TestPackageComponent trfDetails={trfDetails} />
          }
        </tbody>
      </table>
  )
}
const laboratoryTable = (trfDetails) => {
  return (<table className="pdf_table mb4-sm ">
    <tr>
      <th className='cs-n'>
        <div className="th-cl-sum header-background padding-3">
          <span className='u-bold' colSpan={2}>{'LABORATORY INFORMATION'}</span>
        </div>
      </th>
    </tr>
    <tbody className='add-border-top'>
      <tr className="ncss-col-sm-12 padding-0 mt-2">
        <td className="ncss-col-sm-2 padding-0   vertical-top u-bold">
          Lab
        </td>
        {typeof trfDetails.materialType !== 'undefined' ? (
          <td className="ncss-col-sm-10 padding-0 vertical-top">
            {filter(location, { location: [{ id: trfDetails.labLocationId }] }).length >= 1 ? (
              <span>
                {filter(location, { location: [{ id: trfDetails.labLocationId }] })[0].lab}
              </span>
            ) : (
              <span>No Location Added</span>
            )}
          </td>
        ) : (
          <td className="ncss-col-sm-5 margin-lab  u-bold">
            <span>Not Added</span>
          </td>
        )}
      </tr>
      <tr className="ncss-col-sm-12 padding-0 mt-9">
        <td className="ncss-col-sm-2 padding-0   vertical-top u-bold">
          Location
        </td>
        <td className="ncss-col-sm-10 padding-0 vertical-top" >
          {typeof trfDetails.labLocationId !== 'undefined' ? (
            <span>
              {find(flattenItems(location, 'location'), ['id', trfDetails.labLocationId]).area} {' - '}
              {find(flattenItems(location, 'location'), ['id', trfDetails.labLocationId]).country}
            </span>
          ) : (
            <span>
              Not Added
            </span>
          )}
        </td>
      </tr>
      <tr className="ncss-col-sm-12 padding-0 mt-9">
        <td className="ncss-col-sm-2 padding-0   vertical-top u-bold">
          Service
        </td>
        <td className="ncss-col-sm-10 padding-0 vertical-top  " >
          {typeof trfDetails.service !== 'undefined' ? (
            <span>
              {trfDetails.service}
            </span>
          ) : (
            <span>
              Not Added
            </span>
          )}
        </td>
      </tr>
    </tbody>
  </table>)
}
const displayCertification = (testingInfoStates) => {
  const certificationArry = testingInfoStates.certification
  return (
    <div>
      {certificationArry && certificationArry.length > 0 && typeof certificationArry !== 'undefined'
        && Object.keys(certificationArry).length !== 0 ? (
        certificationArry.map((data, index) => {
          return (
            <Fragment>
              <tr className="ncss-col-sm-12 padding-0 mt-9">
                <td className="ncss-col-sm-5 padding-0   vertical-top u-bold">
                  {index === 0 ? "Certificate" : ''}
                </td>
                <td className="ncss-col-sm-7 padding-0 vertical-top" style={{ marginLeft: '-3px' }}>
                  <td className="ncss-col-sm-4 padding-0 vertical-top">
                  {trfCertificationName(data.name) === 'Other' ? strUpperCase(data.name) : trfCertificationName(data.name)}
                  </td>
                  <td className="ncss-col-sm-2 padding-0 vertical-top u-bold" >
                    Number
                  </td>
                  <td className="ncss-col-sm-2 padding-0 vertical-top" >
                    {data.number}
                  </td>
                </td>
              </tr>
            </Fragment>
          )
        })
      ) : ''}
    </div>
  )
}
const supplierInfoTable = (trfDetails) => {
  return (
    <table className="pdf_table mb4-sm" style={{ width: '100%' }}>
      <thead>
        <tr>
          <th className='cs-n'>
            <div className="th-cl-sum header-background padding-3">
              <span className='u-bold' colSpan={2}>{'SAMPLE SUBMITTER INFORMATION'}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody className='add-border-top'>
        <tr className="ncss-col-sm-12 padding-0 mt-2">
          <td className="ncss-col-sm-2 padding-0   vertical-top u-bold">
            Name
          </td>
          <td className="ncss-col-sm-10 padding-0 vertical-top  " >
            {typeof trfDetails.name !== 'undefined' ? (
              <span>{trfDetails.name}</span>
            ) : (
              <span>Not Added</span>
            )}
          </td>
        </tr>
        <tr className="ncss-col-sm-12 padding-0 mt-9">
          <td className="ncss-col-sm-2 padding-0   vertical-top u-bold">
            E-mail
          </td>
          <td className="ncss-col-sm-10 padding-0 vertical-top  " >
            {typeof trfDetails.userEmail !== 'undefined' ? (
              <span>{trfDetails.userEmail}</span>
            ) : (
              <span>Not Added</span>
            )}
          </td>
        </tr>
        <tr className="ncss-col-sm-12 padding-0 mt-9">
          <td className="ncss-col-sm-2 padding-0   vertical-top u-bold">
            Contact
          </td>
          <td className="ncss-col-sm-10 padding-0 vertical-top  " >
            {trfDetails.userContactNumber ? (
              <span>{trfDetails.userContactNumber}</span>
            ) : (
              <span>Not Added</span>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  )
}
export const TrfPrintable = ({ trfDetails, showBilling }) => {
  sessionStorage.setItem("trfCreatedDate", formatDate(trfDetails.createdAt));
  sessionStorage.setItem("reportId", trfDetails.RSLNo);
  return (
    <div className="test_details" >
      {summaryTable(trfDetails)}
      {/******** */}
      {supplierTable(trfDetails)}
      {/*******GENERAL INFORMATION ******* */}
      {genaralInformationTable(trfDetails)}
      {/**********LABORATORY INFORMATION ************/}
      <div className='ncss-col-sm-6 padding-0'>
        {laboratoryTable(trfDetails)}
      </div>
      <div className='ncss-col-sm-6 padding-right-submitter'>
        {supplierInfoTable(trfDetails)}
      </div>
      {/*******BILLING INFORMATION ******* */}
      {billingInformationTable(trfDetails)}
      {/*******MATERIAL INFORMATION******* */}
      <div className="page-break" />
      <span />
      {materialInformationTable(trfDetails, showBilling)}
      {trfDetails && typeof trfDetails !== 'undefined' && trfDetails.materialType && trfDetails.pColor ?
        <table className="pdf_table mb4-sm" >
          <thead>
            <tr>
              <th className='cs-n'>
                <div className="th-cl-sum header-background padding-3">
                  <span className='u-bold' colSpan={2}>{'INKS'}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className='add-border-top'>
            <div className='ncss-col-sm-6 padding-0'>
              <tr className="ncss-col-sm-12 padding-0 mt-2" >
                <td className="ncss-col-sm-3 padding-0   vertical-top u-bold">
                  {'Ink System Name'}
                </td>
                <td className="ncss-col-sm-9 padding-0 vertical-top  " >
                  {typeof trfDetails.inkSystem !== 'undefined' && trfDetails.inkSystem !== null && trfDetails.inkSystem !== '' ? (<span className='mt-9'>
                    {trfDetails.inkSystem}</span>
                  ) : (
                    <span className='mt-9'>
                      {'Not Added'}</span>
                  )}
                </td>
              </tr>
              <tr className="ncss-col-sm-12 padding-0 mt-9">
                <td className="ncss-col-sm-3 padding-0   vertical-top u-bold">
                  {'Base Name'}
                </td>
                <td className="ncss-col-sm-9 padding-0 vertical-top  " >
                  {typeof trfDetails.bName !== 'undefined' && trfDetails.bName !== null && trfDetails.bName !== '' ? (
                    <span className='mt-9'>
                      {trfDetails.bName}</span>
                  ) : (
                    <span className='mt-9'>
                      {'Not Added'}</span>
                  )}
                </td>
              </tr>
              <tr className="ncss-col-sm-12 padding-0 mt-9">
                <td className="ncss-col-sm-3 padding-0   vertical-top u-bold">
                  {'Pigment Color'}
                </td>
                <td className="ncss-col-sm-9 padding-0 vertical-top  " >
                  {typeof trfDetails.pColor !== 'undefined' && trfDetails.pColor !== null && trfDetails.pColor !== '' ? (
                    <span className='mt-9'>
                      {trfDetails.pColor}</span>
                  ) : (
                    <span className='mt-9'>
                      {'Not Added'}</span>
                  )}
                </td>
              </tr>
            </div>
            <div className='ncss-col-sm-6 padding-0'>

              <tr className="ncss-col-sm-12 padding-0 mt-9">
                <td className="ncss-col-sm-3 padding-0   vertical-top u-bold">
                  {'Additive 1 Name'}
                </td>
                <td className="ncss-col-sm-9 padding-0 vertical-top  " >
                  {typeof trfDetails.addit1 !== 'undefined' && trfDetails.addit1 !== null && trfDetails.addit1 !== '' ? (
                    <span className='mt-9'>
                      {trfDetails.addit1}</span>
                  ) : (
                    <span className='mt-9'>
                      {'Not Added'}</span>
                  )}
                </td>
              </tr>
              <tr className="ncss-col-sm-12 padding-0 mt-9">
                <td className="ncss-col-sm-3 padding-0   vertical-top u-bold">
                  {'Additive 2 Name'}
                </td>
                <td className="ncss-col-sm-9 padding-0 vertical-top  " >
                  {typeof trfDetails.addit2 !== 'undefined' && trfDetails.addit2 !== null && trfDetails.addit2 !== '' ? (
                    <span className='mt-9'>
                      {trfDetails.addit2}</span>
                  ) : (
                    <span className='mt-9'>
                      {'Not Added'}</span>
                  )}
                </td>
              </tr>
              <tr className="ncss-col-sm-12 padding-0 mt-9">
                <td className="ncss-col-sm-3 padding-0   vertical-top u-bold">
                  {'Additive 3 Name'}
                </td>
                <td className="ncss-col-sm-9 padding-0 vertical-top  " >
                  {typeof trfDetails.addit3 !== 'undefined' && trfDetails.addit3 !== null && trfDetails.addit3 !== '' ? (
                    <span className='mt-9'>
                      {trfDetails.addit3}</span>
                  ) : (
                    <span className='mt-9'>
                      {'Not Added'}</span>
                  )}
                </td>
              </tr>
            </div>
          </tbody>
        </table>
        : null}
      <div className="disclaimerPDF">
        <h5 >IMPORTANT NOTICE:</h5> This Test Request Form (TRF) is intended to be received only by
        persons entitled to receive the confidential information it may contain.  Test Requests for
        product that presumptively could be used
        by NIKE, Inc. and its subsidiaries are confidential and legally privileged;  Do not read, copy,
        forward, print or store this TRF or any part of it unless you are an intended recipient of it.
        If you have received this TRF
        in error, please notify the NIKE RSL team immediately at RSLSupport@nike.com, and permanently
        delete this original and all copies of the TRF.
      </div>
      <style>{styles}</style>

    </div>
  );
}

TrfPrintable.propTypes = {
  trfDetails: PropTypes.array.isRequired,
  isDownload: PropTypes.bool,
  showTitle: PropTypes.bool
};
export default TrfPrintable;