 
const config = [
  {
    colId: 'fileName',
    headerName: 'Title',
    suppressMovable: true,
    field: 'fileName',
    suppressHeaderMenuButton: true
  },
  {
    colId: 'format',
    headerName: 'Format',
    suppressMovable: true,
    field: 'format',
    suppressHeaderMenuButton: true
  },
  {
    colId: 'submittedDate',
    headerName: 'Submitted Date',
    suppressMovable: true,
    field: 'submittedDate',
    suppressHeaderMenuButton: true,
    cellStyle: { color: '#3498db' }
  }
];
export default config;
