import React, { Component, Fragment } from 'react';
import trfDetailsJson from './trfDetailsJson.js';
import individualTest from '../../constants/json/checkboxes/individualTestPackages.json';
import find from 'lodash/find';
import union from 'lodash/union';
import moment from 'moment';
import { styles } from './style.js';
import { flattenItems, formatDate } from '../../util/index.js';
import { strUpperCase, trfCertificationName } from '../../util/index.js';
import filter from 'lodash/filter';
import { testPackageArry } from './testPackageTableLogic.js';
import * as _ from 'lodash';
import isEmpty from 'lodash/isEmpty';


class TestDetailsPDF extends Component {
  constructor(...props) {
    super(...props);
    this.state = { testDetails: [], trfDetails: [], frfDetails: {}, qrfDetails: {}, elementHeight: 0, nonCompliance: [] }
  }

  componentWillMount() {
    const { testDetails, trfDetails, testDetailsByClass, isRslUser } = this.props;
    trfDetails && sessionStorage.setItem("labRecDate", formatDate(trfDetails.data.trfDetails.labRecDate));
    let trfDetailsJsonData = trfDetailsJson(trfDetails)
    trfDetailsJsonData.map((obj, i) => {
      if (obj.headerName === "Laboratory Information" && !isRslUser) {
        trfDetailsJsonData[i] = {
          "headerName": "Laboratory Information",
          "Lab": obj["Lab"],
          "Test Report Date": obj["Test Report Date"],
          "Location": obj["Location"],
          "Lab Accept Date": obj["Lab Accept Date"],
          "Lab Reference Number": obj["Lab Reference Number"]
        }
      }
      if (trfDetails && trfDetails.data.trfDetails.product !== 204 && trfDetails.data.trfDetails.product !== 206) {
        delete trfDetailsJsonData[3]["Is this electronic material intended to come in contact with user's skin?"]
        delete trfDetailsJsonData[3]["Will this electonic material be embedded into a finished product (e.g. Footwear)?"]
      }
      if (trfDetails && trfDetails.data.trfDetails.product !== 202) {
        delete trfDetailsJsonData[3]["Is this material intended for eye-wear frames?"]
      }
      if (trfDetails && trfDetails.data.trfDetails.certification && trfDetails.data.trfDetails.certification.length === 0) {
        delete trfDetailsJsonData[3]["Certificate"]
      }
      if (trfDetails && trfDetails.data.trfDetails.recycle === false) {
        delete trfDetailsJsonData[3]["List the samples recycled material source (e.g. post industrial, post consumer, pre consumer, etc.)"]
        delete trfDetailsJsonData[3]["Describe the recycled content in the material sample (e.g. 20% recycled PET, 10% recycled TPU, etc.)"]
      }
      if (trfDetails && trfDetails.data.trfDetails.linkage && trfDetails.data.trfDetails.linkage.originalTRFArr.length === 0 ) {
        delete trfDetailsJsonData[2]["Link TRF"]
      }
      if (trfDetails && trfDetails.data.trfDetails.linkage && trfDetails.data.trfDetails.linkage.retestTRFArr.length === 0 ) {
        delete trfDetailsJsonData[2]["Re-Test TRF"]
     
      }
      return trfDetailsJsonData;
    })
    this.setState({
      trfDetails: trfDetailsJsonData,
      frfDetails: trfDetails.data.frfDetails,
      qrfDetails: trfDetails.data.qrfDetails,
      testDetails: testDetails && testDetails.data ? testDetails.data : [],
      testDetailsByClass: testDetailsByClass ? testDetailsByClass : []
    })
  }


  getClassName(classId, status, testType) {
    let renderedText = '';

    if (classId && filter(
      union(
        individualTest[0].common,
        individualTest[0].toys,
        individualTest[0].electronic,
        individualTest[0].packaging,
        individualTest[0].jewelry
      ),
      { variants: [{ id: classId }] }
    ).length >= 1) {
      renderedText = filter(
        union(
          individualTest[0].common,
          individualTest[0].toys,
          individualTest[0].electronic,
          individualTest[0].packaging,
          individualTest[0].jewelry
        ),
        { variants: [{ id: classId }] }
      )[0].label + ' - ' +
        find(
          flattenItems(
            union(
              individualTest[0].common,
              individualTest[0].toys,
              individualTest[0].electronic,
              individualTest[0].packaging,
              individualTest[0].jewelry
            ),
            'variants'
          ),
          ['id', classId]
        ).label.replace('–', '-')
    }
    else {
      renderedText = find(
        union(
          individualTest[0].common,
          individualTest[0].toys,
          individualTest[0].electronic,
          individualTest[0].packaging,
          individualTest[0].jewelry
        ),
        ['id', classId]
      ).label.replace('–', '-')
    }
    if (status === undefined) {
      return renderedText
    } else {
      return renderedText + ' - ' + status + ':' + testType;
    }
  }

  processResult(result, getClassValue, unit) {
    let renderedText = result;
    renderedText = unit ? `${renderedText} ${unit}` : renderedText;
    const classValue = 532;
    const classValueAsbestos = 504;
    if (result !== ' ') {
      if (getClassValue === classValue || getClassValue === classValueAsbestos) {
        if (result === -2) {
          renderedText = 'Present';
        } else if (result === -3) {
          renderedText = 'Absent';
        } else {
          renderedText = 'Invalid Test Result Value '
        }
      } else if (result < 0) {
        if (result === -4) {
          renderedText = 'Not Tested';
        }
        else if (result === -1) {
          renderedText = 'Not Detected';
        }
        else {
          renderedText = 'Invalid Test Result Value '
        }
      }
    }
    return renderedText;
  }

  formatDate = (updatedDate, dateFormat = 'DD-MMM-YYYY HH:mm:ss') => {
    let isIE = /*@cc_on!@*/false || !!document.documentMode;
    let isEdge = !isIE && !!window.StyleMedia;
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const formatStr = dateFormat;
    let localDate;
    if (isEdge || isSafari) {

      const dateAndTime = updatedDate.split('T');
      const dateUpdated = dateAndTime[0].split('-');
      const timeUpdated = dateAndTime[1].split(':')
      const monthInChar = dateUpdated[1]
      const fullDate = monthInChar + "/" + dateUpdated[2] + "/" + dateUpdated[0] + " " + timeUpdated[0] + ":" + timeUpdated[1] + ":" + timeUpdated[2].split('.')[0];
      
      localDate = moment.utc(fullDate).local().format(formatStr);
    }

    else {
      localDate = moment.utc(updatedDate).local().format(formatStr);
    }
    return localDate;
  }

  createTestPackage(testPacks, key) {
    return (
      <Fragment>
        <tr className="ncss-col-sm-12">
          <td className="ncss-col-sm-2 table-align u-bold">
            <span className='ml-n-8 mt-9'>{key}</span></td>
          <td className="ncss-col-sm-10 padding-0 ml-n-8">
            <table className='tbl-cl' >
              <thead>
                <tr>
                  <th className='cs-n-tstpck'>
                    <div className="ncss-col-sm-3 u-bold th-cl grey-background-groupsum">
                      <span className='padding-3'>TP1</span>
                    </div>
                  </th>
                  <th className='cs-n-tstpck'>
                    <div className="ncss-col-sm-3 u-bold th-cl grey-background-groupsum">
                      <span className='padding-3'>TP2</span>
                    </div>
                  </th>
                  <th className='cs-n-tstpck'>
                    <div className="ncss-col-sm-3 u-bold th-cl grey-background-groupsum">
                      <span className='padding-3'>Individual</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className='tbody-cl'>
                {testPacks.map((data, index) =>
                  <div className="row">
                    <div className="cell ncss-col-sm-3">
                      <p>
                        {data.TP1}</p>
                    </div>
                    <div className="cell ncss-col-sm-3">
                      <p> {data.TP2}</p>
                    </div>
                    <div className="cell ncss-col-sm-3">
                      <p>{data.CUSTOM}</p>
                    </div>
                  </div>
                )}
              </tbody>
            </table>
          </td>
        </tr>
      </Fragment>
    );
  }

  getTestClassWithPackage(testClass) {
    const { trfDetails: { data: { trfDetails } = {} } = {} } = this.props;
    var custom = trfDetails['customClass'];
    var defaultClass = trfDetails['defaultClass'];
    var TP1 = defaultClass['tp1']; var TP2 = defaultClass['tp2'];
    let packages = [];
    testClass.map((obj, i) =>
      packages = packages.concat(this.getClassName(obj.class, obj.status, custom.includes(obj.class) ? "CUSTOM" :
        TP1.includes(obj.class) ? "TP1" : TP2.includes(obj.class) ? "TP2" : ""))
    )
    packages.sort();
    let filteredResult = testPackageArry(packages)
    return this.createTestPackage(filteredResult, "Test Class")
  }

  returnLabSectionTable(key, objKey) {

    return (
      <td className={key === "Lab Reference Number" ? "ncss-col-sm-6 padding-0 align-lab " : "ncss-col-sm-6 padding-0 "}>
        <span>
          {key === "Lab Accept Date" ?
            objKey !== "Not Available" ? this.formatDate(objKey) : objKey
            :
            key === "Test Report Date" && objKey ?
              this.formatDate(objKey)
              :
              objKey
          }
        </span>
      </td>
    )
  }
  certificateInfo(data, key, index) {
    return (
      <Fragment>
        <tr key={data} className="ncss-col-sm-12 padding-0 vertical-top mt-6">
          <td className="ncss-col-sm-5 u-bold padding-0 vertical-top">
            <div>
              {index === 0 ? key : ""}
            </div>
          </td>
          <tr className="ncss-col-sm-7 padding-0">
            <td className="ncss-col-sm-4 padding-0">
              {trfCertificationName(data.name) === 'Other' ? strUpperCase(data.name) : trfCertificationName(data.name)}
            </td>
            <td className="ncss-col-sm-2 padding-0 vertical-top u-bold" >
              Number
            </td>
            <td className="ncss-col-sm-2 padding-0">{data.number}
            </td>
          </tr></tr>
      </Fragment>
    )
  }

  render() {
    const { testDetailsByClass, testDetails, trfDetails, qrfDetails, frfDetails } = this.state
    const { isRslUser, isLabUser,testDetails: testDetailsProp } = this.props;
    const testdetailsPDF = isLabUser ? testDetailsProp : testDetails
    return (
      (<div className="test_details" >
        {trfDetails.map((obj, i) =>
          obj && obj.headerName ?
            obj.headerName === "Laboratory Information" || obj.headerName === "Sample Submitter Information" ?
              <div className={obj.headerName === "Laboratory Information" ? "ncss-col-sm-6 padding-0 prevent-split" : "ncss-col-sm-6 padding-right-submitter"}>
                <table key={obj.headerName} className="pdf_table mb4-sm">
                  <tr>
                    <th className='cs-n'>
                      <div className="th-cl-sum grey-background padding-3">
                        <span className='text-color-accent u-bold' colSpan={2}>{obj.headerName}</span>
                      </div>
                    </th>
                  </tr>
                  <tbody className='add-border-top'>
                    {Object.keys(obj).map((key, i) =>
                      i !== 0 && !Array.isArray(obj[key]) && obj.headerName === "Laboratory Information" ?
                        <tr key={key} className={i !== 1 ? "ncss-col-sm-6 padding-0 align-lab hg-lab" : "ncss-col-sm-6 align-lab hg-lab padding-0"}>
                          <td className={(key === "Lab Accept Date" || key === "Test Report Date") ? "ncss-col-sm-5 margin-lab  u-bold" : "ncss-col-sm-6 align-lab padding-0 u-bold"}>
                            {key !== "Lab Accept Date" ? key : isRslUser ? key : isLabUser ? key : ""}
                          </td>
                          {key !== "Lab Accept Date" ? this.returnLabSectionTable(key, obj[key])
                            : isRslUser ? this.returnLabSectionTable(key, obj[key]) : isLabUser ? this.returnLabSectionTable(key, obj[key]) : ""}

                        </tr>
                        :
                        i !== 0 &&
                        <tr key={key} className={i !== 1 ? "ncss-col-sm-12 padding-0 hg-lab" : "ncss-col-sm-12 padding-0 hg-lab"}>
                          <td className="ncss-col-sm-4 padding-0  u-bold">
                            {key}
                          </td>
                          <td className="ncss-col-sm-8 padding-0 ">
                            {obj[key]}
                          </td>
                        </tr>

                    )}
                  </tbody>
                </table>
              </div>
              :
              <table key={obj.headerName} className={obj.headerName === 'Material Information' ?
                "pdf_table_testResultbyclass add-border mb4-sm" : "pdf_table mb4-sm"} style={{ width: '100%' }} >
                {obj.headerName === 'Summary' ?
                  <thead>
                    <tr>
                      <th className='cs-n'>
                        <div className="th-cl-sum grey-background padding-3">
                          <span className='text-color-accent u-bold' colSpan={2}>{obj.headerName}</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  :
                  <thead>
                    <tr>
                      <th className='cs-n'>
                        <div className="th-cl-sum grey-background padding-3">
                          <span className='text-color-accent u-bold' colSpan={2}>{obj.headerName}</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                }
                <tbody className='add-border-top'>
                  {obj.headerName !== "Laboratory Information" &&
                    Object.keys(obj).map((key, i) =>
                      i !== 0 && !Array.isArray(obj[key]) ?
                        <tr key={key} className={i !== 1 ? "ncss-col-sm-12 padding-0 mt-9" : "ncss-col-sm-12 padding-0"}>
                          {key !== "Expiry" && (
                            <Fragment>
                              <td className={i === 1 ? "ncss-col-sm-5 padding-0 vertical-top u-bold mt-1" : "ncss-col-sm-5 padding-0 u-bold"}>
                                {key === 'RSL Submit Number' ? 'Test Report Number' : key}
                              </td>
                              {/* Except Material Types Key */}
                              <td className={i === 1 ? "ncss-col-sm-7 padding-0 vertical-top" : "ncss-col-sm-7 padding-0"}>
                                <span className='mt-9'>
                                  {key === "Brand Tested For" ?
                                    obj[key]
                                    :
                                    key === "TRF Submit Date" && obj[key] ?
                                      this.formatDate(obj[key])
                                      :
                                      obj[key]
                                  }</span>
                              </td></Fragment>
                          )}
                        </tr>
                        :
                        i !== 0 && Array.isArray(obj[key]) ?
                          obj[key].length ?
                            obj[key].map((data, index) => key !== "Test Packages" && key !== "Certificate" ?
                              <tr key={data} className={i !== 1 ? "ncss-col-sm-12 padding-0 vertical-top mt-6" : "ncss-col-sm-12 padding-0 vertical-top mt-6"}>
                                <td className={key === 'Non-Compliances' ? "ncss-col-sm-2 u-bold padding-0 vertical-top" : "ncss-col-sm-5 u-bold padding-0 vertical-top"}>
                                  <div>
                                    {index === 0 ? key : ""}
                                  </div>
                                </td>
                                {key === "Material Types"
                                  ? (/* Material Types section start here */
                                    (<td className="ncss-col-sm-7 padding-0 vertical-top" style={{ marginLeft: '50px !important' }}>{data}</td>))
                                  : <Fragment>
                                  <tr className="ncss-col-sm-5">
                                <td className="ncss-col-sm-10" style={{padding: '0px',  borderCollapse: 'collapse'
                              }}>
                                <div >
                                {data.columnOne !== "" && <pre className="ws-n">{"\u2022"}{data.columnOne} </pre>}
                                </div>
                                  </td>
                                </tr>
                                <tr className="ncss-col-sm-5">
                                    <td className="ncss-col-sm-10" 
                                    style={{padding: '0px', borderCollapse: 'collapse',display: 'block'}} >
                                    <div >
                                     {data.columnTwo !== "" && <pre className="ws-n">{"\u2022"}{data.columnTwo} </pre>}
                                    </div>
                                        </td>
                                </tr>
                                </Fragment>
                                }
                              </tr>
                              : key === "Certificate" ? this.certificateInfo(data, key, index)
                                : index === 0 && this.createTestPackage(obj["Test Packages"], key)
                            )
                            :
                            <tr className="ncss-col-sm-12 padding-0">
                              <td className="ncss-col-sm-4 padding-0  " >{key}</td>
                              <td className="ncss-col-sm-8 padding-0  ">{'None'}</td>
                            </tr>
                          :
                          null
                    )}
                </tbody>
              </table>
            :
            null
        )}
        <div className="prevent-split" >
          <table className="pdf_table_testResultbyclass mb4-sm">
            <thead>
              <tr>
                <th className='cs-n'>
                  <div className="th-cl-sum grey-background padding-3">
                    <span className='text-color-accent u-bold' colSpan={8}>Test Results By Class</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="ncss-col-sm-12 padding-0 add-border-top">
              {this.getTestClassWithPackage(testDetailsByClass)}
            </tbody>
          </table>
        </div>
        <div className="prevent-split" >
          <div className="page-break" />
          <table className="pdf_table_chemicalreport">
            <thead>
              <tr>
                <th className='cs-n' colSpan={18}>
                  <div className="th-cl-sum grey-background padding-3">
                    <span className='text-color-accent u-bold'>Test Results By Chemical</span>
                  </div>
                </th>
              </tr>
              <tr>
                <th className='chname-width cs-n-tstpck' colSpan={8}>
                  <div className="u-bold th-cl-sum bg-color-blue">
                    <span className='padding-3'>Chemical Name</span>
                  </div>
                </th>
                <th className='lab-casNo-niklimit-result-pafa-width cs-n-tstpck' colSpan={2}>
                  <div className=" u-bold th-cl-sum bg-color-blue">
                    <span className='padding-3'>CAS No</span>
                  </div>
                </th>
                <th className='lab-casNo-niklimit-result-pafa-width cs-n-tstpck' colSpan={2}>
                  <div className="u-bold th-cl-sum bg-color-blue">
                    <span className='padding-3'>Lab Limit</span>
                  </div>
                </th>
                <th className='lab-casNo-niklimit-result-pafa-width cs-n-tstpck' colSpan={2}>
                  <div className="u-bold th-cl-sum bg-color-blue">
                    <span className='padding-3'>Nike Limit</span>
                  </div>
                </th>
                <th className='lab-casNo-niklimit-result-pafa-width cs-n-tstpck' colSpan={2}>
                  <div className="u-bold th-cl-sum bg-color-blue">
                    <span className='padding-3'>Result/UOM</span>
                  </div>
                </th>
                <th className='lab-casNo-niklimit-result-pafa-width cs-n-tstpck' colSpan={2}>
                  <div className="u-bold th-cl-sum bg-color-blue">
                    <span className='padding-3'>Pass/Fail</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {!isEmpty(testdetailsPDF) && testdetailsPDF.map((obj, i) =>
                <React.Fragment key={obj.id}>
                  {i === 0 ?<>
                    <tr key={obj.id}>
                      <th key={obj.id} className='cs-n-tstpck' colSpan={8}>
                        <div className="u-bold th-cl-sum grey-background_chth">
                          <span className='padding-3'>{this.getClassName(obj.class)}</span>
                        </div>
                      </th>
                      <th className='cs-n-tstpck' colSpan={2}>
                        <div className="u-bold th-cl-sum grey-background_chth">
                          <span className='padding-3'></span>
                        </div>
                      </th>
                      <th className='cs-n-tstpck' colSpan={2}>
                        <div className="u-bold th-cl-sum grey-background_chth">
                          <span className='padding-3'></span>
                        </div>
                      </th>
                      <th className='cs-n-tstpck' colSpan={2}>
                        <div className="u-bold th-cl-sum grey-background_chth">
                          <span className='padding-3'></span>
                        </div>
                      </th>
                      <th className='cs-n-tstpck' colSpan={2}>
                        <div className="u-bold th-cl-sum grey-background_chth">
                          <span className='padding-3'></span>
                        </div>
                      </th>
                      <th className='cs-n-tstpck' colSpan={2}>
                        <div className="u-bold th-cl-sum grey-background_chth">
                          <span className='padding-3'></span>
                        </div>
                      </th>
                    </tr>
                    {obj.groupName !== null &&
                    <tr key={obj.id} className="grey-background-groupsum">
                    <th key={obj.id} className='cs-n-tstpck border-t' colSpan={8}>
                          <div className="u-bold th-cl-sum  pd-l-3 pl-10">
                              <span>{obj.groupName}</span>
                          </div>
                    </th>
                    <th className='cs-n-tstpck u-bold th-cl-sum border-t' colSpan={2}>
                        <div>
                            <span className='padding-3'></span>
                        </div>
                    </th>
                    <th className='cs-n-tstpck u-bold th-cl-sum border-t' colSpan={2}>
                        <div>
                            <span className='padding-3'></span>
                        </div>
                    </th>
                    <th className='cs-n-tstpck u-bold th-cl-sum border-t' colSpan={2}>
                        <div>
                            <span className='padding-3'></span>
                        </div>
                    </th>
                    <th className='cs-n-tstpck u-bold th-cl-sum border-t' colSpan={2}>
                        <div>
                            <span className='padding-3'></span>
                        </div>
                    </th>
                    <th className='cs-n-tstpck u-bold th-cl-sum border-t' colSpan={2}>
                        <div>
                            <span className='padding-3'></span>
                        </div>
                    </th>
                  </tr>
                    }
                </>
                    :
                    null}
                  {i<= testDetails.length && i !== 0 && obj.class !== testDetails[i - 1].class ?
                    <tr key={obj.id}>
                      <th key={obj.id} className='cs-n-tstpck' colSpan={8}>
                        <div className="u-bold th-cl-sum grey-background_chth pd-l-3">
                          <span>{this.getClassName(obj.class)}</span>
                        </div>
                      </th>
                      <th className='cs-n-tstpck u-bold th-cl-sum grey-background_chth' colSpan={2}>
                        <div>
                          <span className='padding-3'></span>
                        </div>
                      </th>
                      <th className='cs-n-tstpck u-bold th-cl-sum grey-background_chth' colSpan={2}>
                        <div>
                          <span className='padding-3'></span>
                        </div>
                      </th>
                      <th className='cs-n-tstpck u-bold th-cl-sum grey-background_chth' colSpan={2}>
                        <div>
                          <span className='padding-3'></span>
                        </div>
                      </th>
                      <th className='cs-n-tstpck u-bold th-cl-sum grey-background_chth' colSpan={2}>
                        <div>
                          <span className='padding-3'></span>
                        </div>
                      </th>
                      <th className='cs-n-tstpck u-bold th-cl-sum grey-background_chth' colSpan={2}>
                        <div>
                          <span className='padding-3'></span>
                        </div>
                      </th>
                    </tr>
                    :
                    null}
                    {i <= testDetails.length && i !== 0 && obj.groupName !== testDetails[i - 1].groupName && obj.groupName !== null &&
                      <tr key={obj.id} className="grey-background-groupsum">
                          <th key={obj.id} className='cs-n-tstpck border-t' colSpan={8}>
                              <div className="u-bold th-cl-sum  pd-l-3 pl-10">
                                  <span>{obj.groupName}</span>
                              </div>
                          </th>
                          <th className='cs-n-tstpck u-bold th-cl-sum border-t' colSpan={2}>
                              <div>
                                  <span className='padding-3'></span>
                              </div>
                          </th>
                          <th className='cs-n-tstpck u-bold th-cl-sum border-t' colSpan={2}>
                              <div>
                                  <span className='padding-3'></span>
                              </div>
                          </th>
                          <th className='cs-n-tstpck u-bold th-cl-sum border-t' colSpan={2}>
                              <div>
                                  <span className='padding-3'></span>
                              </div>
                          </th>
                          <th className='cs-n-tstpck u-bold th-cl-sum border-t' colSpan={2}>
                              <div>
                                  <span className='padding-3'></span>
                              </div>
                          </th>
                          <th className='cs-n-tstpck u-bold th-cl-sum border-t' colSpan={2}>
                              <div>
                                  <span className='padding-3'></span>
                              </div>
                          </th>
                      </tr>
                    }
                  <tr className={obj.chemicalName.startsWith("Sum") === true ? "font-italic" : ""}>

                    <td className='cell_ch' colSpan={8}>{obj.chemicalName}</td>
                    <td className='cell_lab_o' colSpan={2}>{obj.casNo}</td>
                    <td className='cell_lab_o' colSpan={2}>{obj.labLimit}</td>
                    <td className='cell_lab_o' colSpan={2}>{obj.kidFail !== null ?
                      <p>{obj.kidFail} (kids), {obj.adultFail} (adults)</p> : <p>{obj.nikeLimit}</p>}
                    </td>
                    <td className='cell_lab_o' colSpan={2}>{this.processResult(obj.result, obj.class, obj.unit)}</td>
                    <td className='cell_lab_o' colSpan={2}>{obj.status}</td>
                  </tr>
                </React.Fragment>
              )}
            </tbody>
          </table>
        </div>
        {(qrfDetails?.qrfId) && !isLabUser ?
          <div className="ncss-col-sm-12 padding-0 prevent-split">
            <table key={qrfDetails.qrfId} className="pdf_table mb4-sm">
              <tr>
                <th className='cs-n'>
                  <div className="th-cl-sum grey-background padding-3">
                    <span className='text-color-accent u-bold' colSpan={2}>QRF Information</span>
                  </div>
                </th>
              </tr>
              <tbody className='add-border-top'>
                <tr key={`qrf-1`} className="ncss-col-sm-12 padding-0 hg-lab">
                  <td className="ncss-col-sm-6 padding-0  u-bold">
                    Has any of the affected material left the manufacturer's facility?
                  </td>
                  <td className="ncss-col-sm-6 padding-0 ">
                    {_.get(qrfDetails, 'leftFacility', 'Not Added')}
                  </td>
                </tr>
                <tr key={`qrf-1`} className="ncss-col-sm-12 padding-0 hg-lab">
                  <td className="ncss-col-sm-6 padding-0  u-bold">
                    Where is the affected material now?
                  </td>
                  <td className="ncss-col-sm-6 padding-0 ">
                    {_.get(qrfDetails, 'location', 'Not Added')}
                  </td>
                </tr>
                <tr key={`qrf-1`} className="ncss-col-sm-12 padding-0 hg-lab">
                  <td className="ncss-col-sm-6 padding-0  u-bold">
                    How much material is affected by this failure?
                  </td>
                  <td className="ncss-col-sm-6 padding-0 ">
                    {_.get(qrfDetails, 'quantity', 'Not Added')}
                  </td>
                </tr>
                <tr key={`qrf-1`} className="ncss-col-sm-12 padding-0 hg-lab">
                  <td className="ncss-col-sm-6 padding-0  u-bold">
                  How much is the material worth?
                  </td>
                  <td className="ncss-col-sm-6 padding-0 ">
                    {(typeof qrfDetails.materialWorth !== 'undefined' && qrfDetails.materialWorth !== null) ?
                     _.get(qrfDetails, 'materialWorth') : 'Not Added'}
                  </td>
                </tr>
                <tr key={`qrf-1`} className="ncss-col-sm-12 padding-0 hg-lab">
                  <td className="ncss-col-sm-6 padding-0  u-bold">
                    List all factory(s) that are expecting to receive this material:
                  </td>
                  <td className="ncss-col-sm-6 padding-0 ">
                    {_.get(qrfDetails, 'factories', 'Not Added')}
                  </td>
                </tr>
                <tr key={`qrf-1`} className="ncss-col-sm-12 padding-0 hg-lab">
                  <td className="ncss-col-sm-6 padding-0  u-bold">
                    Is this affected material used for Kid product?
                  </td>
                  <td className="ncss-col-sm-6 padding-0 ">
                    {_.get(qrfDetails, 'isAffectedKidProduct', 'Not Added')}
                  </td>
                </tr>
                <tr key={`qrf-1`} className="ncss-col-sm-12 padding-0 hg-lab">
                  <td className="ncss-col-sm-6 padding-0  u-bold">
                    Describe your immediate next steps:
                  </td>
                  <td className="ncss-col-sm-6 padding-0 ">
                    {_.get(qrfDetails, 'nextStep', 'Not Added')}
                  </td>
                </tr>
                <tr key={`qrf-1`} className="ncss-col-sm-12 padding-0 hg-lab">
                  <td className="ncss-col-sm-6 padding-0  u-bold">
                    Comments
                  </td>
                  <td className="ncss-col-sm-6 padding-0 ">
                    {_.get(qrfDetails, 'comment', 'Not Added')}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          : null}
        {(frfDetails?.frfId) && !isLabUser ?
          <div className="ncss-col-sm-12 padding-0 prevent-split">
            <table key={frfDetails?.frfId} className="pdf_table mb4-sm">
              <tr>
                <th className='cs-n'>
                  <div className="th-cl-sum grey-background padding-3">
                    <span className='text-color-accent u-bold' colSpan={2}>FRF Information</span>
                  </div>
                </th>
              </tr>
              <tbody className='add-border-top'>
                <tr key={`frf-1`} className="ncss-col-sm-12 padding-0 hg-lab">
                  <td className="ncss-col-sm-6 padding-0  u-bold">
                    Why is this chemical used in the manufacturing process? Conduct PDCA to identify root cause
                  </td>
                  <td className="ncss-col-sm-6 padding-0 ">
                    {_.get(frfDetails, 'PDCA', 'Not Added')}
                  </td>
                </tr>
                <tr key={`frf-1`} className="ncss-col-sm-12 padding-0 hg-lab">
                  <td className="ncss-col-sm-6 padding-0  u-bold">
                    Failure Description
                  </td>
                  <td className="ncss-col-sm-6 padding-0 ">
                    {_.get(frfDetails, 'failureDesc', 'Not Added')}
                  </td>
                </tr>
                <tr key={`frf-1`} className="ncss-col-sm-12 padding-0 hg-lab">
                  <td className="ncss-col-sm-6 padding-0  u-bold">
                    What is the root cause of this failure?
                  </td>
                  <td className="ncss-col-sm-6 padding-0 ">
                    {_.get(frfDetails, 'rootCause', 'Not Added')}
                  </td>
                </tr>
                <tr key={`frf-1`} className="ncss-col-sm-12 padding-0 hg-lab">
                  <td className="ncss-col-sm-6 padding-0  u-bold">
                    What are your proposed corrective actions?
                  </td>
                  <td className="ncss-col-sm-6 padding-0 ">
                    {_.get(frfDetails, 'correctiveAction', 'Not Added')}
                  </td>
                </tr>
                <tr key={`frf-1`} className="ncss-col-sm-12 padding-0 hg-lab">
                  <td className="ncss-col-sm-6 padding-0  u-bold">
                    What date will the corrective action be completed?
                  </td>
                  <td className="ncss-col-sm-6 padding-0 ">
                    {(typeof frfDetails.dateOfCompletion !== 'undefined' && frfDetails.dateOfCompletion !== null) ?
                      moment.utc(frfDetails.dateOfCompletion).format('DD-MMM-YYYY')
                      : (
                        'Not Added'
                      )}
                  </td>
                </tr>
                <tr key={`frf-1`} className="ncss-col-sm-12 padding-0 hg-lab">
                  <td className="ncss-col-sm-6 padding-0  u-bold">
                    Comments
                  </td>
                  <td className="ncss-col-sm-6 padding-0 ">
                    {_.get(frfDetails, 'comment', 'Not Added')}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          : null}
        <div className="page-break" />
        <div className="mt4-sm">
          <div className="text-color-accent u-bold">RSL VERSION</div>
          <p>This test was conducted according to the 2024 Nike RSL : https://rsltesting.nike.com</p>
        </div>
        <div className="mt4-sm">
          <div className="text-color-accent u-bold">DISCLAIMER</div>
          <div class="ncss-row">
            <div className="ncss-col-sm-6"></div>
            <div className="ncss-col-sm-3"></div>
            <div className="ncss-col-sm-3 txta-r"><i className="font-italic ">End of Report</i></div>
          </div>
          <div className="font-italic">
            <p className="txta-j">Any holder of this PDF document is advised that it was automatically generated on the Nike
              RSL testing website (www.rsltesting.nike.com) for information purposes only. This PDF does
              not constitute an official test report - the official test report is only available on the
              Nike RSL testing website. This document shall under no circumstances be construed as such or
              used instead of the official test report.</p><br />

            <p className="txta-j">Nike, Inc. is not responsible for verifying the information in the official test report.
              The official test report is prepared based on the sample information and physical samples
              provided to the third-party RSL testing lab directly by the sample submitter under the sole
              responsibility thereof. The results in this report are generated and entered by the RSL testing
              lab and the report is uploaded to the Nike RSL testing website by the lab itself without Nike`s
              intervention or control.</p><br />

            <p className="txta-j">Nike, Inc. cannot verify, confirm, or edit in any way the information in neither the PDF document
              nor the official test report. It cannot guarantee or make any representations or warranties of
              any kind as to the completeness or accuracy of these documents and shall accept no liability for
              any loss, liability, or damage of any nature resulting from its use.</p><br />

            <p className="txta-j">RSL testing labs use suitable test methods for sample preparation and measurement. More information
              about the suitable test methods used to obtain these results is available in the Nike, Inc. Chemistry
              Playbook and Restricted Substances List (RSL) at https://about.nike.com/pages/chemistry-restricted-substances-list.</p><br />

            <p className="txta-j">Whilst Nike, Inc. recommends suitable test methods for sample preparation and measurement in
              utmost good faith, it is the ultimate responsibility of the RSL testing labs to ensure that
              testing is made in line with the most current techniques and accurate state of the art. Nike
              shall not be held liable for any errors that may occur during testing, or when test results are
              uploaded to the Nike RSL testing website, nor shall it mediate related disagreements or disputes
              with the sample submitter and/or the finished good factory.</p>
          </div>
        </div>
        <style>{styles}</style>
        <script>

        </script>
      </div>)
    );

  }
}

export default TestDetailsPDF;