import find from 'lodash/find';
import union from 'lodash/union';

import individualTest from '../../../constants/json/checkboxes/individualTest.json';

const config = [
  {
    colId: 'testPackageId',
    field: 'testPackageId',
    headerName: 'Chemical Class',
    headerTooltip: 'Chemical Class',
    suppressHeaderMenuButton: true,
    resizable: true,
    width:400,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    valueFormatter: params => {
      if (params.value) {
        if (typeof params.value === 'string') {
          return params.value;
        } else {
          const chemicalClass =
          params.value &&
          find(
            union(
              individualTest[0].common,
              individualTest[0].toys,
              individualTest[0].electronic,
              individualTest[0].packaging,
              individualTest[0].jewelry
            ), [
            'id',
            params.value
          ]).label;
        return chemicalClass;
      }
      }
      return params.data.chemicalClass;
    },
    tooltip: (params) => {
      if (params.value) {
        if (typeof params.value === 'string') {
          return params.value;
        } else {
          const chemicalClass =
          params.value &&
          find(
            union(
              individualTest[0].common,
              individualTest[0].toys,
              individualTest[0].electronic,
              individualTest[0].packaging,
              individualTest[0].jewelry
            ), [
            'id',
            params.value
          ]).label;
        return chemicalClass;
      }
      }
      return params.data.chemicalClass;
    }

  },
  {
    colId: 'chemicalName',
    headerName: 'Chemical Name',
    headerTooltip: 'Chemical Name',
    field: 'chemicalName',
    width:350,
    tooltip: (params) => params.value,
    resizable: true,
    suppressHeaderMenuButton: true,
  },
  {
    colId: 'CAS',
    headerName: 'CAS Number',
    headerTooltip: 'CAS Number',
    field: 'CAS',
    suppressHeaderMenuButton: true,
    resizable: true,
  },
  {
    colId: 'labLimit',
    headerName: 'Required Laboratory Reporting Limit',
    headerTooltip: 'Required Laboratory Reporting Limit',
    field: 'labLimit',
    resizable: true,
    width:400,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    tooltip: (params) => params.value,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellStyle: { 'white-space': 'normal', 'font-weight': 'normal' },
  },
  {
    colId: 'nikeLimit',
    headerName: 'Nike Limit',
    headerTooltip: 'Nike Limit',
    field: 'nikeLimit',
    resizable: true,
    tooltip: (params) => params.value,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellStyle: {'font-weight': 'normal' },
  },
  {
    colId: 'testResultValue',
    headerName: 'Test Result',
    headerTooltip: 'Test Result',
    field: 'testResultValue',
    suppressHeaderMenuButton: true,
    editable: true,
    resizable: true,
  },
  {
    colId: 'unit',
    headerName: 'Unit',
    headerTooltip: 'Unit',
    field: 'unit',
    suppressHeaderMenuButton: true
  }
];

// Create a new config Array of objects without mutating the parent array of objects
export const resultsTableReadonlyConfig =  [ ...config ].map(obj => obj.colId === 'testResultValue' ? {...obj, editable:false } : obj );

export default config;
