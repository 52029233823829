import React from 'react';
import find from 'lodash/find';
import brandInfo from '../../../constants/json/checkboxes/brandInfo.json';
/**
 * CustomCheckBoxComponent is a React functional component that renders a checkbox input.
 * The checkbox is checked based on the `favExists` property of the `data` object in the `node` parameter.
 *
 * @param {Object} params - The parameters object.
 * @param {Object} params.node - The node object containing data.
 * @param {Object} params.node.data - The data object within the node.
 * @param {boolean} params.node.data.favExists - Determines if the checkbox should be checked by default.
 * @returns {JSX.Element} A checkbox input element.
 */
//import ReactDOMServer from 'react-dom/server';

function CustomCheckBoxComponent({ node, api }) {
  const activeStatus = node?.data?.favExists ??false;
    const [checked, setSwitchChecked] = React.useState(activeStatus);
  
    React.useEffect(() => {
      setSwitchChecked(activeStatus);
    }, [activeStatus]);
  
    const handleChange = () => {
      //setSwitchChecked(!checked);
      const newChecked = !checked;
      setSwitchChecked(newChecked);
    // Update the data in the grid or perform any additional logic here
    if (node && node.setDataValue) {
      node.setDataValue('favourites', newChecked);
      // Refresh the grid cells to reflect the updated data
      api.refreshCells({ rowNodes: [node] });
    }
    };
    return (
          <input type="checkbox" onChange={handleChange} checked={checked}/>
    );
}

function brandValueFormatter(params) {
  const brand = find(brandInfo, ['id', params.value]);
      return brand ? brand.value : ''; 
}
function ReviewTRF({data}) {
  if (data.action === 'Review' && data.userLabLocationId === data.labLocationId) {
    return <div class="test_button"><i class="fas fa-clipboard-check"></i> Review TRF</div>;
  }
}
function AcceptTRF({data}) {
  if (data.action === 'Review' && data.userLabLocationId === data.labLocationId) {
    return <div class="test_button_proceed"><i class="fas fa-flask"></i> Accept TRF - Begin Testing</div>;
  }
}

function dateConversion(date) {
  var dateAsString1 = date;
  let convertedDate1 = '';
  const isIE = /*@cc_on!@*/false || !!document.documentMode;
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if(dateAsString1 !== null){
  var dateParts = dateAsString1.split("-");
  let months1 = { Jan: "01", Feb: "02", Mar: "03", Apr: "04", May: "05", Jun: "06", Jul: "07", Aug: "08", Sep: "09", Oct: "10", Nov: "11", Dec: "12" };
  convertedDate1 = Number(dateParts[2]) + '-' + Number(months1[dateParts[1]]) + '-' + Number(dateParts[0]) + ' 00:00:00'

  var cellDate1 = new Date(convertedDate1);
  if (isIE || isSafari) {
    return new Date(Number(dateParts[2]), Number(months1[dateParts[1]] - 1), Number(dateParts[0]), 0, 0, 0)

  }
  return cellDate1;
}

}

const equalsFilterParams = {
  filterOptions: [
    "equals",
    {
      displayKey: "inRange",
      displayName: "In Range",
      predicate: ([fv1, fv2], cellValue) => {
        if (cellValue == null) return true;
        var cellDate = dateConversion(cellValue);
        return (
          cellDate.getTime() > fv1.getTime() &&
          cellDate.getTime() < fv2.getTime()
        );
      },
      numberOfInputs: 2,
    },
  ],
  inRangeInclusive: true,
  comparator: function (filterLocal, cellValue) {
    /* Seems LabAcceptDate has null value, so we are checking null condition & passing only the not null values  */
    if(cellValue !== null){
    var cellDate = dateConversion(cellValue);
    if (filterLocal) {
      if (filterLocal.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocal) {
        return -1;
      }
      if (cellDate > filterLocal) {
        return 1;
      }
    }
  }
},

};


const config = [
  {
    colId: 'rsl',
    headerName: 'TRF No.',
    field: 'rsl',
    suppressColumnsToolPanel: true,
    toolPanelClass: "ag-hide-toolpanel",
    pinned: 'left',
    suppressSizeToFit: true,
    width: 135,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      defaultOption: 'contains',
      maxNumConditions : 1,
      caseSensitive: true
    }
  },
  {
    colId: 'supplierName',
    headerName: 'Supplier Name',
    field: 'supplierName',
    suppressSizeToFit: true,
    width: 270,
    resizable: false,
    tooltipField: 'supplierName',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      defaultOption: 'contains',
      maxNumConditions: 1,
      caseSensitive: false
    },
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    enableRowGroup: true,
    enableValue: true,
  },
  {
    colId: 'supLocName',
    headerName: 'Supplier Location',
    field: 'supLocName',
    suppressSizeToFit: true,
    width: 270,
    resizable: false,
    tooltipField: 'supLocName',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      defaultOption: 'contains',
      maxNumConditions: 1,
      caseSensitive: false
    },
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    hide: true,
    enableRowGroup: true,
    enableValue: true,

  },
  {
    colId: 'matTypeName',
    headerName: 'Material Type',
    field: 'matTypeName',
    tooltipField:'matTypeName',
    suppressSizeToFit: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      defaultOption: 'contains',
      maxNumConditions: 1,
      caseSensitive: false,

    },
    width: 270,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    hide: true,
    enableRowGroup: true,
    enableValue: true,
  },
  {
    colId: 'materialName',
    headerName: 'Material Name',
    field: 'materialName',
    tooltipField: 'materialName',
    suppressSizeToFit: true,
    width: 220,
   // suppressFilter: false,
    floatingFilter: true,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      defaultOption: 'contains',
      maxNumConditions: 1,
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    enableRowGroup: true,
    enableValue: true,
  },
  {
    colId: 'otherMaterial',
    headerName: 'Material Number',
    field: 'otherMaterial',
    suppressSizeToFit: true,
    width: 200,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
   // suppressFilter: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      defaultOption: 'contains',
      maxNumConditions: 1,
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    hide: true,
    enableRowGroup: true,
    enableValue: true,
  },
  {
    colId: 'materialNumber',
    headerName: 'PCX Number',
    field: 'materialNumber',
    suppressSizeToFit: true,
    width: 200,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
   // suppressFilter: false,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      defaultOption: 'contains',
      maxNumConditions: 1,
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    enableRowGroup: true,
    enableValue: true,
  },
  {
    colId: 'refId',
    headerName: 'Material ID',
    field: 'refId',
    suppressSizeToFit: true,
    width: 200,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
   // suppressFilter: false,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      defaultOption: 'contains',
      maxNumConditions: 1,
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    enableRowGroup: true,
    enableValue: true,
  },
  {
    colId: 'PDMNumber',
    headerName: 'PDM Number',
    field: 'PDMNumber',
    suppressSizeToFit: true,
    width: 200,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
   // suppressFilter: false,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      defaultOption: 'contains',
      maxNumConditions: 1,
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    hide: true,
    enableRowGroup: true,
    enableValue: true,
  },
  {
    colId: 'brandInfo',
    headerName: 'Brand Tested For',
    field: 'brandInfo',
    floatingFilter: true,
    filter: 'agSetColumnFilter',
    suppressSizeToFit: true,
    width: 150,
    keyCreator:  brandValueFormatter,
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false,
      textCustomComparator: function (filter, value, filterText) {
        var filterTextLoweCase = filterText.toLowerCase();
        var valueLowerCase = value.toString().toLowerCase();
        function contains(target, lookingFor) {
          if (target === null) return false;
          return ((find(brandInfo, ['id', parseInt(target)]).value).toLowerCase()).indexOf(lookingFor.toLowerCase()) >= 0;
        }
        var literalMatch = contains(valueLowerCase, filterTextLoweCase);
        return literalMatch;
      },
      
    valueFormatter: params => {
      const brand = find(brandInfo, ['id', params.value]);
      return brand ? brand.value : ''; // Format the object data into a string
    },
    },
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    cellRenderer: params => {
      if (find(brandInfo, ['id', params.value])) {
        return <i className={find(brandInfo, ['id', params.value]).icon} />;
      }
      return '';
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    enableRowGroup: true,
    enableValue: true,
  },
  {
    colId: 'prodTypeName',
    headerName: 'Product Type',
    field: 'prodTypeName',
    filter: 'agSetColumnFilter',
    suppressSizeToFit: true,
    width: 150,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    hide: true,
    enableRowGroup: true,
    enableValue: true,
  },
  {
    colId: 'labLocName',
    headerName: 'Lab ',
    field: 'labLocName',
    suppressSizeToFit: true,
    width: 200,

    filter: 'agSetColumnFilter',
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    hide: true,
    enableRowGroup: true,
    enableValue: true,
  },
  {
    colId: 'defaultTest',
    headerName: 'Default Test',
    field: 'defaultTest',
    suppressSizeToFit: true,
    width: 170,
    //suppressFilter: false,
    suppressHeaderMenuButton: true,
    suppressMovable: true, 
    floatingFilter: true,
    filter: 'agSetColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    enableRowGroup: true,
    enableValue: true,
  },
  {
    colId: 'testingType',
    headerName: 'Testing Type',
    field: 'testingType',
    suppressSizeToFit: true,
    width: 170,
    enablePivot: false,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    floatingFilter: true,
    filter: 'agSetColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    enableRowGroup: true,
    enableValue: true,
  },
  {
    colId: 'overAllStatus',
    headerName: 'Overall Test Result',
    field: 'overAllStatus',
    pinned: 'left',
    suppressSizeToFit: true,
    suppressColumnsToolPanel: true,
    toolPanelClass: "ag-hide-toolpanel",
    width: 170,
    //suppressFilter: false,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    floatingFilter: true,
    filter: 'agSetColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    cellStyle: params => {
      if (params.value && (params.value === 'Pass')) {
        return { color: '#87e300' };
      }
      else if (params.value && (params.value === 'Fail' || params.value === 'Kid Fail')) {
        return { color: '#ea723f' };
      }
      return { color: '#3498db' };
    }
  },
  {
    colId: 'labAccept',
    headerName: 'Lab Accept Date',
    field: 'labAccept',
    tooltipField: 'labAccept',
    width: 200,
    resizable: false,
    suppressSizeToFit: true,
   // suppressFilter: false,
    floatingFilter: true,
    filter: "agDateColumnFilter",
    comparator: function (date1, date2) {
      var cellDate1 = dateConversion(date1);
      var cellDate2 = dateConversion(date2);
      
      if (cellDate1 === null && cellDate2 === null) {
        return 0;
      }
      if (cellDate1 === null) {
        return -1;
      }
      if (cellDate2 === null) {
        return 1;
      }
      return cellDate1 - cellDate2;

    },
    filterParams: equalsFilterParams,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    hide: true,
    enableRowGroup: true,
    enableValue: true,
  },
  {
    colId: 'labReceivedDate',
    headerName: 'Test Report Date',
    field: 'labReceivedDate',
    tooltipField: 'labReceivedDate',
    width: 200,
    resizable: false,
    suppressSizeToFit: true,
    //suppressFilter: false,
    floatingFilter: true,
    filter: "agDateColumnFilter",
    comparator: function (date1, date2) {
      var cellDate1 = dateConversion(date1);
      var cellDate2 = dateConversion(date2);

      if (cellDate1 === null && cellDate2 === null) {
        return 0;
      }
      if (cellDate1 === null) {
        return -1;
      }
      if (cellDate2 === null) {
        return 1;
      }
      return cellDate1 - cellDate2;

    },
    filterParams: equalsFilterParams,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    enableRowGroup: true,
    enableValue: true,
  },
  {
    colId: 'action',
    headerName: 'Status',
    field: 'action',
    pinned: 'right',
    suppressColumnsToolPanel: true,
    toolPanelClass: "ag-hide-toolpanel",
    tooltipField: 'action',
    width: 160,
    resizable: false,
    suppressSizeToFit: true,
    floatingFilter: true,
    filter: 'agSetColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      caseSensitive: false
    },
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    }
  },
  {
    colId: 'labRequested',
    headerName: 'Lab Service Requested',
    field: 'labRequested',
    suppressSizeToFit: true,
    width: 150,
   // suppressFilter: false,
    suppressHeaderMenuButton: true,
    filter: 'agSetColumnFilter',
    suppressMovable: true,
    icons: {
      sortAscending: '<i class="fas fa-caret-up"></i>',
      sortDescending: '<i class="fas fa-caret-down"></i>'
    },
    hide: true,
    enableRowGroup: true,
    enableValue: true,
  },
 

  {
    headerName: "Actions",
    field: "reviewAction",
    colId: "reviewAction",
    pinned: 'right',
    suppressColumnsToolPanel: true,
    toolPanelClass: "ag-hide-toolpanel",
    suppressSizeToFit: true,
    width: 100,
   // suppressFilter: true,
    suppressHeaderMenuButton: true,
    tooltipField: 'reviewAction',
    suppressMovable: true,
    cellRenderer: params => {
      return <ReviewTRF {...params}/>
    },
    valueFormatter: params => {
      return params.value ? params.value.toString() : ''; // Format the object data into a string
    }
  },
  {
    headerName: " ",
    field: "proceedAction",
    colId: "proceedAction",
    pinned: 'right',
    suppressColumnsToolPanel: true,
    toolPanelClass: "ag-hide-toolpanel",
    suppressSizeToFit: true,
    width: 180,
   // suppressFilter: true,
    suppressHeaderMenuButton: true,
    tooltipField: 'proceedAction',
    suppressMovable: true,
    cellRenderer: params => {
      return <AcceptTRF {...params}/>
    },
    valueFormatter: params => {
      return params.value ? params.value.toString() : ''; // Format the object data into a string
    }
  },
  {
    headerName: "Favorites",
    field: "favExists",
    colId: "favourites",
    pinned: 'right',
    suppressColumnsToolPanel: true,
    suppressSizeToFit: true,
    width: 100,
    toolPanelClass: "ag-hide-toolpanel",
    suppressHeaderMenuButton: true,
    tooltipField: 'favourites',
    suppressMovable: true,
    cellRenderer: params => {
      return <CustomCheckBoxComponent {...params} />;
    },
  }
  // {
  //   headerName: "Favorites",
  //   field: "favExists",
  //   colId: "favExists",
  //   pinned: 'right',
  //   suppressColumnsToolPanel: true,
  //   suppressSizeToFit: true,
  //   width: 100,
  //   toolPanelClass: "ag-hide-toolpanel",
  //   suppressHeaderMenuButton: true,
  //   tooltipField: 'favourites',
  //   suppressMovable: true,
  //   cellRenderer: params => {
  //     return <CustomCheckBoxComponent {...params} />;
  //   }
  // }
];
export default config;
