import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { createServiceAction } from '../../../util/actionHelper.js';
import PropTypes from 'prop-types';
import { H4, ConfirmationBox } from '../../../components/index';
import Label from '../../../components/ncss/Label';

class Frf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitModal: false,
      fileName: ''
    };
    this.openConfirmationPopup = this.openConfirmationPopup.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
  }

  openConfirmationPopup = (fileName)=> {
    this.setState({submitModal: true, fileName: fileName});
  }

  downloadFile = (requestParams) =>{
    const {downloadAttachment} = this.props;
    downloadAttachment({ ...requestParams, formType:'frf'});
  }

  closeSubmitModal = () => {
    this.setState({submitModal: false});
  };

  render() {
  const {
    frfDetails,
    isDownload, 
    showTitle, 
    attachment
  } = this.props;
  const { fileName, submitModal } = this.state;
  return (
    (<div className={isDownload ? 'p9-sm' : 'p2-sm'}>
      <span className="html2pdf__page-break" />
      {showTitle && (
         <H4 className="text-color-accent u-bold page-title-has-back sub-heading">FRF Information</H4>)
      }
      <div>
        <div className="ncss-row">
          <Label className="ncss-col-sm-6 pb3-sm u-bold description">
            Why is this 
            chemical used in 
            the manufacturing process? <br />
            Conduct PDCA to 
            identify root cause
          </Label>
          <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
            {typeof frfDetails.PDCA !== 'undefined' ? <span>{frfDetails.PDCA}</span> : 'Not Added'}
          </Label>
        </div>
        <div className="ncss-row">
          <Label className="ncss-col-sm-6 pb3-sm u-bold description">Failure Description</Label>
          <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
            {typeof frfDetails.failureDesc !== 'undefined' ? (
              <span>{frfDetails.failureDesc}</span>
            ) : (
              'Not Added'
            )}
          </Label>
        </div>
        <div className="ncss-row">
          <Label className="ncss-col-sm-6 pb3-sm u-bold description">
            What is the root cause of this failure?
          </Label>
          <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
            {typeof frfDetails.rootCause !== 'undefined' ? (
              <span>{frfDetails.rootCause}</span>
            ) : (
              'Not Added'
            )}
          </Label>
        </div>
        <div className="ncss-row">
          <Label className="ncss-col-sm-6 pb3-sm u-bold description">
            What are your proposed corrective actions?
          </Label>
          <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
            {typeof frfDetails.correctiveAction !== 'undefined' ? (
              <span>{frfDetails.correctiveAction}</span>
            ) : (
              'Not Added'
            )}
          </Label>
        </div>
        <div className="ncss-row">
          <Label className="ncss-col-sm-6 pb3-sm u-bold description">
            What date will the corrective action be completed?
          </Label>
          <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
            {(typeof frfDetails.dateOfCompletion !== 'undefined' && frfDetails.dateOfCompletion !== null) ? (
              // <span>{convertDate(frfDetails.dateOfCompletion)}</span>
              (<span>{moment.utc(frfDetails.dateOfCompletion).format('DD-MMM-YYYY')}</span>)
            ) : (
              'Not Added'
            )}
          </Label>
        </div>
        <div className="ncss-row">
            <Label className="ncss-col-sm-6 pb3-sm u-bold attachment-sets description">
             Attachment
            </Label>
            <Label className="ncss-col-sm-6 text-color-primary-dark u-bold page-title-has-back p2-left-sm">
              {attachment && attachment.length > 0 ? <div>{attachment.map(data =>( 
                <div>
                  <span>{data.fileName}</span>
                  <div  className="inline-flex-display download_button" title="Download file" onClick={()=>{this.openConfirmationPopup(data.fileName)} }>
                    <i className="fa fa-download"></i>
                  </div>
                </div>))}
              </div> : 'Not Added'}
            </Label>
        </div>
        <ConfirmationBox
    message={
        'Do you want to download the ' + fileName + '?'
    }
    submitModal={submitModal}
    submitValue={
       () => {
        this.downloadFile({id: frfDetails.frfId, fileName: fileName});
          }
    }
    closeModal={this.closeSubmitModal}
  />
      </div>
    </div>)
  );
    }
  };

Frf.defaultProps = {
  showAttachement: true
  };
Frf.propTypes = {
    frfDetails: PropTypes.object.isRequired,
    isDownload: PropTypes.bool,
    showTitle: PropTypes.bool,
    showAttachement: PropTypes.bool,
    attachment: PropTypes.array,
    downloadAttachment: PropTypes.func,
    
  };
function mapStateToProps(state) {
  return {

  };
}
  
const mapDispatchToProps = dispatch => ({
  downloadAttachment: data => {
    dispatch(createServiceAction('downloadAttachment', 'requested')(data));
  }
});

export default (connect(mapStateToProps, mapDispatchToProps)(Frf));
