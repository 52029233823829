import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { H4, Container, Button } from '../../components/index.js';
import Grid from '../../components/grid/Grid.js';
import LabInputs from '../trf_review/labInputs/LabInputs.js';
import testDetailConfig from '../../configs/gridConfigs/labResults/testDetailConfig.js';
import { selectTestDetailData } from '../../reducers/labReducer.js';
import { createServiceAction } from '../../util/actionHelper.js';
import {isLabUser } from '../../reducers/authReducer.js';
import { PDFExport } from '@progress/kendo-react-pdf';
import PageTemplate from "../test_details/pageTemplate.js";
import TestDetailsPDF from "../test_details/testDetailsPdf.js";
import { getTestDetailsByClassLab } from '../../reducers/labReducer.js';

class TestCompleted extends Component {

  componentDidMount() {
    const { getTrfDetails, getTestDetails, location } = this.props;
    const info = { trfId: location.state && location.state.trfId, pageCount: 1000 }
    // Get Trf details
    getTrfDetails(location.state && location.state.trfId);
    // Get Test details
    getTestDetails(info);
  }
  exportLabPDF = () => {
    const { trfDetail } = this.props;
    sessionStorage.setItem("reportId", trfDetail.data.trfDetails.RSLNo);
    this.testLabCompletedPdf.save();
  }
  ExtendedPageTemplate = props => (
    <PageTemplate header="Nike RSL Test Report" isLabUser="true" {...props}  />
  );

  render() {
    const { testDetailData, trfDetail, isLabUser, testDetailsByClass} = this.props;
    return (
      <div>
        <div section className="prevent-scroll top-scroll-fix" style={{ marginTop: '46px' }}>
          <H4 className="text-color-accent u-bold page-title-has-back sub-heading p2-bottom-sm">
            LAB - UPLOADED RESULTS
          </H4>
          {trfDetail && 
            <div className="ncss-col-sm-6 download-print-section">
              {!isEmpty(testDetailData) ?
                <span>  
                  <i className="fa fas fa-download" />
                  <Button onClick={this.exportLabPDF} className="download-print-button text-color-accent">
                    Nike RSL Test Report
                  </Button>
                </span>
                :
                <span>
                  <span className="text-color-accent">Loading Reports</span>
                  <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

                </span>
              }
            </div>
          }
        </div>
        <div className="dashboard lab-complete" data-testid="container-component">
          <Container section className="p0-top-sm m0-sm lab">
            <div className="ncss-row">
              <div className="ncss-col-sm-12" data-testid="grid-component">
                <Grid
                  columnDefs={testDetailConfig}
                  rowData={testDetailData}
                  showFilter={false}
                />
              </div>
            </div>
          </Container>
          <div className="ncss-row p2-bottom-sm"></div>
          <div className="ncss-row p2-bottom-sm"></div>
          {!isEmpty(trfDetail) && <LabInputs trfDetail={trfDetail} isLabUser={isLabUser} />}
        </div>
        {trfDetail && trfDetail.data && !isEmpty(testDetailData) && !isEmpty(testDetailsByClass) &&
          <div className="testDetailsPDF">
            <span>

              <PDFExport paperSize="A4"
                pageTemplate={this.ExtendedPageTemplate}
                scale={0.8}
                margin={{ top: 50, left: 20, right: 20, bottom: 50 }}
                multiPage
                forcePageBreak=".page-break"
                fileName={`Nike RSLReport - ${trfDetail.data.trfDetails.RSLNo}.pdf`}
                repeatHeaders
                title=""
                subject=""
                keywords=""
                landscape="true"
                keepTogether=".prevent-split"
                ref={(r) => this.testLabCompletedPdf = r}>
                <div className="ncss-col-sm-12">
                {!isEmpty(trfDetail) && 
                  <TestDetailsPDF trfDetails={trfDetail} isLabUser={isLabUser} testDetails={testDetailData}  testDetailsByClass={testDetailsByClass}/>
                }
                </div>
              </PDFExport>
            </span>

          </div>
        }
      </div>
    );
  }
}

TestCompleted.propTypes = {
  testDetailData: PropTypes.object,
  trfDetail: PropTypes.object,
  getTrfDetails: PropTypes.func,
  getTestDetails: PropTypes.func
};

function mapStateToProps(state) {
  return {
    testDetailData: selectTestDetailData(state),
    trfDetail: state.lab.trfDetail,
    isLabUser: isLabUser(state),
    testDetailsByClass: getTestDetailsByClassLab(state),

  };
}

const mapDispatchToProps = dispatch => ({
  getTrfDetails: data => {
    dispatch(createServiceAction('trfDetailsForLab', 'requested')(data));
  },
  getTestDetails: data => {
    dispatch(createServiceAction('testDetailsForLab', 'requested')(data));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TestCompleted)
);

