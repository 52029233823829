/* eslint-disable max-statements */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getFormValues } from 'redux-form';
import PropTypes from 'prop-types';
import union from 'lodash/union';
import includes from 'lodash/includes';
import get from 'lodash/get';
import find from 'lodash/find';
import filter from 'lodash/filter';
import moment from 'moment';

import { H4, Button, ProgressTracker, Container, ConfirmationBox, Spinner } from '../../components/index.js';
import { createServiceAction } from '../../util/actionHelper.js';
import { flattenItems } from '../../util/flattenItems.js';
import { displaySubmitButtonTrf, displayClearButtonTrf, updateMaterialField, clearFormValueTRF,initialCertificationArry, recycledContentValue } from '../../actions/trfActions';
import { selectUserDetails, isLabUser, isRslUser, isFactoryUser, isNloUser } from '../../reducers/authReducer.js';
import location from '../../constants/json/radiobuttons/location.json';
import individualTest from '../../constants/json/checkboxes/individualTestPackages.json';
import MaterialTypes from '../../constants/json/radiobuttons/materialTypes.json';

import BrandInfo from './brandInfo/BrandInfo';
import TestingInfo from './testingInfo/TestingInfo';
import LabLocation from './labLocation/LabLocation';
import Review from './review/Review';
import { convertToRSLNumber } from '../../util/index.js';

function getTestPackage(testPackages, selectedTestPackages) {
  testPackages.sort(sortDynamic('label'));
  testPackages = modifyData(testPackages);
  const testPackageArray = [], actualTestPackages = [];
  if (selectedTestPackages && selectedTestPackages.length > 0) {
    selectedTestPackages.filter(element => element != null).map(testPackage => {

      if (filter(union(individualTest[0].common, individualTest[0].toys, individualTest[0].electronic, individualTest[0].packaging, individualTest[0].jewelry), { variants: [{ id: testPackage }] }).length >= 1) {
        actualTestPackages.push(filter(union(individualTest[0].common, individualTest[0].toys, individualTest[0].electronic, individualTest[0].packaging, individualTest[0].jewelry), { variants: [{ id: testPackage }] })[0].id)
      } else {
        let packageTest = find(flattenItems(union(individualTest[0].common, individualTest[0].toys, individualTest[0].electronic, individualTest[0].packaging, individualTest[0].jewelry), 'variants'), ['id', testPackage]);
        if (packageTest && packageTest.id) {
          actualTestPackages.push(packageTest.id)
        }
      }
      return actualTestPackages;
    }
    )
  }
  if (testPackages) {
    testPackages.map((data, index) => {
      if (actualTestPackages.indexOf(data.id) > -1) {
        return testPackageArray[index] = data.id;
      }
      else {
        return testPackageArray[index] = null;
      }

    })
    return testPackageArray;
  }
  return []
}
//Edit form to bind the selected Material Types
function getMaterialTypes(materialTypes, selectedMaterialTypes) {
  const materialTypeArray = [], materials = [];
  if (selectedMaterialTypes) {
    selectedMaterialTypes.filter(element => element != null).map(material => {
      if (filter(materialTypes, { variants: [{ id: material }]}).length >= 1) {
        materials.push(filter(materialTypes, { variants: [{ id: material }] })[0].id)
      } else if(find(flattenItems(materialTypes, 'variants'), ['id', material])) {
        materials.push(find(flattenItems(materialTypes, 'variants'), ['id', material]).id)
      }
      else if (material === 4051 || material === 4050 || material === 4040){
        materials.push(405)
      }
      return materials;
    }
    )
  }
  if (materialTypes) {
    materialTypes.filter(data => data.isTestAvailable === true).map((data, index) => {
      if (materials.indexOf(data.id) > -1) {
        return materialTypeArray[index] = data.id;
      }
      else {
        return materialTypeArray[index] = null;
      }
    })
    return materialTypeArray;
  }
  return []
}

class Trf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitModal: false,
      status: null,
      stepState: 0,
      prevProductId:0,
      tabStatus:false,
    };
    this.hidden = {
      display: 'none'
    };
    this.filterTrfData = this.filterTrfData.bind(this);
    this.filterTrfDataForUpdate = this.filterTrfDataForUpdate.bind(this);
    this.confirmationBoxOpen = this.confirmationBoxOpen.bind(this);
    this.currentStep = this.currentStep.bind(this);
    this.cancelTrf = this.cancelTrf.bind(this);
  }

  componentDidMount() {
    const { updateMaterialField, clearFormValueTRF, history,initialCertificationArry, recycledContentValue,userDetails } = this.props;
    const { location } = this.props;
    const { fetchTrfData } = this.props;
    updateMaterialField('');
    clearFormValueTRF();
    if (location && location.state && location.state.trfId) {
      var facilityId = userDetails.facilityIds ? userDetails.facilityId+","+userDetails.facilityIds.join(",") :userDetails.facilityId
      fetchTrfData({ trfId: location.state.trfId,supplierId:userDetails.supplierId, facilityId:facilityId }, history);
    }
   initialCertificationArry([{name:"", number:"", other:""}])
   recycledContentValue("No")
  }
  componentDidUpdate(prevProps) {
    const { brandInfo } = this.props
    if (prevProps.brandInfo.productId !== brandInfo.productId) {
      this.setState({ prevProductId: prevProps.brandInfo.productId });
    }
  }
  onClickNext = () => {
    this.setState(prevState => ({
      stepState: prevState.stepState + 1
    }));
    this.clickNext();
  };

  onClickPrevious = () => {
    this.setState(prevState => ({
      stepState: prevState.stepState - 1
    }));
    this.clickPrevious();
  };

  filterTrfData = (brandInfoStates, testingInfoStates, labLocationStates, supplierForm, packagingTestIds, selectedMaterialTypes, rslOtherTestsIds) => {
    const resultArray = {};
    const { submitTrfData, history, userId, isRslUser, isFactoryUser } = this.props;
    const seasons = testingInfoStates.season;
    resultArray.brandId = brandInfoStates.brandId;
    resultArray.productId = parseInt(brandInfoStates.productId);
    resultArray.testingType = testingInfoStates.testingType;
    resultArray.supplierName = brandInfoStates.supplierName;
    resultArray.description = testingInfoStates.sampleDescription;
    if (resultArray.description) {
      resultArray.description = resultArray.description.replace(/\n/g, " ")
    }
    resultArray.seasonId = seasons;
    resultArray.supLocName = brandInfoStates.supLocName;
    resultArray.PDMNumber = brandInfoStates.pdmNumber;
    if (isRslUser || isFactoryUser) {
      resultArray.supplierId = supplierForm.supplierOrLocationId;
    } else {
      resultArray.supplierId = brandInfoStates.supplierId;
    }

    resultArray.locationId = brandInfoStates.labName;
    if (testingInfoStates.Year) {
      resultArray.year = (testingInfoStates.Year).year();
    } else {
      resultArray.year = new Date().getFullYear();
    }
    if (testingInfoStates.materialType) { 
      const materialType = [];
      testingInfoStates.materialType.filter(element => element != null)
        .map(data => {
          if (find(MaterialTypes, ['id', data]).variantNotMandatory) {
            materialType.push(data)
            if (testingInfoStates[`variants${data}`]) {
              materialType.push(testingInfoStates[`variants${data}`][0])
            }
          } else {
            if (testingInfoStates[`variants${data}`] && data !== 400 && data !== 405 ) {
              materialType.push(testingInfoStates[`variants${data}`])
            } else if (data === 400) {
              materialType.push.apply(materialType, testingInfoStates.variants400.filter(element => element != null));
            } else {
              materialType.push(data)
            }
          }
          if(data === 405){
            const getScreeningInks = testingInfoStates.variants405
            if(getScreeningInks === 4052){
              materialType.push(testingInfoStates.subVariants)
            }
            if(getScreeningInks === 4040){
              materialType.push(4040)
            }
          }
          return null
        }
        );
      //for Inks and Panints checkbox value
      resultArray.materialType =  materialType.filter(element => element !== 405)
    }
    if (testingInfoStates.repellent === 'Yes') {
      resultArray.repellent = true;
    }
    else {
      resultArray.repellent = false;
    }
    if (testingInfoStates.foodOrMouth === 'Yes') {
      resultArray.foodOrMouth = true;
    } else {
      resultArray.foodOrMouth = false;
    }
    if(brandInfoStates.productId === 202 || parseInt(brandInfoStates.productId) === 202){
      if (testingInfoStates.eyeWear === 'Yes') {        
        resultArray.eyeWear = true;
      } else {
        resultArray.eyeWear = false;
      }
    }
    if (
      testingInfoStates.testPackages ||
      testingInfoStates.testPackagesToys ||
      testingInfoStates.testPackagesElectronic ||
      testingInfoStates.testPackagesPackaging ||
      testingInfoStates.testPackagesJewelry
    ) {
      const testPackages = [];
      union(
        testingInfoStates.testPackages,
        testingInfoStates.testPackagesToys,
        testingInfoStates.testPackagesElectronic,
        testingInfoStates.testPackagesPackaging,
        testingInfoStates.testPackagesJewelry
      )
        .filter(element => element != null)
        .map(data =>
          includes(Object.keys(testingInfoStates), `tp-variants${data}`)
            ? testPackages.push(testingInfoStates[`tp-variants${data}`])
            : testPackages.push(data)
        );
        if(testPackages.includes(593)){
          const index = testPackages.indexOf(593);
          testPackages.splice(index,1);
          if(testingInfoStates.foodOrMouth === 'Yes'){
          testPackages.push(506);
          }else{
            testPackages.push(594);
          }
        }
      resultArray.testPackages = testPackages;
    }
    
    resultArray.labId = labLocationStates.lab;
    resultArray.labLocationId = labLocationStates[`location${labLocationStates.lab}`];
    resultArray.service = labLocationStates.serviceRequested;
    resultArray.materialName = brandInfoStates.materialName;
    resultArray.materialNumber = brandInfoStates.materialNumber;
    resultArray.refId = brandInfoStates.refId;
    resultArray.colorCode = testingInfoStates.colorCode;
    resultArray.colorName = testingInfoStates.colorName;
    resultArray.colorWay = testingInfoStates.colorway;
    resultArray.inkSystem = testingInfoStates.inkSystemName;
    resultArray.bName = testingInfoStates.baseName;
    resultArray.pColor = testingInfoStates.pigmentColor;
    resultArray.addit1 = testingInfoStates.additive1Name;
    resultArray.addit2 = testingInfoStates.additive2Name;
    resultArray.addit3 = testingInfoStates.additive3Name;
    resultArray.userId = userId;
    resultArray.sampleType = testingInfoStates.sampleType;
    resultArray.ageGroup = testingInfoStates.ageGroup;
    resultArray.sampleColor = testingInfoStates.sampleColor;
    if(testingInfoStates.certification && testingInfoStates.certification.length > 0 && typeof testingInfoStates.certification !== 'undefined'){
      const filterCertification = testingInfoStates.certification.filter(data => data.name !=="" && data.number !=="") 
      const deleteCertificationArry = filterCertification.map(item=>{
        delete item.other
        return item
      })
      resultArray.certification = deleteCertificationArry;
    }
    if (brandInfoStates.isLicensee === "Yes") {
      resultArray.licensee = brandInfoStates.licensee;
    }
    if (brandInfoStates.isRetest === "Yes") {
      const sliceString = brandInfoStates.originalTRF.slice(2);
      resultArray.originalTRF = parseInt(sliceString);
    }  
    
    if (brandInfoStates.isRetest === 'Yes') {
      resultArray.isRetest = true;
    } else {
      resultArray.isRetest = false;
    }
    if(testingInfoStates.enableTP2 === true){
      resultArray.enableTP2 = true;
    }else{
      resultArray.enableTP2 = false;
    }
    if(brandInfoStates.productId === 204 || parseInt(brandInfoStates.productId) === 204  || parseInt(brandInfoStates.productId) === 206){
      if (testingInfoStates.isElectEmbedded === 'Yes') {
        resultArray.isElectEmbedded = true;
      } else {
        resultArray.isElectEmbedded = false;
      }
      resultArray.electCategory = testingInfoStates.electCategory;
      if (testingInfoStates.skinContact === 'Yes') {
        resultArray.skinContact = true;
      } else {
        resultArray.skinContact = false;
      }
    }
    if(brandInfoStates.productId === 205 || parseInt(brandInfoStates.productId) === 205 || brandInfoStates.productId === 206 || parseInt(brandInfoStates.productId) === 206)
    {  
      resultArray.jeweleryMaterialType = testingInfoStates.jeweleryMaterialType;
    }
    /* if(testingInfoStates.recycle === 'Yes' && 
    (testingInfoStates.subrecycle === 'Post Consumer' || testingInfoStates.subrecycle === 'Post-Industrial')){
      resultArray.recycle = testingInfoStates.subrecycle;
    }else{
      resultArray.recycle = 'No';
    } */
    if(testingInfoStates.recycle === 'Yes'){
      resultArray.listRecycle = testingInfoStates.listRecycle;
      resultArray.describeRecycle = testingInfoStates.describeRecycle;
    }
    if (testingInfoStates.recycle === 'Yes') {
      resultArray.recycle = true;
    } else {
      resultArray.recycle = false;
    }
    submitTrfData(resultArray, history);
  };

  filterTrfDataForUpdate = (brandInfoStates, testingInfoStates, labLocationStates, supplierForm, packagingTestIds, selectedMaterialTypes, rslOtherTestsIds) => {
    const resultArray = {};
    const { updateTrfData, history, isRslUser, isFactoryUser, trfId } = this.props;
    const seasons = testingInfoStates.season;
    resultArray.brandId = brandInfoStates.brandId;
    resultArray.productId = parseInt(brandInfoStates.productId);
    if (brandInfoStates.isLicensee === "Yes" && brandInfoStates.oldLic && brandInfoStates.licensee !== brandInfoStates.oldLic) {
      resultArray.licensee = brandInfoStates.licensee;
      resultArray.oldLic = brandInfoStates.oldLic
    } else if (brandInfoStates.isLicensee === "Yes" && brandInfoStates.licensee) {
      resultArray.licensee = brandInfoStates.licensee;
    } else if (brandInfoStates.isLicensee === "No") {
      resultArray.licensee = null;
    }
    if (brandInfoStates.isRetest === "Yes" && brandInfoStates.originalTRF) {
      const sliceString = brandInfoStates.originalTRF.slice(2);
      resultArray.originalTRF = parseInt(sliceString); 
    }  else if (brandInfoStates.isRetest === "No") {
      resultArray.originalTRF = null;     
    }

    if (brandInfoStates.isRetest === 'Yes') {
      resultArray.isRetest = true;
    } else {
      resultArray.isRetest = false;
    }   
    resultArray.testingType = testingInfoStates.testingType;
    resultArray.supplierName = testingInfoStates.supplierName;
    resultArray.description = testingInfoStates.sampleDescription;
    if (resultArray.description) {
      resultArray.description = resultArray.description.replace(/\n/g, " ")
    }
    resultArray.seasonId = seasons;
    resultArray.supLocName = brandInfoStates.supLocName;
    resultArray.PDMNumber = brandInfoStates.pdmNumber;
    if (isRslUser || isFactoryUser) {
      resultArray.supplierId = supplierForm.supplierOrLocationId;
    } else {
      resultArray.supplierId = brandInfoStates.supplierId;
    }

    resultArray.locationId = brandInfoStates.labName;
    if (testingInfoStates.Year) {
      resultArray.year = (testingInfoStates.Year).year();
    } else {
      resultArray.year = new Date().getFullYear();
    }
    if (testingInfoStates.materialType) {
      const materialType = [];
      testingInfoStates.materialType.filter(element => element != null)
        .map(data => { 
          if(find(MaterialTypes, ['id', data]).isTestAvailable === true){
          if (find(MaterialTypes, ['id', data]).variantNotMandatory) {
            materialType.push(data)
            if (testingInfoStates[`variants${data}`]) {
              materialType.push(testingInfoStates[`variants${data}`][0])
            }
          } else {
            if (testingInfoStates[`variants${data}`] && data !== 400 && data !== 405) {
              materialType.push(testingInfoStates[`variants${data}`])
            } else if (data === 400) {
              materialType.push.apply(materialType, testingInfoStates.variants400.filter(element => element != null));
            } else {
              materialType.push(data)
            }
           
          }
          if(data === 405){
            const getScreeningInks = testingInfoStates.variants405
            if(getScreeningInks === 4052){
              materialType.push(testingInfoStates.subVariants)
            }
            if(getScreeningInks === 4040){
              materialType.push(4040)
            }
          }
          return null
        }
        return null
      }
        );
         //for Inks and Panints checkbox value
      resultArray.materialType =  materialType.filter(element => element !== 405)
      //resultArray.materialType = materialType
    }
    if (testingInfoStates.repellent === 'Yes') {
      resultArray.repellent = true;
    }
    else {
      resultArray.repellent = false;
    }
    /* if(testingInfoStates.recycle === 'Yes'){
      resultArray.recycle = 'Yes';
    }else{
      resultArray.recycle = 'No';
    } */
    if (testingInfoStates.foodOrMouth === 'Yes') {
      resultArray.foodOrMouth = true;
    } else {
      resultArray.foodOrMouth = false;
    }
    if(brandInfoStates.productId === 202 || parseInt(brandInfoStates.productId) === 202){
      if (testingInfoStates.eyeWear === 'Yes') {
        resultArray.eyeWear = true;
      } else {
        resultArray.eyeWear = false;
      }
    }
    if (
      testingInfoStates.testPackages ||
      testingInfoStates.testPackagesToys ||
      testingInfoStates.testPackagesElectronic ||
      testingInfoStates.testPackagesPackaging ||
      testingInfoStates.testPackagesJewelry 
    ) {
      const testPackages = [];
      union(
        testingInfoStates.testPackages,
        testingInfoStates.testPackagesToys,
        testingInfoStates.testPackagesElectronic,
        testingInfoStates.testPackagesPackaging,
        testingInfoStates.testPackagesJewelry
      )
        .filter(element => element != null)
        .map(data =>
          includes(Object.keys(testingInfoStates), `tp-variants${data}`)
            ? testPackages.push(testingInfoStates[`tp-variants${data}`])
            : testPackages.push(data)
        );
        if(testPackages.includes(594) || testPackages.includes(506) || testPackages.includes(593)){
          if(testPackages.includes(593)){
            testPackages.splice(testPackages.indexOf(593),1)
          }
        if(testingInfoStates.foodOrMouth === 'Yes'){
          if(testPackages.includes(594)){
            testPackages.splice(testPackages.indexOf(594),1)
          }
         if(!testPackages.includes(506)){testPackages.push(506) };
        }else{
          if(testPackages.includes(506)){
            testPackages.splice(testPackages.indexOf(506),1)
          }
          if(!testPackages.includes(594)){testPackages.push(594) };
        }
      }
      resultArray.testPackages = testPackages;
        
    }
    
    resultArray.labId = labLocationStates.lab;
    resultArray.labLocationId = labLocationStates[`location${labLocationStates.lab}`];
    resultArray.service = labLocationStates.serviceRequested;
    resultArray.materialName = brandInfoStates.materialName;
    resultArray.materialNumber = brandInfoStates.materialNumber;
    resultArray.refId = brandInfoStates.refId;
    resultArray.colorCode = testingInfoStates.colorCode;
    resultArray.colorName = testingInfoStates.colorName;
    resultArray.colorWay = testingInfoStates.colorway;
    resultArray.inkSystem = testingInfoStates.inkSystemName;
    resultArray.bName = testingInfoStates.baseName;
    resultArray.pColor = testingInfoStates.pigmentColor;
    resultArray.addit1 = testingInfoStates.additive1Name;
    resultArray.addit2 = testingInfoStates.additive2Name;
    resultArray.addit3 = testingInfoStates.additive3Name;
    resultArray.trfId = trfId;
    resultArray.sampleType = testingInfoStates.sampleType;
    resultArray.ageGroup = testingInfoStates.ageGroup;
    resultArray.sampleColor = testingInfoStates.sampleColor;
    if(testingInfoStates.certification && testingInfoStates.certification.length > 0 && typeof testingInfoStates.certification !== 'undefined'){
      const filterCertification = testingInfoStates.certification.filter(data => data.name !=="" && data.number !=="") 
      const deleteCertificationArry = filterCertification.map(item=>{
        delete item.other
        return item
      })
      resultArray.certification = deleteCertificationArry;
    }
    if(testingInfoStates.enableTP2 === true){
      resultArray.enableTP2 = true;
    }else{
      resultArray.enableTP2 = false;
    }
    if(brandInfoStates.productId === 204 || parseInt(brandInfoStates.productId) === 204 || parseInt(brandInfoStates.productId) === 206){
      if (testingInfoStates.isElectEmbedded === 'Yes') {
        resultArray.isElectEmbedded = true;
      } else {
        resultArray.isElectEmbedded = false;
      }
      if (testingInfoStates.skinContact === 'Yes') {
        resultArray.skinContact = true;
      } else {
        resultArray.skinContact = false;
      }
      resultArray.electCategory = testingInfoStates.electCategory;
      
    }
    /* if(testingInfoStates.recycle === 'Yes' && 
    (testingInfoStates.subrecycle === 'Post Consumer' || testingInfoStates.subrecycle === 'Post-Industrial')){
      resultArray.recycle = testingInfoStates.subrecycle;
    }else{
      resultArray.recycle = 'No';
    } */
    if(brandInfoStates.productId === 205 || parseInt(brandInfoStates.productId) === 205 || brandInfoStates.productId === 206 || parseInt(brandInfoStates.productId) === 206)
    {  
      resultArray.jeweleryMaterialType = testingInfoStates.jeweleryMaterialType;
    }
    if(testingInfoStates.recycle === 'Yes'){
      resultArray.listRecycle = testingInfoStates.listRecycle;
      resultArray.describeRecycle = testingInfoStates.describeRecycle;
    }
    if(testingInfoStates.recycle === 'No'){
      resultArray.listRecycle = '';
      resultArray.describeRecycle = '';
    }
   if (testingInfoStates.recycle === 'Yes') {
    resultArray.recycle = true;
  } else {
    resultArray.recycle = false;
  }
  updateTrfData(resultArray, history);
  };
  confirmationBoxOpen = (modalopen, status) => this.setState({ submitModal: true, status });
  closeSubmitModal = () => this.setState({ submitModal: false });
  cancelTrf = () => {
    const { history } = this.props;
    history.push('/');
  };
  currentStep = step => this.setState({ stepState: step });
  render() {
    const {
      brandInfoStates,
      testingInfoStates,
      labLocationStates,
      errorClassName,
      sampleType,
      shouldDisplaySubmit,
      displaySubmitButtonTrf,
      displayClearButtonTrf,
      isLabUser,
      brandInfo,
      brandInfoForEdit,
      testingInfo,
      testingInfoForEdit,
      labInfo,
      labInfoForEdit,
      supplierForm,
      trfId,
      isLoading,
      packagingTestIds,
      rslOtherTestsIds,
      selectedMaterialTypes,
      location,
      isNloUser,
      
    } = this.props;
    const  getProductId  = brandInfo.productId;
     const rslId = location.state !== undefined && location.state.rslId ? location.state.rslId: "" ;
     const isRetestPassed = location.state !== undefined && location.state.isRetest ? location.state.isRetest: "" ;
    const { submitModal, status, stepState,prevProductId } = this.state;

    if (isLoading) return <Spinner large />;
    const brandInfoComponent = (
      <BrandInfo
        displaySubmitButtonTrf={displaySubmitButtonTrf}
        displayClearButtonTrf={displayClearButtonTrf}
        isPartialEdit={brandInfoForEdit.trfId && location && location.state &&location.state.isPartialEdit ? true : false}
        {...(brandInfoForEdit.trfId ? { initialValues: brandInfoForEdit } : { initialValues: brandInfo })}
        sucessFill={typeof errorClassName !== 'undefined' ? errorClassName[0] : true}
         rslId = {rslId}
         isRetestPassed = {isRetestPassed}
      />
    );
    const testingInfoComponent = (
      <TestingInfo
        displaySubmitButtonTrf={displaySubmitButtonTrf}
        displayClearButtonTrf={displayClearButtonTrf}
        sampleType={sampleType}
        isPartialEdit={testingInfoForEdit.trfId && location && location.state &&location.state.isPartialEdit ? true : false}
        {...(testingInfoForEdit.trfId ? { initialValues: testingInfoForEdit } : { initialValues: testingInfo })}
        sucessFill={typeof errorClassName !== 'undefined' ? errorClassName[1] : true}
        productId = {parseInt(getProductId)}
        //brandInfoStates = {brandInfoForEdit}
        brandInfo = {brandInfo}
        prevProductId = {prevProductId}
        errorStatus={false}
      />
    );
    const labLocationComponent = (
      <LabLocation
        displaySubmitButtonTrf={displaySubmitButtonTrf}
        displayClearButtonTrf={displayClearButtonTrf}
        isPartialEdit={labInfoForEdit.trfId && location && location.state &&location.state.isPartialEdit ? true : false}
        {...(labInfoForEdit.trfId ? { initialValues: labInfoForEdit } : { initialValues: labInfo })}
        sucessFill={typeof errorClassName !== 'undefined' ? errorClassName[2] : true}
        productId = {parseInt(getProductId)}
        prevProductId = {prevProductId}
      />
    );
    const reviewComponent = (
      <Review
        displaySubmitButtonTrf={displaySubmitButtonTrf}
        displayClearButtonTrf={displayClearButtonTrf}
        packagingTestIds={packagingTestIds ? packagingTestIds : []}
        rslOtherTestsIds={rslOtherTestsIds ? rslOtherTestsIds : []}
        selectedMaterialTypes={selectedMaterialTypes}
        data={this.props}
        resetTabValue={false}
        tabStatus={false}
        productId = {getProductId}
      />
    );
    const steps = [
      {
        name: 'MATERIAL SELECTION',
        component: brandInfoComponent
      },
      {
        name: 'TESTING INFORMATION',
        component: testingInfoComponent
      },
      {
        name: 'LAB SELECTION',
        component: labLocationComponent
      },
      {
        name: 'REVIEW & SUBMIT',
        component: reviewComponent
      }
    ];
    return (
      <div className="page-align-fixed-items">
        { // The Testing info and lab location component should be preloded in the view for fraction of second,
          // for redux form values to be intialized on the redux store
          // This fix is done for form values not getting added to redux store,
          // when user directly navigates to review page without accessing other forms in the stepper component.
          trfId &&
          <div className="invisible">
            {testingInfoComponent}
            {labLocationComponent}
          </div>
        }
        {!isLabUser ? (
          <div className="TRFpage">
            <div section className="prevent-scroll top-scroll-fix"
             
            >
              <H4 className="text-color-accent u-bold page-title-has-back sub-heading p2-bottom-sm">
                TEST REQUEST FORM (TRF)
              </H4>
            </div>
            <div section className="clear-margin progress-tracker-align">
              <ProgressTracker
                steps={steps}
                errorClassName={errorClassName}
                clickNext={clickNext => (this.clickNext = clickNext)}
                clickPrevious={clickPrevious => (this.clickPrevious = clickPrevious)}
                currentStepNumber={this.currentStep}
              />
            </div>
            <Container
              section
              className="prevent-scroll bottom-scroll-fix bottom-section remove-margin sumbmit-area"
            >
              <Button
                style={stepState === 0 ? this.hidden : {}}
                className="ncss-btn-secondary-dark u-bold progresstracker-prevoius-button trf-button-selected"
                onClick={() => this.onClickPrevious('previous')}
              >
                PREVIOUS
              </Button>
              {(!trfId || !location.state.preLoadMaterial) ?
                shouldDisplaySubmit  && (
                  <span>
                    {errorClassName.every(element => element === false) ? (
                      <Button
                        className="ncss-btn-primary-dark u-bold submit-button-enable trf-button-selected"
                        onClick={() => this.confirmationBoxOpen(true, 'submit')}
                      >
                        SUBMIT
                      </Button>
                    ) : (
                        <Button className="ncss-btn-primary-dark disabled u-bold submit-button trf-button-selected">
                          SUBMIT
                        </Button>
                      )}
                  </span>
                )
                :
                shouldDisplaySubmit && (
                  <span>
                    {(errorClassName.every(element => element === false) && !isNloUser) ? (
                      <Button
                        className="ncss-btn-primary-dark u-bold submit-button-enable trf-button-selected"
                        onClick={() => this.confirmationBoxOpen(true, 'update')}
                      >
                        UPDATE
                      </Button>
                    ) : (
                        <Button className="ncss-btn-primary-dark disabled u-bold submit-button trf-button-selected">
                          UPDATE
                        </Button>
                      )}
                  </span>
                )
              }
              <Button
                className="ncss-btn-secondary-dark u-bold secondary-button trf-button-selected"
                onClick={() => this.confirmationBoxOpen(true, 'clear')}
              >
                CLEAR
              </Button>
              <Button
                style={stepState === 3 ? this.hidden : {}}
                className="ncss-btn-secondary-dark u-bold progresstracker-next-button trf-button-selected"
                onClick={() => this.onClickNext('next')}
              >
                NEXT
              </Button>
            </Container>
            <ConfirmationBox
              message={
                status === 'submit'
                  ? 'Are you sure you want to submit the TRF?'
                  : status === 'update' ?
                    'Are you sure you want to update the TRF?'
                    : 'Are you sure you want to clear the TRF'
              }
              submitModal={submitModal}
              submitValue={
                status === 'submit'
                  ? () => {
                    this.filterTrfData(brandInfoStates, testingInfoStates, labLocationStates, supplierForm, packagingTestIds, selectedMaterialTypes, rslOtherTestsIds);
                  }
                  : status === 'update' ?
                    () => { this.filterTrfDataForUpdate(brandInfoStates, testingInfoStates, labLocationStates, supplierForm, packagingTestIds, selectedMaterialTypes, rslOtherTestsIds); } :
                    () => this.cancelTrf()
              }
              closeModal={this.closeSubmitModal}
            />
          </div>
        ) : (
            <div className="pagenotFound">Error! Page you are looking for is not found</div>
          )}
      </div>
    );
  }

  componentWillUnmount() {
    // Clear all previously set form values
    const { clearFormValueTRF } = this.props;
    clearFormValueTRF();
  }

}

Trf.propTypes = {
  brandInfoStates: PropTypes.object,
  testingInfoStates: PropTypes.object,
  labLocationStates: PropTypes.object,
  errorClassName: PropTypes.array,
  sampleType: PropTypes.string,
  submitTrfData: PropTypes.func,
  updateTrfData: PropTypes.func,
  clearFormValueTRF: PropTypes.func,
  displaySubmitButtonTrf: PropTypes.func,
  history: PropTypes.object,
  shouldDisplaySubmit: PropTypes.bool,
  displayClearButtonTrf: PropTypes.func,
  updateMaterialField: PropTypes.func,
  userId: PropTypes.number,
  isLabUser: PropTypes.bool,
  trfId: PropTypes.number,
  preLoadComponents: PropTypes.bool,
  initialCertificationArry:PropTypes.func,
  recycledContentValue: PropTypes.func,
};
  function sortDynamic(key, order = 'asc')  {
  const sortOrder = order === 'asc' ? 1 : -1
  return (a, b) => {
      const A = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
      const B = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];
      if ( A < B ){
          return sortOrder * -1
      }else if ( A > B ) {
          return sortOrder * 1
      } else {
          return 0
      }
    }
}
function modifyData(data) {
  if(data.length >= 3){
  var mod = data.length % 3;
  var colLength = data.length / 3;
  var tableArr =[];
  var _ndEle =  mod === 2 ?Math.trunc(colLength) + 1 : Math.trunc(colLength) + mod;
  var rowLength = Math.trunc(colLength);
  var roundoff = Math.round(colLength);

  for(var i=0;i< _ndEle;i++){
    if(i === 0){
      tableArr.push(data[0]);
      tableArr.push(data[_ndEle]);
      mod === 2 ? tableArr.push(data[_ndEle + roundoff]): tableArr.push(data[_ndEle + rowLength]);
    }else{
      if(mod === 2){
        tableArr.length < data.length && tableArr.push(data[i]);
        tableArr.length < data.length && tableArr.push(data[i+roundoff]);
        tableArr.length < data.length &&  tableArr.push(data[i+roundoff+roundoff]);
      }
     else if((i+_ndEle+rowLength -1) < data.length && mod !== 2){
      tableArr.push(data[i]);
       if(mod === 1){
        (i+_ndEle+rowLength) !== tableArr.length && tableArr.push(data[i+_ndEle]);
        (i+_ndEle+rowLength) !== tableArr.length &&  tableArr.push(data[i+_ndEle+rowLength]);
      }
       else if((i+_ndEle+rowLength) !== data.length){
        tableArr.push(data[i+_ndEle])
        tableArr.push(data[i+_ndEle+rowLength])
       }
      }
    }
  }
  return tableArr;
}
return data
}
function returnJewelrymaterialType(selectedMterialTypes,preLoadMaterial){
  var jeweleryMaterialType = ''
  if(preLoadMaterial){
  if(selectedMterialTypes.includes(4146)  === true || selectedMterialTypes.includes(4142) === true){
    jeweleryMaterialType = 4148
  }else if(selectedMterialTypes.includes(4141) === true || selectedMterialTypes.includes(4145) === true){
    jeweleryMaterialType = 4147 
  }
  else if(selectedMterialTypes.includes(4144) === true ){
    jeweleryMaterialType = 4144 
  }else if(selectedMterialTypes.includes(4143) === true ){
    jeweleryMaterialType = 4143 
  }else{
    jeweleryMaterialType = " " 
  }
}
  return jeweleryMaterialType
}

function getSubVariantsIds(selectedMterialTypes){
  const subVarientsIds = selectedMterialTypes.filter((obj) => 
     obj === 4051 || obj === 4050
    );
  return subVarientsIds
}
function getElectronicCategory(electCategorystate,preLoadMaterial){
  if(preLoadMaterial){
  if (electCategorystate === "electrical" ) {
      return "electrical";
  } 
  else if (electCategorystate === "battery") {
     return "battery";
  } 
  else if (electCategorystate === null) {
     return "";
  }
}else return "";
}
function allMaterialTypes(){
   var rslTests=MaterialTypes.filter(data => data.category === "rsl-test" && data.isTestAvailable === true).sort(sortDynamic('label'));
   rslTests = modifyData(rslTests);
   var otherTest =MaterialTypes.filter(data => data.category === "other-test" && data.isTestAvailable === true).sort(sortDynamic('label'));
   otherTest = modifyData(otherTest);
   var packagingTestSort =MaterialTypes.filter(data => data.category === "packaging-test").sort(sortDynamic('label'));
   packagingTestSort = modifyData(packagingTestSort);
   var jewelryTestSort =MaterialTypes.filter(data => data.category === "jewelry-test" && data.isTestAvailable === true).sort(sortDynamic('label'));
   var electronicTest = MaterialTypes.filter(data => data.category === "electronic-test" && data.isTestAvailable === true).sort(sortDynamic('label'));
   electronicTest = modifyData(electronicTest);
   const materialTypesSorted = rslTests.concat(otherTest).concat(packagingTestSort).concat(jewelryTestSort).concat(electronicTest);
   return materialTypesSorted
}

  var returnBrandInfoForEdit =(state) => {
    const retestTrfNumber = get(state, `trf.trfDataForEdit.originalTRF`, '');
    const convertToRsl = retestTrfNumber !== null && retestTrfNumber !== 'undefined' ?
     convertToRSLNumber(retestTrfNumber) : '';
    const brandInfoForEdit = {
    trfId: get(state, `trf.trfDataForEdit.trfId`, ''),
    userId: get(state, `trf.trfDataForEdit.userId`, ''),
    brandId: get(state, `form.brandInfo.values.brandId`) || get(state, `trf.trfDataForEdit.brand`, ''),
    productId: get(state, `form.brandInfo.values.productId`) || get(state, `trf.trfDataForEdit.product`, ''),
    isLicensee: get(state, `form.brandInfo.values.isLicensee`) || get(state, `trf.trfDataForEdit.isLicensee`, ''),
    licensee: get(state, `form.brandInfo.values.licensee`) || get(state, `trf.trfDataForEdit.licensee`, ''),
    oldLic: get(state, `trf.trfDataForEdit.licensee`, ''),
    labName: get(state, `form.brandInfo.values.labName`) || get(state, `trf.trfDataForEdit.locationId`, ''),
    materialName: get(state, `form.brandInfo.values.materialName`) || get(state, `trf.trfDataForEdit.materialName`, ''),
    materialNumber: get(state, `form.brandInfo.values.materialNumber`) || get(state, `trf.trfDataForEdit.materialNumber`, ''),
    refId: (get(state, `form.brandInfo.values.refId`) || get(state, `form.brandInfo.values.refId`) === '') ? get(state, `form.brandInfo.values.refId`) : get(state, `trf.trfDataForEdit.refId`),
    pdmNumber: (get(state, `form.brandInfo.values.PDMNumber`) || get(state, `form.brandInfo.values.PDMNumber`) === '') ? get(state, `form.brandInfo.values.PDMNumber`) : get(state, `trf.trfDataForEdit.PDMNumber`),
    supplierName:get(state, `form.brandInfo.values.supplierName`,'') || get(state, `trf.trfDataForEdit.supplierName`, '') !== null ? get(state, `form.brandInfo.values.supplierName`,'') ||get(state, `trf.trfDataForEdit.supplierName`, '') : 
            get(state, `form.brandInfo.values.facilityName`,'') ||get(state, `trf.trfDataForEdit.facilityName`, ''),
    supplierId :get(state, `form.brandInfo.values.supplierId`) || get(state, `trf.trfDataForEdit.supplierId`, '') !== null ? 
            get(state, `form.brandInfo.values.supplierId`) || get(state, `trf.trfDataForEdit.supplierId`, ''):
            get(state, `form.brandInfo.values.facilityId`) ||get(state, `trf.trfDataForEdit.facilityId`, ''),
    resetMaterialInfo: get(state, `form.brandInfo.values.resetMaterialInfo`, ''),
    brandInfoStatus: 'Edit',
    activeForm: 'brandInfo',
    supLocName: get(state, `form.brandInfo.values.supLocName`, '') || get(state, `trf.trfDataForEdit.supLoc`, ''),
    isRetest: get(state, `form.brandInfo.values.isRetest`) || get(state, `trf.trfDataForEdit.isRetest`, ''),
    originalTRF: get(state, `form.brandInfo.values.originalTRF`) || convertToRsl
    }
    return brandInfoForEdit
  }
var returnBrandInfoForCreate = (state) =>{
  const brandInfoForCreate = {
    trfId: '',
    brandId: get(state, `form.brandInfo.values.brandId`, ''),
    productId: get(state, `form.brandInfo.values.productId`, ''),
    isLicensee: get(state, `form.brandInfo.values.isLicensee`, ''),
    licensee: get(state, `form.brandInfo.values.licensee`, ''),
    labName: get(state, `form.brandInfo.values.labName`, ''),
    materialName: get(state, `form.brandInfo.values.materialName`, ''),
    materialNumber: get(state, `form.brandInfo.values.materialNumber`, ''),
    supplierName: get(state, `form.brandInfo.values.supplierName`, '') || get(state, `form.testingInfo.values.supplierName`, ''),
    supplierId: get(state, `form.brandInfo.values.supplierId`) || get(state, `form.testingInfo.values.supplierId`, ''),
    refId: get(state, `form.brandInfo.values.refId`, ''),
    pdmNumber: get(state, `form.brandInfo.values.PDMNumber`, ''),
    resetMaterialInfo: get(state, `form.brandInfo.values.resetMaterialInfo`, ''),
    brandInfoStatus: 'New',
    supLocName: get(state, `form.brandInfo.values.supLocName`, ''),
    isRetest: get(state, `form.brandInfo.values.isRetest`, ''),
    originalTRF: get(state, `form.brandInfo.values.originalTRF`, ''),
  }
  return brandInfoForCreate
}
var returnLabInfoForCreate =(state)=>{
  const labInfoForCreate ={
    trfId: '',
      lab: get(state, `form.labLocation.values.lab`),
      serviceRequested: get(state, `form.labLocation.values.serviceRequested`) || 'Regular',
      labName: `location${get(state, `form.labLocation.values.lab`) || get(state, `trf.trfDataForEdit.lab`, '')}`,
      labLocation: get(state, `form.labLocation.values.lab`),
      labInfoStatus: 'New'
  }
  return labInfoForCreate
}
var returnLabInfoForEdit =(state)=>{
  const labInfoForEdit ={
    trfId: get(state, `trf.trfDataForEdit.trfId`, ''),
    lab: get(state, `form.labLocation.values.lab`) || (get(state, `trf.trfDataForEdit.labLocationId`, '') ? filter(location, { location: [{ id: get(state, `trf.trfDataForEdit.labLocationId`, '') }] })[0].id : ''),
    serviceRequested: get(state, `form.labLocation.values.serviceRequested`) || get(state, `trf.trfDataForEdit.service`, ''),
    labName: `location${get(state, `form.labLocation.values.lab`) || get(state, `trf.trfDataForEdit.lab`, '')}`,
    labLocation: get(state, `form.labLocation.values.location${get(state, `form.labLocation.values.lab`)}`) ||
      find(flattenItems(location, 'location'), [
        'id',
        get(state, `trf.trfDataForEdit.labLocationId`, '')
      ]),
    labInfoStatus: 'Edit',
    activeForm: 'labInfo'
  }
  return labInfoForEdit
}
function mapStateToProps(state,ownProps) {
  let repellent;
  let foodOrMouth;
  let eyeWear
  let enableTP2;
  let isElectEmbedded;
  let electCategory;
  let jeweleryMaterialType;
  let certification;
  let skinContact;
  let recycle;
  let testPackageArray, toysTestPackageArray, electronicTestPackageArray, packagingTestPackageArray, jewelrytestPackagesJewelry, materialTypes;
  
  const selectedTestPackages = get(state, `trf.trfDataForEdit.manualTest`, null);
  const selectedMterialTypes = get(state, `trf.trfDataForEdit.materialType`, null);
  const packagingTests = MaterialTypes.filter(data => data.category === "packaging-test");
  const packagingTestIds = packagingTests.map(data => data.id)
  const rslOtherTests = MaterialTypes.filter(x => !packagingTests.includes(x));
  const rslOtherTestsIds = rslOtherTests.map(data => data.id);

  if (selectedTestPackages) {
    testPackageArray = !ownProps.location.state.preLoadMaterial ? [] :getTestPackage(individualTest[0].common.filter(data => data.isTestAvailable === true), selectedTestPackages);
    toysTestPackageArray =!ownProps.location.state.preLoadMaterial ? [] : getTestPackage(individualTest[0].toys.filter(data => data.isTestAvailable === true), selectedTestPackages);
    electronicTestPackageArray = !ownProps.location.state.preLoadMaterial ? [] :getTestPackage(individualTest[0].electronic.filter(data => data.isTestAvailable === true), selectedTestPackages);
    packagingTestPackageArray = !ownProps.location.state.preLoadMaterial ? [] : getTestPackage(individualTest[0].packaging.filter(data => data.isTestAvailable === true), selectedTestPackages);
    jewelrytestPackagesJewelry = !ownProps.location.state.preLoadMaterial ? [] :getTestPackage(individualTest[0].jewelry.filter(data => data.isTestAvailable === true), selectedTestPackages);
  }
  if (selectedMterialTypes) {
    const materialTypesSorted = allMaterialTypes()
    //Edit form to bind the selected Material Types
    materialTypes =!ownProps.location.state.preLoadMaterial ? [] :getMaterialTypes(materialTypesSorted, selectedMterialTypes);
    const subVarientsIds = selectedMterialTypes.filter((obj) => {
    return obj === 4051 || obj === 4050;
    });
    var subVarientsValue = getSubVariantsIds(selectedMterialTypes).length > 0 ? subVarientsIds[0] : []
  }
  const variants = filter(MaterialTypes, { variants: [{ id: get(state, `trf.trfDataForEdit.materialType`) }] })[0] ?
    `variants${filter(MaterialTypes, { variants: [{ id: get(state, `trf.trfDataForEdit.materialType`) }] })[0].id}` :
    `variants${get(state, `trf.trfDataForEdit.materialType`, '')}`;
  
  if (get(state, `trf.trfDataForEdit.repellent`)) {
    repellent = 'Yes';
  }
  else {
    repellent = 'No';
  }
  if (get(state, `trf.trfDataForEdit.foodOrMouth`)) {
    foodOrMouth = 'Yes';
  }
  else {
    foodOrMouth = 'No';
  }
  
  if (get(state, `trf.trfDataForEdit.eyeWear`)) {
    eyeWear = 'Yes';
  }
  else {
    eyeWear = 'No';
  }
  if (get(state, `trf.trfDataForEdit.enableTP2`)) {
    enableTP2 = true;
  }
  else {
    enableTP2 = false;
  }
  if (get(state, `trf.trfDataForEdit.isElectEmbedded`)) {
    isElectEmbedded = 'Yes';
  }
  else {
    isElectEmbedded = 'No';
  }
  if (get(state, `trf.trfDataForEdit.skinContact`)) {
    skinContact = 'Yes';
  }
  else {
    skinContact = 'No';
  }
  if (get(state, `trf.trfDataForEdit.recycle`)) {
    recycle = 'Yes';
  }
  else {
    recycle = 'No';
  }
  if(get(state, `trf.trfDataForEdit.electCategory`)){
    electCategory = getElectronicCategory(get(state, `trf.trfDataForEdit.electCategory`),ownProps.location.state.preLoadMaterial)
  }
  if(selectedMterialTypes && selectedMterialTypes.length > 0){
    jeweleryMaterialType = returnJewelrymaterialType(selectedMterialTypes,ownProps.location.state.preLoadMaterial)
  }
  if(get(state, `trf.trfDataForEdit.certification`)){
    certification = get(state, `trf.trfDataForEdit.certification`)
  }
  return {
    brandInfoStates: getFormValues('brandInfo')(state),
    testingInfoStates: getFormValues('testingInfo')(state),
    labLocationStates: getFormValues('labLocation')(state),
    supplierForm: getFormValues('supplierForm')(state),
    errorClassName: state.trf.errorClassName,
    sampleType: state.trf.sampleType,
    trfResult: state.trf.result,
    trfDataForEdit: state.trf.trfDataForEdit,
    shouldDisplaySubmit: state.trf.shouldDisplaySubmit,
    shouldDisplayClear: state.trf.shouldDisplayClear,
    userId: selectUserDetails(state).userId,
    userDetails: selectUserDetails(state),
    isRslUser: isRslUser(state),
    isNloUser: isNloUser(state),
    isFactoryUser: isFactoryUser(state),
    isLabUser: isLabUser(state),
    trfId: get(state.trf.trfDataForEdit, 'trfId', ''),
    isLoading: state.trf.isLoading,
    preLoadComponents: state.trf.preLoadComponents,
    packagingTestIds: packagingTestIds,
    rslOtherTestsIds: rslOtherTestsIds,
    selectedMaterialTypes: get(state, `form.testingInfo.values.materialType`) || materialTypes,
    brandInfo:  returnBrandInfoForCreate(state),
    brandInfoForEdit:  returnBrandInfoForEdit(state),
    isRetest: get(state, `form.brandInfo.values.isRetest`, ''),
    testingInfo: {
      trfId: '',
      supplierName: get(state, `form.testingInfo.values.supplierName`),
      supLocName: get(state, `form.testingInfo.values.supLocName`, ''),
      materialName: get(state, `form.testingInfo.values.materialName`),
      materialNumber: get(state, `form.testingInfo.values.materialNumber`),
      testmaterialName: get(state, `form.testingInfo.values.testmaterialName`),
      testmaterialNumber: get(state, `form.testingInfo.values.testmaterialNumber`),
      refId: get(state, `form.testingInfo.values.refId`),
      colorCode: get(state, `form.testingInfo.values.colorCode`),
      colorName: get(state, `form.testingInfo.values.colorName`),
      colorway: get(state, `form.testingInfo.values.colorway`),
      sampleDescription: get(state, `form.testingInfo.values.sampleDescription`),
      certification: get(state, `form.testingInfo.values.certification`),
      season: get(state, `form.testingInfo.values.season`),
      Year: get(state, `form.testingInfo.values.Year`),
      testingType: get(state, `form.testingInfo.values.testingType`, 'PR'),
      repellent: get(state, `form.testingInfo.values.repellent`, 'No'),
      recycle: get(state, `form.testingInfo.values.recycle`,'No'),
      //recycle: get(state, `form.testingInfo.values.recycle`,'No'),
      //subrecycle: get(state, `form.testingInfo.values.recycle`),
      //recycle:  get(state, `form.testingInfo.values.recycle`, 'No'),
      foodOrMouth: get(state, `form.testingInfo.value.foodOrMouth`, 'No'),
      sampleType: get(state, `form.testingInfo.value.sampleType`),
      ageGroup: get(state, `form.testingInfo.value.ageGroup`),
      sampleColor: get(state, `form.testingInfo.value.sampleColor`),
      eyeWear: get(state, `form.testingInfo.values.eyeWear`, 'No'),
      isElectEmbedded: get(state, `form.testingInfo.values.isElectEmbedded`, 'No'),
      skinContact: get(state, `form.testingInfo.values.skinContact`, 'No'),
      electCategory: get(state, `form.testingInfo.values.electCategory`),
      enableTP2: get(state, `form.testingInfo.values.enableTP2`, false),
      materialType: get(state, `form.testingInfo.values.materialType`),
      variantName: `variants${get(state, `form.testingInfo.values.materialType`)}`,
      testPackages: get(state, `form.testingInfo.values.testPackages`),
      testPackagesToys: get(state, `form.testingInfo.values.testPackagesToys`),
      testPackagesElectronic: get(state, `form.testingInfo.values.testPackagesElectronic`),
      testPackagesPackaging: get(state, `form.testingInfo.values.testPackagesPackaging`),
      testPackagesJewelry: get(state, 'form.testingInfo.values.testPackagesJewelry'),
      inkSystemName: get(state, `form.testingInfo.values.inkSystemName`),
      baseName: get(state, `form.testingInfo.values.baseName`),
      pigmentColor: get(state, `form.testingInfo.values.pigmentColor`),
      additive1Name: get(state, `form.testingInfo.values.additive1Name`),
      additive2Name: get(state, `form.testingInfo.values.additive2Name`),
      additive3Name: get(state, `form.testingInfo.values.additive3Name`),
      InitialProductType : get(state, `form.brandInfo.values.productId`, null),
      currentProductType : get(state, `form.brandInfo.values.productId`, null),
      testingInfoStatus: 'New'
    },
    testingInfoForEdit: {
      trfId: get(state, `trf.trfDataForEdit.trfId`, ''),
      //supplierName: get(state, `form.testingInfo.values.supplierName`) || get(state, `trf.trfDataForEdit.supplierName`, ''),
      supplierName: ((get(state, `form.testingInfo.values.supplierName`) || get(state, `trf.trfDataForEdit.supplierName`)=== null)) ?
         get(state, `form.testingInfo.values.facilityName`) ||get(state, `trf.trfDataForEdit.facilityName`, '') : 
         get(state, `form.testingInfo.values.supplierName`) || get(state, `trf.trfDataForEdit.supplierName`,''), 
     supLocName: get(state, `form.testingInfo.values.supLocName`, '') || get(state, `trf.trfDataForEdit.supLoc`, ''),
      materialName: get(state, `form.testingInfo.values.materialName`) || get(state, `trf.trfDataForEdit.materialName`, ''),
      materialNumber: get(state, `form.testingInfo.values.materialNumber`) || get(state, `trf.trfDataForEdit.materialNumber`, ''),
      testmaterialName: get(state, `form.testingInfo.values.testmaterialName`) || get(state, `trf.trfDataForEdit.materialName`, ''),
      testmaterialNumber: get(state, `form.testingInfo.values.testmaterialNumber`) || get(state, `trf.trfDataForEdit.materialNumber`, ''),
      refId: (get(state, `form.brandInfo.values.refId`) || get(state, `form.brandInfo.values.refId`) === '') ? get(state, `form.brandInfo.values.refId`) : get(state, `trf.trfDataForEdit.refId`),
      colorCode: get(state, `form.testingInfo.values.colorCode`) || get(state, `trf.trfDataForEdit.colorCode`, ''),
      colorName: get(state, `form.testingInfo.values.colorName`) || get(state, `trf.trfDataForEdit.colorName`, ''),
      colorway: get(state, `form.testingInfo.values.colorway`) || get(state, `trf.trfDataForEdit.colorWay`, ''),
      sampleDescription: get(state, `form.testingInfo.values.sampleDescription`) || get(state, `trf.trfDataForEdit.description`, ''),
      season: get(state, `form.testingInfo.values.season`) || get(state, `trf.trfDataForEdit.seasonId`, ''),
      Year: get(state, `form.testingInfo.values.Year`) || moment(get(state, `trf.trfDataForEdit.year`, '') + '-1-1'),
      testingType: get(state, `form.testingInfo.values.testingType`) || get(state, `trf.trfDataForEdit.testingType`, ''),
      repellent: get(state, `form.testingInfo.values.repellent`) || repellent,
      recycle: get(state, `form.testingInfo.values.recycle`) || recycle,
      listRecycle : get(state, `form.testingInfo.values.listRecycle`) || get(state, `trf.trfDataForEdit.listRecycle`, ''),
      describeRecycle: get(state, `form.testingInfo.values.describeRecycle`) || get(state, `trf.trfDataForEdit.describeRecycle`, ''),
      /* recycle:(( get(state, `trf.trfDataForEdit.recycle`) === 'Post Consumer' || 
      get(state, `trf.trfDataForEdit.recycle`) === 'Post-Industrial') ? 'Yes' : 'No') ,
      subrecycle: get(state, `trf.trfDataForEdit.recycle`), */
      foodOrMouth: get(state, `form.testingInfo.values.foodOrMouth`) || foodOrMouth,
      sampleType: get(state, `form.testingInfo.values.sampleType`) || get(state, `trf.trfDataForEdit.sampleType`),
      ageGroup: get(state, `form.testingInfo.values.ageGroup`) ||  get(state, `trf.trfDataForEdit.ageGroup`),
      sampleColor: get(state, `form.testingInfo.values.sampleColor`) ||  get(state, `trf.trfDataForEdit.sampleColor`),
      eyeWear: get(state, `form.testingInfo.values.eyeWear`) || eyeWear,
      enableTP2: get(state, `form.testingInfo.values.enableTP2`) || enableTP2,
      electCategory: get(state, `form.testingInfo.values.electCategory`) || electCategory,
      jeweleryMaterialType:  jeweleryMaterialType,
      isElectEmbedded: get(state, `form.testingInfo.values.isElectEmbedded`) || isElectEmbedded,
      skinContact:  get(state, `form.testingInfo.values.skinContact`) || skinContact,
      variantName: variants,
      variants: filter(MaterialTypes, { variants: [{ id: `variants${get(state, `form.testingInfo.values.materialType`)}` }] })[0] ?
        filter(MaterialTypes, { variants: [{ id: `variants${get(state, `form.testingInfo.values.materialType`)}` }] })[0].id :
        get(state, `form.testingInfo.values.materialType`, '') || get(state, `trf.trfDataForEdit.materialType`, ''),
      subVariants:subVarientsValue,
      selectedTestPackages: selectedTestPackages,
      testPackageVariants: [],
      selectedMaterials: ownProps.location.state && !ownProps.location.state.preLoadMaterial ?[]:  selectedMterialTypes,
      materialType: get(state, `form.testingInfo.values.materialType`) || materialTypes,
      testPackages: get(state, `form.testingInfo.values.testPackages`) || testPackageArray,
      certification: get(state, `form.testingInfo.values.certification`) || certification,
      testPackagesToys: get(state, `form.testingInfo.values.testPackagesToys`) || toysTestPackageArray,
      testPackagesElectronic: get(state, `form.testingInfo.values.testPackagesElectronic`) || electronicTestPackageArray,
      prevProductId : get(state, `trf.trfDataForEdit.product`, null),
      currentProductId : get(state, `form.brandInfo.values.productId`, null),
      testPackagesPackaging: get(state, `form.testingInfo.values.testPackagesPackaging`) || packagingTestPackageArray,
      testPackagesJewelry: get(state, `form.testingInfo.values.testPackagesJewelry`) || jewelrytestPackagesJewelry,
      inkSystemName: get(state, `form.testingInfo.values.inkSystemName`) || get(state, `trf.trfDataForEdit.inkSystem`, ''),
      baseName: get(state, `form.testingInfo.values.baseName`) || get(state, `trf.trfDataForEdit.bName`, ''),
      pigmentColor: get(state, `form.testingInfo.values.pigmentColor`) || get(state, `trf.trfDataForEdit.pColor`, ''),
      additive1Name: get(state, `form.testingInfo.values.additive1Name`) || get(state, `trf.trfDataForEdit.addit1`, ''),
      additive2Name: get(state, `form.testingInfo.values.additive2Name`) || get(state, `trf.trfDataForEdit.addit2`, ''),
      additive3Name: get(state, `form.testingInfo.values.additive3Name`) || get(state, `trf.trfDataForEdit.addit3`, ''),
      testingInfoStatus: 'Edit',
      activeForm: 'testingInfo'
    },
    labInfo: returnLabInfoForCreate(state),
    labInfoForEdit: returnLabInfoForEdit(state),
  };
}

const mapDispatchToProps = dispatch => ({
  submitTrfData: (data, history) => {
    dispatch(createServiceAction('trfData', 'submited')({ ...data, history }));
  },

  updateTrfData: (data, history) => {
    dispatch(createServiceAction('trfUpdate', 'submitted')({ ...data, history }));
  },

  displaySubmitButtonTrf: isOpen => {
    dispatch(displaySubmitButtonTrf(isOpen));
  },

  displayClearButtonTrf: isOpen => {
    dispatch(displayClearButtonTrf(isOpen));
  },

  updateMaterialField: data => {
    dispatch(updateMaterialField(data));
  },
  fetchTrfData: (data, history) => {
    dispatch(createServiceAction('trfUpdate', 'requested')({ ...data, history }));
  },
  clearFormValueTRF: () => {
    dispatch(clearFormValueTRF());
  },
  initialCertificationArry: data =>{
    dispatch(initialCertificationArry(data));
  },
  recycledContentValue: data => {
    dispatch(recycledContentValue(data))
  }
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
  const allProps = {
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps
  }

  const variantsCreate = allProps.testingInfo.variantName;
  const labName = allProps.labInfoForEdit.labName;
  const labNameCreate = allProps.labInfo.labName;
  allProps.labInfoForEdit[labName] = allProps.labInfoForEdit.labLocation ? allProps.labInfoForEdit.labLocation.id : '';
  if (allProps.testingInfoStates && allProps.testingInfoStates.materialType && allProps.testingInfoStates[variantsCreate] && allProps.trfId === '') {
    allProps.testingInfo[variantsCreate] = allProps.testingInfoStates[variantsCreate];
  } else if (allProps.testingInfoStates && allProps.testingInfoStates.materialType && allProps.testingInfoStates[variantsCreate] && allProps.trfId !== '') {
    allProps.testingInfoForEdit[variantsCreate] = allProps.testingInfoStates[variantsCreate];
  }
  if (allProps.brandInfoForEdit) {
    allProps.brandInfoForEdit.isLicensee = allProps.brandInfoForEdit.licensee ? "Yes" : "No";
  }
  if (allProps.brandInfoForEdit) {
    allProps.brandInfoForEdit.isRetest = allProps.brandInfoForEdit.originalTRF ? "Yes" : "No";
  }
  if (allProps.labLocationStates && allProps.labLocationStates.labName && allProps.labLocationStates[labNameCreate] && allProps.trfId === '') {
    allProps.labInfo[labNameCreate] = allProps.labLocationStates[labNameCreate];
  } else if (allProps.labLocationStates && allProps.labLocationStates.labName && allProps.labLocationStates[labName] && allProps.trfId !== '') {
    allProps.labInfoForEdit[labName] = allProps.labLocationStates[labName];
  }
  if (allProps.supplierForm && allProps.supplierForm.supplierName && allProps.trfId === '') {
    allProps.brandInfo.supplierName = allProps.supplierForm.supplierName;
  }
  if (allProps.supplierForm && allProps.supplierForm.supplierOrLocationId && allProps.trfId === '') {
    allProps.brandInfo.supplierId = allProps.supplierForm.supplierOrLocationId;
  }
  if (allProps.testingInfoForEdit.selectedMaterials) {
    const tempMaterialArray = [];
    const arrayMaterialNumbers = [400, 415];
    allProps.testingInfoForEdit.selectedMaterials.filter(element => element != null).map((material, indx) => {
      const materialObject = filter(MaterialTypes, { variants: [{ id: material }] })[0];
      if(material === 4051 || material === 4050){
      allProps.testingInfoForEdit[`variants405`]  = 4052
      allProps.testingInfoForEdit[`subVariants405`]  = material
      }
      if(material === 4040){
        allProps.testingInfoForEdit[`variants405`]  = 4040
        }
      if (filter(MaterialTypes, { variants: [{ id: material }] }).length >= 1 && !(arrayMaterialNumbers.includes(materialObject.id)) && material !== 4040) {
        allProps.testingInfoForEdit[`variants${filter(MaterialTypes, { variants: [{ id: material }] })[0].id}`]  =material
       
      } else if (filter(MaterialTypes, { variants: [{ id: material }] }).length >= 1 && arrayMaterialNumbers.includes(materialObject.id) && material !== 4040) {
        const mat_Indx = MaterialTypes.findIndex(x => x.id === materialObject.id);
        MaterialTypes[mat_Indx].variants.map((data, index) => {
          if (data.id === material) {
            tempMaterialArray[index] = material
          } else {
            if (!tempMaterialArray[index]) { tempMaterialArray[index] = null }
          }
          return tempMaterialArray;
        })
        allProps.testingInfoForEdit[`variants${filter(MaterialTypes, { variants: [{ id: material }] })[0].id}`] = tempMaterialArray
      }
      return allProps.testingInfoForEdit;
    })
  }
  if (allProps.testingInfoForEdit.selectedTestPackages) {

    allProps.testingInfoForEdit.selectedTestPackages.filter(element => element != null).map(testPackage => {
      if (filter(union(individualTest[0].common, individualTest[0].toys, individualTest[0].electronic, individualTest[0].packaging, individualTest[0].jewelry), { variants: [{ id: testPackage }] }).length >= 1) {
        allProps.testingInfoForEdit[`tp-variants${filter(union(individualTest[0].common, individualTest[0].toys, individualTest[0].electronic, individualTest[0].packaging, individualTest[0].jewelry), { variants: [{ id: testPackage }] })[0].id}`] = testPackage
       // allProps.testingInfoForEdit.testPackageVariants.push(testPackage)
      }
      return allProps.testingInfoForEdit;
    })
  }
  return allProps;
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(Trf)
);