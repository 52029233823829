import React, { Component } from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Popover, ArrowContainer} from 'react-tiny-popover';

import { H4, H5, H6, Radiobutton, TextInput, Button, DropDown, ProgressBar, ConfirmationBox, IconButton } from '../../../components/index.js';
import Grid from '../../../components/grid/Grid.js';
import SupplierSearch from '../../supplierSearch/SupplierSearch.js';
import { toggleAddMaterailModal, updateMaterialField, onChangeProductType, onChangeRetest } from '../../../actions/trfActions.js';
import { locationReinitilized, materialReinitilized } from '../../../actions/authActions.js';
import summaryTableConfig from '../../../configs/gridConfigs/trf/summaryTableConfig.js';
import brandInfo from '../../../constants/json/checkboxes/brandInfo.json';
import productInfo from '../../../constants/json/checkboxes/productInfo.json';
import AddMaterial from '../addMaterial/AddMaterial.js';
import {
  selectUserDetails,
  getLocationList,
  getMaterialList,
  getMaterialRequestCompleteStatus,
  getMaterialNextPage,
  getMaterialTotalPages,
  isRslUser,
  isFactoryUser,
  isLoading,
  isNloUser,
  isSupplierUser
} from '../../../reducers/authReducer.js';
import { createServiceAction } from '../../../util/actionHelper.js';
import { Spinner } from '../../../components/index.js';
import licensee from '../../../constants/json/radiobuttons/licensee.json';
import retestTRF from '../../../constants/json/radiobuttons/retestTRF.json';

function getLabLocations(locationList,supplierName) {
  if (locationList) {
    return locationList.map(labLocation => ({
      id: labLocation.locationId,
      value: labLocation.locationId,
      label: labLocation.locationName,
      supplierId: labLocation.supplierId,
      supplierName: labLocation.supplierName !== undefined ?labLocation.supplierName :supplierName
    }));
  }
  return [];
}
function getProductType() {
    return productInfo.map(productType => ({
      id: productType.id,
      value: productType.id,
      label: productType.label
    }));
}
export class BrandInfo extends Component {
  constructor(...props) {
    super(...props);
    this.onRowClick = this.onRowClick.bind(this);
    this.setGridParams = this.setGridParams.bind(this);
    this.onFilterTextBoxChanged = this.onFilterTextBoxChanged.bind(this);
    this.state = {
      inInitialLoad: true,
      productChangeModal: false,
      isPopoverOpen: false
    };
  }

  componentDidMount() {
    const {
      displaySubmitButtonTrf,
      displayClearButtonTrf,
      requestLocationList,
      userDetails,
      brandInfoStates,
      isRslUser,
      isFactoryUser,
      isNloUser,
      activeLicenseeList,
      updateRetest
    } = this.props;
    displaySubmitButtonTrf(false);
    displayClearButtonTrf(true);
    if ((!brandInfoStates.labName || typeof brandInfoStates.labName === 'undefined' || brandInfoStates.labName === '' || brandInfoStates.labName === 'undefined') && !isRslUser && !isFactoryUser && !isNloUser ) {
      var facilityId = userDetails.facilityIds ? userDetails.facilityId+","+userDetails.facilityIds.join(",") :userDetails.facilityId
      requestLocationList(userDetails.supplierId,facilityId);
    }
    activeLicenseeList();
    updateRetest(brandInfoStates.isRetest)
  }
  onSupplierChange=(name,id) =>{
    const { change } = this.props;
     change('supplierName', name ? name : '');
    change('supplierOrLocationId', id? id : '');
  }

  componentWillUpdate(nextProps) {
    const {
      brandInfoStates,
      locationReinitilized,
      materialData,
      requestLocationList,
      change,
      initialValues,
      updateMaterialField,
      userDetails,
      supplierForm,
      isRslUser,
      isFactoryUser,
      locationList,
    } = this.props;

    if ((locationList && locationList.length > 0 && !brandInfoStates) || (brandInfoStates && brandInfoStates.labName && brandInfoStates.labName !== nextProps.brandInfoStates.labName)) {

      if (nextProps.brandInfoStates && nextProps.brandInfoStates.labName) {
        let index = locationList.findIndex(i => i.locationId.toString() === nextProps.brandInfoStates.labName);

        if (index !== -1){
          change('supplierName', locationList[index].supplierName)
          change('supLocName', locationList[index].locationName);
          change('supplierId', locationList[index].supplierId);
            }
        else
          change('supLocName', ' ');
      }

    }

    if (nextProps.brandInfoStates && brandInfoStates) {
      if (brandInfoStates.labName !== nextProps.brandInfoStates.labName &&
        (typeof nextProps.brandInfoStates.labName == 'undefined' || nextProps.brandInfoStates.labName === '')) {
        change('labName', '');
        locationReinitilized();

      }
      if (brandInfoStates.labName !== nextProps.brandInfoStates.labName &&
        (nextProps.brandInfoStates.labName === '') && userDetails.supplierId && userDetails.supplierId !== '' && !isFactoryUser) {
        change('labName', '');
        var facilityId = userDetails.facilityIds ? userDetails.facilityId+","+userDetails.facilityIds.join(",") :userDetails.facilityId
        requestLocationList(userDetails.supplierId,facilityId);      
      }
      //(inInitialLoad && (nextProps.brandInfoStates.brandInfoStatus !== 'Edit'))
      if (brandInfoStates.labName !== nextProps.brandInfoStates.labName) {
        const material = {}, materialInfo = [];

        let index = locationList.findIndex(i => i.locationId.toString() === nextProps.brandInfoStates.labName);
        change('materialName', '');
        change('materialNumber', '');
        change('refId', '');
        change('resetMaterialInfo', true);
        if (index !== -1){
          change('supLocName', locationList[index].locationName);
          change('supplierName', locationList[index].supplierName);
          change('supplierId', locationList[index].supplierId);
         
        }
        else
          change('supLocName', ' ');
        this.setState({
          resetMaterialList: true
        });

        material.materialName = '';
        material.materialNumber = '';
        material.refId = '';
        materialInfo.push(material);
        if (isRslUser || isFactoryUser) {
          materialInfo.push({ supplierName: supplierForm.supplierName, supLocName: brandInfoStates.supLocName });
        } else {
          materialInfo.push({ supplierName: brandInfoStates.supplierName, supLocName: brandInfoStates.supLocName });
        }
        updateMaterialField(materialInfo);
      }
     
      if (brandInfoStates.productId && brandInfoStates.productId !== nextProps.brandInfoStates.productId &&
        nextProps.brandInfoStates.labName) {
        this.confirmationBoxOpen()

      }
    }

    if (initialValues.trfId !== nextProps.initialValues.trfId && nextProps.initialValues.brandInfoStatus === 'Edit') {
      const material = {}, materialInfo = [];
      material.materialName = nextProps.initialValues.materialName;
      material.materialNumber = nextProps.initialValues.materialNumber;
      material.refId = nextProps.initialValues.refId;
      materialInfo.push(material);
      materialInfo.push(nextProps.initialValues.supplierName);

      updateMaterialField(materialInfo);
    }
    if (nextProps.initialValues.brandInfoStatus === 'Edit') {
      if (nextProps.brandInfoStates.resetMaterialInfo !== null && nextProps.brandInfoStates.resetMaterialInfo && nextProps.brandInfoStates.materialNumber !== '') {
        if (!materialData) {
          change('materialName', '');
          change('materialNumber', '');
          change('refId', '');
        }
        if (brandInfoStates.materialName !== nextProps.brandInfoStates.materialName) {
          change('materialName', '');
          change('materialNumber', '');
          change('refId', '');
        }
        if (nextProps.materialData && brandInfoStates.materialName !== nextProps.brandInfoStates.materialName && brandInfoStates.materialName === '') {
          change('materialName', nextProps.brandInfoStates.materialName ? nextProps.brandInfoStates.materialName : '');
          change('materialNumber', nextProps.brandInfoStates.materialNumber ? nextProps.brandInfoStates.materialNumber : '');
          change('refId', nextProps.brandInfoStates.refId ? nextProps.brandInfoStates.refId : '');

        }
      }
    }
    if (initialValues.labName !== nextProps.initialValues.labName && nextProps.initialValues.brandInfoStatus === 'Edit') {
      change('labName', nextProps.initialValues.labName);
    }

    if (materialData !== nextProps.materialData && nextProps.materialData) {
      const materialInfo = [];
      materialInfo.push(nextProps.materialData);
      if (isRslUser || isFactoryUser) {
        materialInfo.push({ supplierName: supplierForm.supplierName, supLocName: nextProps.initialValues.supLocName });
      } else {
       materialInfo.push({ supplierName: nextProps.brandInfoStates.supplierName, supLocName: nextProps.initialValues.supLocName });
      }
      change('materialNumber', nextProps.materialData.materialNumber ? nextProps.materialData.materialNumber : '');
      change('refId', nextProps.materialData.refId ? nextProps.materialData.refId : '');
      change('materialName', nextProps.materialData.materialName ? nextProps.materialData.materialName : '');
      updateMaterialField(materialInfo);
    }

  }
  confirmationBoxOpen = () => this.setState({ productChangeModal: true });

  closeProductChangeModal = () => this.setState({ productChangeModal: false });

  clearMaterialData = () => {
    const { change, locationReinitilized } = this.props;

    change('materialName', '')
    change('materialNumber', '');
    change('refId', '');
    change('supLocName', ' ');
    change('labName', [0]);
    locationReinitilized();
    change('materialType', [null]);
    change('testPackagesValidationArry', null);
   
  }

  onRowClick = params => {
    const { change, updateMaterialField,supplierForm, isRslUser, isFactoryUser, brandInfoStates } = this.props;
    const materialInfo = [];
    materialInfo.push(params.data);

    if (isRslUser || isFactoryUser) {
      materialInfo.push({ supplierName: supplierForm.supplierName, supLocName: brandInfoStates.supLocName });

    } else {
      materialInfo.push({ supplierName: brandInfoStates.supplierName, supLocName: brandInfoStates.supLocName });

    }
    updateMaterialField(materialInfo);
    change('resetMaterialInfo', false);
    change('materialNumber', params.data.materialNumber ? params.data.materialNumber : '');
    //change('refId', params.data.refId ? params.data.refId : '');
    change('materialName', params.data.materialName ? params.data.materialName : '');
    if (params.data.hasOwnProperty("refId")) {
      change('refId', params.data.refId);
    } else {
      change('refId', '');
    }
    if (params.data.hasOwnProperty("PDMNumber")) {
      change('pdmNumber', params.data.PDMNumber)
    } else {
      change('pdmNumber', '')
    }
  };

  onFilterTextBoxChanged = () =>{
    if (this.params) {
      this.params.api.setQuickFilter(document.getElementById('filter-text-box').value);
    }
  }

  setGridParams = params => this.params = params;

  displayPopOver = () => {
    const { isPopoverOpen } = this.state;
    const togglePopover = !isPopoverOpen;
    this.setState({ isPopoverOpen: togglePopover });
  };

  resetLocation = () => {
    const { change } = this.props;
    change('labName', '');
  }
  selectedDropdownValue = (locationID) => {
    const { requestMaterialList, brandInfoStates,change ,locationList,userDetails,isSupplierUser} = this.props;
    const productLabel = productInfo.filter(e => e.id === parseInt(brandInfoStates.productId))
    requestMaterialList(locationID, productLabel[0].label)
    if(isSupplierUser){
    const locationList1= getLabLocations(locationList,userDetails.supplierName);
    const supplier =locationList1.filter(e => e.id.toString() === locationID);
    change('supplierName',supplier[0].supplierName)
    change('supplierId',supplier[0].supplierId)
    }
  }
  
  handleChange =   (productId) => { 
    const {   onChangeProductType } = this.props;
    const productType = parseInt(productId)
    const storeProductType ={isChanged : true, CurrentProductType:productType}
    onChangeProductType(storeProductType)
  }
  render() {
    const {
      isAddMaterialModalOpen,
      toggleAddMaterailModal,
      sucessFill,
      locationList,
      materialList,
      materialRequestComplete,
      isRslUser,
      isFactoryUser,
      brandInfoStates,
      supplierForm,
      userDetails,
      materialNextPage,
      materialTotalPages,
      requestLocationList,
      isLoading,
      activeLicensees,
      isPartialEdit,
      rslId,
      isRetestPassed,
      initialValues
    } = this.props;
    const { productChangeModal, isPopoverOpen } = this.state;
    if(isRetestPassed && rslId){
      brandInfoStates.isRetest = "Yes";
      brandInfoStates.originalTRF = rslId;
    }
     if(initialValues.isRetest === "" && initialValues.brandInfoStatus === "New")
    {
      brandInfoStates.isRetest = "No";     
    }
    let loader = null;

    if (isLoading) {
      loader = <Spinner className="spinner-lg modal-overlay-spinner" text='Loading...' />;
    }

    return (
      <form className="form">
        {loader}
        <H4 className={sucessFill ? 'error-text' : 'error-hide'}>
          Brand, Product, and Material Name Fields are Mandatory <span className="astericks ml-neg3">&#42;</span>
        </H4>
        <div className="p2-sm">
          <H4 className="text-color-accent u-bold page-title-has-back sub-heading">BRAND INFORMATION</H4>
        </div>
        <div>
          <div className="ncss-col-sm-4 description u-bold trf-data-text">
            Brand Tested For <span className="astericks ml-neg3">&#42;</span>
          </div>
          <div className="ncss-col-sm-8  ncss-form-group trf-icon-brand-display">
            {brandInfo.map(data => (

              <div key={data.label} className="ncss-col-sm-3 ncss-radio-container icon-align-center ">
                {!data.hideBrand ?
                  <Field
                    type="radio"
                    component={Radiobutton}
                    name="brandId"
                    value={data.id}
                    icon={data.icon}
                    checked={false}
                    disabled={isPartialEdit}
                    label={data.label}
                    id={data.id}
                    mandatory={data.mandatory}
                    isIcon
                  />
                  :
                  null}
              </div>
            ))}
          </div>
        </div>
        <div className="ncss-col-sm-4 description p2-bottom-sm trf-data-text">
          Product Type <span className="astericks ml-neg3">&#42;</span>
        </div>
        <div className="ncss-col-sm-8  ncss-radio-container">
        <div className="ncss-col-sm-5  ncss-radio-container" >
        <Field
          component={DropDown}
          name="productId"
          placeholder="Select Product Type"
          options={getProductType()}
          className="dropdown-border-location"
          onOptionSelect={this.handleChange}
        />
        </div></div>
        <div className="ncss-col-sm-4 description p2-bottom-sm pt5-sm trf-data-text">
          Is this a Re-Test? <span className="astericks ml-neg3">&#42;</span>
        </div>

        <div className="ncss-col-sm-8  ncss-radio-container">
          {retestTRF.map((data) => (
            <div
              key={data.label}
              className="ncss-col-sm-3 description u-bold trf-data-text" 
            >
              <Field
                type="radio"
                component={Radiobutton}
                name="isRetest"
                value={data.value}
                checked={false}
                label={data.label}
                id={data.id}
                isIcon={false}
                disabled={isRetestPassed ? true : false}
              />
            </div>
          ))}
        </div>
        {brandInfoStates && brandInfoStates.isRetest === "Yes" ? (
          <div>
            <div className="ncss-col-sm-4 description p2-bottom-sm pt5-sm trf-data-text">
              Link TRF # <span className="astericks ml-neg3">&#42;</span>
            </div>
            <div className="ncss-col-sm-4 ncss-input-container">
              <Field
                component={TextInput}
                name="originalTRF"
                placeholder="Eg: R-0003021"
                disabled={rslId ? true : false}
              />
            </div>
          </div>
        ) : null}
        <div className="ncss-col-sm-4 description p2-bottom-sm pt5-sm trf-data-text">
          Is this material/product test request connected to a <br/>Nike or Converse Licensee finished good?
          <span className="astericks">&#42;</span>
          <span>
                  {' '}
                  <Popover
                    isOpen={isPopoverOpen}
                    position="top"
                    padding={5}
                    onClickOutside={this.displayPopOver}
                    content={({ position, childRect, popoverRect }) => (
                      childRect && popoverRect ? (
                        <ArrowContainer
                          position={position}
                          childRect={childRect}
                          popoverRect={popoverRect}
                          arrowColor="#dddcde"
                          arrowSize={0}
                          arrowStyle={{ opacity: 0.7, left: '120px' }}
                        >
                          <IconButton icon="times" className="popover-button-licensee-sec" onClick={this.displayPopOver} />
                          <div className="popover-new-materialsection">
                            <div>
                              Are you testing for a Licensee? A Licensee is a company who has been granted a special license by Nike or Converse. This means they make 
                              <strong> finished</strong> products such as equipment and eyewear. If you are not submitting a TRF for someone with this special license, you should be selecting ‘No’ for this question.
                            </div>
                          </div>
                        </ArrowContainer>
                      ) : null
                    )}
                  >
                    <span>
                      {' '}
                      <IconButton icon="comment-alt" extraClassName="color-trf" onClick={this.displayPopOver} />
                    </span>
                  </Popover>
           </span>
           
        </div>
        <div className="ncss-col-sm-8  ncss-radio-container">
          {licensee.map(data => (
            <div key={data.label} className="ncss-col-sm-3 description u-bold trf-data-text">
              <Field
                type="radio"
                component={Radiobutton}
                name="isLicensee"
                value={data.value}
                checked={false}
                label={data.label}
                id={data.id}
                isIcon={false}
              />
            </div>
          ))}
        </div>
        {brandInfoStates && brandInfoStates.isLicensee === "Yes" ?
          <div>
            <div className="ncss-col-sm-4 description p2-bottom-sm pt5-sm trf-data-text">
              Licensee Name <span className="astericks ml-neg3">&#42;</span>
            </div>
            <div className="ncss-col-sm-4  ncss-radio-container">
              <Field
                component={DropDown}
                name="licensee"
                placeholder="Select the Licensee"
                options={activeLicensees ? activeLicensees : []}
                className="dropdown-border-location"
              />
            </div>
          </div>
          : null}
        <div className="p2-sm trf-extra-top-margin">
          <H4 className="text-color-accent u-bold page-title-has-back sub-heading">MATERIAL SELECTION</H4>
        </div>
        {brandInfoStates && brandInfoStates.productId ?
          <span>
            {isRslUser || isFactoryUser ? (
              <div className="pt2-sm pb2-sm">
                <div className="ncss-col-sm-2 description u-bold trf-data-text">Select Supplier</div>
                <div className="ncss-col-sm-5">
                  <Field component={TextInput} name="supplierName" type="hidden" />
                  <Field component={TextInput} name="supplierId" type="hidden" />
                  <Field component={TextInput} name="resetMaterialInfo" type="hidden" />
                  <Field component={TextInput} name="supLocName" type="hidden" />
                  <Field component={TextInput} name="pdmNumber" type="hidden" />
                  <SupplierSearch
                    {...(brandInfoStates && (brandInfoStates.brandInfoStatus ? { initialValues: { 'supplierName': brandInfoStates.supplierName, 'supplierInfoStatus': brandInfoStates.brandInfoStatus === 'Edit' ? 'Edit' : 'New', 'supplierOrLocationId': brandInfoStates.supplierId } } : { 'supplierName': brandInfoStates.supplierName, 'supplierInfoStatus': 'New' }))}
                    requestLocationList={requestLocationList}
                    resetLocation={() => this.resetLocation()}
                    fromCreateTrf={this.onSupplierChange}
                  />
                </div>
              </div>
            ) : null}
            {(isRslUser || isFactoryUser) && isEmpty(locationList) ? null : (
              <div className="pt2-sm pb2-sm">
                <div className="ncss-col-sm-2 description u-bold trf-data-text">Location</div>
                <div className="ncss-col-sm-5">
                  <Field
                    component={DropDown}
                    name="labName"
                    placeholder="Select a Location"
                    options={getLabLocations(locationList,userDetails.supplierName)}
                    className="dropdown-border-location"
                    //onOptionSelect={requestMaterialList}
                    onOptionSelect={this.selectedDropdownValue}
                    />
                  </div>
                  </div>
                )}
                </span>
                :
                <div className="material-error-section">
                <H4 className={'error-text-material pl3-sm'}>
                  <span className="astericks">&#42;</span>Product Type is  Mandatory for Material Search
                </H4>
                </div>
              }
              {brandInfoStates && brandInfoStates.materialName ?
                <span>
                <H5 className="text-color-accent u-bold page-title-has-back p2-sm p4-bottom-sm p4-top-sm">
                  Selected Material
              </H5>
                <div className="ncss-col-sm-4 ncss-input-container ">
                  <H5 className="u-bold description p2-top-sm p2-bottom-sm">
                  Material Name <span className="astericks ml-neg3">&#42;</span>
                  </H5>
                  <Field component={TextInput} name="materialName" type="text" disabled />
                </div>
                <div className="ncss-col-sm-4 ncss-input-container ">
                  <H5 className="u-bold description p2-top-sm p2-bottom-sm">
                  Material Number<span className="astericks ml-pos">&#42;</span>
                  </H5>
                  <Field component={TextInput} name="materialNumber" type="text" disabled />
                </div>
                <div className="ncss-col-sm-4 ncss-input-container ">
                  <H5 className="u-bold description p2-top-sm p2-bottom-sm">
                  Material ID
                </H5>
                  <Field component={TextInput} name="refId" type="text" disabled />
                </div>
                </span>
                :
                null
              }
              <div className="pl2-sm grid-filter-p-right-10 pt3-sm">
                <input
                type="text"
                id="filter-text-box"
                className="grid-filter-textbox"
                placeholder="Enter text to filter Material Data..."
                onChange={this.onFilterTextBoxChanged}
                />
              </div>
              <H5 className="text-color-primary-dark u-bold page-title-has-back p2-left-sm  p4-top-sm ">
                Search results
              </H5>
              {materialList !== undefined &&
                materialTotalPages !== undefined &&
                materialNextPage !== 1 &&
                materialRequestComplete ?
                <span>
                <div className="ncss-col-sm-12">
                  <ProgressBar percent={(materialNextPage / (materialTotalPages + 1)) * 100}
                  message={materialNextPage >= (materialTotalPages + 1) ? "Material Grid Updated" : "Updating Material Grid"} />
                </div>
                </span> : null
              }
              <Grid
                columnDefs={summaryTableConfig}
                rowData={materialList}
                showFilter={false}
                setMaterialHeight
                onRowClick={this.onRowClick}
                setParams={this.setGridParams}
                className="set-grid p1-sm"
                overlayLoadingTemplate={

                materialList === undefined
                  ? "<span class='ag-overlay-loading-center'>Please wait while your rows are loading</span>"
                  : "<span class='custom-no-row'>Please Select <strong> Location </strong>for Material list</span>"
                }
                customNoRowTemplete={
                (materialList !== undefined && materialList !== null && materialList.length === 0 && !materialRequestComplete)
                  ? "<span class='custom-no-row'>Please Select <strong> Location </strong>for Material list</span>"
                  : (materialList === null) || (materialList && materialList.length === 0 && materialRequestComplete)
                  ? "<span class='ag-overlay-loading-center'>No results found</span>"
                  : "<span class='ag-overlay-loading-center'>Please wait while your rows are loading</span>"

                }
              />

              <div className="ncss-col-sm-12 ">

                {(brandInfoStates && brandInfoStates.supplierName !== undefined && brandInfoStates.labName !== undefined && brandInfoStates.labName !== '' && brandInfoStates.labName !== null) ?
                <div className="new-material-add-section p4-top-sm">
                  <H6 className="u-bold description">If the material is not in list</H6>
                  <Button
                  className="ncss-btn-grey u-bold new-material"
                  onClick={() => toggleAddMaterailModal(true)}
                  >
                  ADD NEW MATERIAL
                </Button>
                </div> :
                null}
              </div>
              <ConfirmationBox
                message={
                'Material Information is based on Product Type . If you change Product type Material Informations will clear.Do you want to continue?'
                }
                submitModal={productChangeModal}
                submitValue={
                () => {
              this.clearMaterialData();
            }

          }
          closeModal={this.closeProductChangeModal}
        />

        <AddMaterial
          isModalOpen={isAddMaterialModalOpen}
          toggleModal={toggleAddMaterailModal}
          supplierLocationId={brandInfoStates && brandInfoStates.labName}
          productId={brandInfoStates && brandInfoStates.productId}
          supplierId={supplierForm && (isRslUser || isFactoryUser) ?
            supplierForm.supplierOrLocationId : brandInfoStates.supplierId} />
      </form>
    );
  }
}

BrandInfo.propTypes = {
  isAddMaterialModalOpen: PropTypes.bool,
  toggleAddMaterailModal: PropTypes.func,
  locationReinitilized: PropTypes.func,
  sucessFill: PropTypes.bool,
  change: PropTypes.func,
  displaySubmitButtonTrf: PropTypes.func,
  displayClearButtonTrf: PropTypes.func,
  updateMaterialField: PropTypes.func,
  userDetails: PropTypes.object,
  locationList: PropTypes.object,
  materialList: PropTypes.object,
  requestLocationList: PropTypes.func,
  brandInfoStates: PropTypes.object,
  supplierForm: PropTypes.object,
  requestMaterialList: PropTypes.func,
  isRslUser: PropTypes.bool,
  isFactoryUser: PropTypes.bool
};

const mapStateToProps = state => {
  return ({
    userDetails: selectUserDetails(state),
    locationList: getLocationList(state),
    materialList: getMaterialList(state),
    materialRequestComplete: getMaterialRequestCompleteStatus(state),
    materialNextPage: getMaterialNextPage(state),
    materialTotalPages: getMaterialTotalPages(state),
    isAddMaterialModalOpen: state.trf.isAddMaterialModalOpen,
    materialData: state.trf.materialData,
    brandInfoStates: getFormValues('brandInfo')(state),
    supplierForm: getFormValues('supplierForm')(state),
    isRslUser: isRslUser(state),
    isNloUser: isNloUser(state),
    isFactoryUser: isFactoryUser(state),
    isLoading: isLoading(state),
    isSupplierUser: isSupplierUser(state),
    activeLicensees: state.licensee.activeLicensees
  })
};

const mapDispatchToProps = dispatch => ({
  requestLocationList: (supplierId,facilityId) => {
    dispatch(createServiceAction('locationSearch', 'requested')({ supplierId: supplierId, facilityId:facilityId, type: 'createTRF' }));
  },
  requestMaterialList: (locationID, productLabel) => {
    dispatch(createServiceAction('materialSearch', 'requested')({ supplierLocation: locationID, prodType: productLabel }));
  },
  materialReinitilized: () => {
    dispatch(materialReinitilized());
  },
  locationReinitilized: () => {
    dispatch(locationReinitilized());
  },
  toggleAddMaterailModal: isOpen => {
    dispatch(toggleAddMaterailModal(isOpen));
  },
  updateMaterialField: data => {
    dispatch(updateMaterialField(data));
  },
  activeLicenseeList: () => {
    dispatch(createServiceAction("activeLicensee", 'requested')())
  },
  onChangeProductType: (data) => {
    dispatch(onChangeProductType(data));
  },
  updateRetest: (data) => {
    dispatch(onChangeRetest(data));
  },
});

export default reduxForm({
  form: 'brandInfo',
  initialValues: {
    materialName: '',
    isRetest: 'No'
  },
  destroyOnUnmount: false,
  enableReinitialize: true
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BrandInfo)
);