import './util/polyfills.js';
import React from 'react';
import { LicenseManager } from 'ag-grid-enterprise';
//import { LicenseManager } from '@ag-grid-enterprise/core';

import { AG_GRID_LICENSE_KEY } from './constants/appConstants';
import Root from './root/Root.js';
import configureStore from './root/store.js';
// import registerServiceWorker from './registerServiceWorker';
import { watchOnlineState } from './actions/offlineActions.js';
import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);

// Render an empty div until the token check is done
// The createElement is for unit test
const appRoot = document.getElementById('app-host') || document.createElement('div');
const root = createRoot(appRoot);
export const store = configureStore();
Modal.setAppElement(appRoot);
// Track browser's online state
watchOnlineState(store);
root.render(<Root store={store} />);
// Start the app
// registerServiceWorker();